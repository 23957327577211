import React, { useState, useRef, useEffect, useCallback } from 'react';
import { Link, useNavigate } from "react-router-dom";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import axios from 'axios';
import './Navbar.css';
import alertify from 'alertifyjs';
import 'alertifyjs/build/css/alertify.css';
import LanguageSetting from './LanguageSetting';
import { decruptCipherString, decruptCipherJson } from '../utils/CommonLibrary';
import Notifications from "../cn/components/notifications/Notifications";
import { fetchNotificationsListV2, removeNotifications, updateNotifications, markAllNotificationsRead } from "../../services/cn/Service";
import { useUserTabs } from '../cn/components/common/UserTabsContext';
import { Commingsoon } from '../cn/components/common/Utils';
import { useSelector } from 'react-redux';
import NotificationDropdown from '../cn/components/notifications/NotificationDropdown';

// import logoIcon from '../../images/logo_bupasa.png';

const Navbar = ({ title }) => {

  const [hasMore, setHasMore] = useState(false);
  const [loading, setLoading] = useState(true);
  const [pageNumber, setPageNumber] = useState(1);
  const [notificationFilter, setNotificationFilter] = useState("");
  const [notificationTypeFilter, setNotificationTypeFilter] = useState("");
  const [notificationSearchText, setNotificationSearchText] = useState("");
  const [searchText, setSearchText] = useState("");

  const webex = useSelector(state => state.webex);

  // const [isOpen, setIsOpen] = useState(false);
  const [showBellIcon, setShowBellIcon] = useState(false);
  const [showUnreadDot, setShowUnreadDot] = useState('N');
  const [unreadNotificationCount, setUnreadNotificationCount] = useState('N');
  const navigate = useNavigate();
  const isSwitch = localStorage.getItem('switch') ? decruptCipherString(localStorage.getItem('switch')) : '';
  const [validUrl, setValidUrl] = useState(isSwitch);
  const menuArr = localStorage.getItem('dashboard') ? localStorage.getItem('dashboard') : '';
  const dashboard = decruptCipherJson(menuArr);
  const [notificationsList, setNotificationsList] = useState([]);

  useEffect(() => {
    if (dashboard.value == 5) {
      setShowBellIcon(true);
    }
  }, []);

  const { addUserTab } = useUserTabs();
  const handleUserClick = (user) => {
    addUserTab(user);
  };
  useEffect(() => {
    if (dashboard.value == '5' && pageNumber > 1)
      getNotificationsList();
  }, [pageNumber])

  useEffect(() => {
    if (dashboard.value == '5') {
      setNotificationsList([]);
      getNotificationsList();
    }
  }, [notificationFilter, notificationSearchText, notificationTypeFilter])

  const observer = useRef();
  const lastUserElementRef = useCallback(node => {
    if (loading) return
    if (observer.current) observer.current.disconnect();
    observer.current = new IntersectionObserver(entries => {
      if (entries[0].isIntersecting && hasMore) {
        setPageNumber(prevPageNumber => prevPageNumber + 1)
      }
    })
    if (node) observer.current.observe(node)
  }, [loading, hasMore]);

  const getNotificationsList = async () => {
    const request = axios.CancelToken.source();
    let ct = {
      cancelToken: request.token
    }
    await fetchNotificationsListV2(pageNumber, notificationFilter, notificationTypeFilter, notificationSearchText, ct)
      .then((response) => {
        if (response.data.code === 200) {
          //setShowUnreadDot(response.data.data.showUnreadDot);
          setUnreadNotificationCount(response.data.data.unreadNotificationCount);
          if (response.data.data.unreadNotificationCount > 0) {
            setShowUnreadDot("Y");
          } else {
            setShowUnreadDot("N");
          }
          setNotificationsList(prevNotifications => {
            const newNotifications = (response.data.data.notifications).filter(notification =>
              !prevNotifications.some(existingNotification => existingNotification.notificationId === notification.notificationId)
            );
            return [...prevNotifications, ...newNotifications];
          });

          if (response.data.data.notifications.length > 0) setHasMore(true);
          else setHasMore(false);
          setLoading(false);
        }
      }).catch(function (error) {
        console.log(error);
      });
    return () => request.cancel();
  }

  const updateNotificationAction = async (notificationId, notificationRead) => {
    const request = axios.CancelToken.source();
    let ct = {
      cancelToken: request.token
    }
    await updateNotifications(notificationId, "actiontaken", ct)
      .then((response) => {
        //getNotifications();
        setNotificationsList((prevNotifications) =>
          prevNotifications.map((item) =>
            item.notificationId === notificationId
              ? { ...item, notificationRead: "Y" }
              : item
          )
        );
        if (notificationRead == "N" && unreadNotificationCount > 0) {
          setUnreadNotificationCount(unreadNotificationCount - 1);
        }
      }).catch(function (error) {
        console.log(error);
      });
    return () => request.cancel();
  }

  const handleShowUnreadDot = (ifUnread, unreadNotificationCount) => {
    setShowUnreadDot(ifUnread);
    setUnreadNotificationCount(unreadNotificationCount);
  };

  const handleRemove = async (index, notificationId) => {
    const request = axios.CancelToken.source();
    let ct = {
      cancelToken: request.token
    }
    await removeNotifications(notificationId, ct)
      .then((response) => {
        const updatedItems = notificationsList.filter((_, i) => i !== index);
        setNotificationsList(updatedItems);
      }).catch(function (error) {
        console.log(error);
      });
    return () => request.cancel();

  };

  function handleNotificationSearch(e) {
    e.stopPropagation();
    if (searchText != "") {
      setNotificationSearchText(searchText);
      setPageNumber(1);
    }
  }

  const handleMarkAllRead = (e) => {
    e.stopPropagation();
    alertify.confirm(`Confirm`, `Are you sure you want to mark all the notifications as read?`, () => {
      markAllRead();
    }, () => {
    });
  }

  const markAllRead = async (fileId) => {
    const request = axios.CancelToken.source();
    let ct = {
      cancelToken: request.token
    }
    await markAllNotificationsRead(ct)
      .then((response) => {
        if (response.data.code === 200) {
          setPageNumber(1);
          setNotificationsList([]);
          getNotificationsList();
        }
      }).catch(function (error) {
        console.log(error);
      });
    return () => request.cancel();
  }

  return (
    <>
      <nav className="navbar sticky-top bg-light bg-navbar pt-1">
        <div className="container-fluid mb-2">
          <section style={{ width: "100%" }}>
            <div className="row align-items-center">
              {showBellIcon && <div className="col-auto pe-5">
                <h4 className="mb-0 title-main">
                  <img src="https://storage.googleapis.com/ksabupatest/2024/09/24/j0sal/h87oluwjzj.png" className="logo-bupa-nav pe-2" alt="Profile" />
                </h4>
              </div>}
              <div className="col ps-4 pe-4 text-center">
                <h4 className="mb-0 title-main text-center">{dashboard.text} <span style={{ fontWeight: `${webex.webexLine ? 'bold' : 'normal'}` }}>{dashboard.version}</span></h4>
              </div>
              <div className='col-auto d-flex align-items-center'>
                {showBellIcon &&
                  <>
                    <div className="dropdown-center tw-pe-3 tw-ps-3">
                      <button onClick={getNotificationsList} className="btn btn-secondary dropdown-toggle notification-top-btn" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                        <i className="bi bi-bell-fill tw-mt-3"></i>
                        {
                          showUnreadDot === "Y" &&
                          <span className="tw-absolute tw-top-0 tw-start-[60%] tw-bg-amber-400 tw-animate-bounce tw-rounded-full tw-text-white tw-text-xs tw-px-1 border tw-border-white">
                            {
                              unreadNotificationCount > 0 && unreadNotificationCount
                            }
                          </span>

                        }
                      </button>
                      <ul className="dropdown-menu dropdown-menu-light p-0">
                        {
                          <li className='notification-item-border p-2'>
                            <Row>
                              <Col md="4" className='pe-2'>
                                <select name="notificationFilter" className='service-action' value={notificationFilter} onChange={(e) => {
                                  setNotificationFilter(e.target.value);
                                  setPageNumber(1);
                                }}>
                                  <option value="">All</option>
                                  <option value="N">Unread</option>
                                  <option value="Y">Read</option>
                                </select>
                              </Col>
                              <Col md="6" className='px-0'>
                                <select name="notificationTypeFilter" className='service-action' value={notificationTypeFilter} onChange={(e) => {
                                  setNotificationTypeFilter(e.target.value);
                                  setPageNumber(1);
                                }}>
                                  <option value="">All</option>
                                  <option value="callmissed">Call Missed</option>
                                  <option value="healthlocker">Health Locker</option>
                                  <option value="homebasedservice">Home Based Services</option>
                                  <option value="memberdisenroll">Member Disenroll</option>
                                  <option value="newmemberassigned">New Member Assigned</option>
                                  <option value="reenrolledmemberassigned">Re-enrolled Member Assigned</option>
                                </select>
                              </Col>
                              <Col md="2" className='ps-2'>
                                <button className='btn btn-success health-locker-btn service-action' style={{ lineHeight: "1.4 !important" }} onClick={handleMarkAllRead}>
                                  <i className="bi bi-check-lg" aria-hidden="true" ></i>
                                </button>

                              </Col>
                              <Col>
                                <div className="search mt-2 mb-0 d-flex">
                                  <input type="text" className='service-action' placeholder='Search' value={searchText} onChange={(e) => {
                                    setSearchText(e.target.value);
                                    if (e.target.value === "") {
                                      setNotificationSearchText("");
                                      setTimeout(() => {
                                        getNotificationsList();
                                      }, 500);
                                    }
                                  }}></input>
                                  <Button type="button" className='action-button blue-btn' onClick={handleNotificationSearch}>Search</Button>
                                </div>
                              </Col>
                            </Row>
                          </li>
                        }
                        {
                          notificationsList.length === 0 &&
                          <li className='notification-item-border p-2 tw-text-center tw-py-3 tw-text-gray-500 tw-text-sm' style={{ width: "390px" }}>
                            <Row>
                              <Col md="12" className='text-center'>
                                <span>No Notifications to display</span>
                              </Col>
                            </Row>
                          </li>
                        }
                        {
                          notificationsList.map((item, index) => {
                            if (notificationsList.length === index + 1) {
                              return (
                                <li ref={lastUserElementRef} key={index} className={`notification-item-border ${item.notificationRead === "Y" ? 'read-message' : 'unread-message'}`}>
                                  <a className="dropdown-item">
                                    <div className="notification-item-box py-2">
                                      <Link
                                        to={'/members/' + encodeURIComponent(item.userId, "UTF-8")}
                                        className=''
                                        onClick={() => {
                                          updateNotificationAction(item.notificationId, item.notificationRead);
                                          handleUserClick({ userId: item.userId, name: item.memberName });
                                        }}
                                      >
                                        <div className='row align-items-center'>
                                          <div className='col-2 text-center'>
                                            <i className={`bi ${item.notificationIcon} notification-icon`}></i>
                                          </div>
                                          <div className='col-10 ps-0'>
                                            <h1 className="notification-title">

                                              {item.memberName}


                                              {/* Delete Button with stopPropagation */}
                                              <Link onClick={(event) => {
                                                event.stopPropagation();  // Prevent dropdown from closing
                                                handleRemove(index, item.notificationId);
                                              }}>
                                                <i className="bi bi-trash" aria-hidden="true" style={{ fontSize: "14px" }} ></i>
                                              </Link>
                                            </h1>

                                            <h4 className="notification-subtitle">{item.notificationText}</h4>
                                            <span className="tw-text-xs tw-text-gray-500">{item.notificationTime}</span>
                                          </div>
                                        </div>
                                      </Link>
                                    </div>

                                  </a>
                                </li>
                              );
                            } else {
                              return (
                                <li key={index} className={`notification-item-border ${item.notificationRead === "Y" ? 'read-message' : 'unread-message'}`}>
                                  <a className="dropdown-item">
                                    <div className="notification-item-box py-2">
                                      <Link
                                        to={'/members/' + encodeURIComponent(item.userId, "UTF-8")}
                                        className=''
                                        onClick={() => {
                                          updateNotificationAction(item.notificationId, item.notificationRead);
                                          handleUserClick({ userId: item.userId, name: item.memberName });
                                        }}
                                      >
                                        <div className='row align-items-center'>
                                          <div className='col-2 text-center'>
                                            <i className={`bi ${item.notificationIcon} notification-icon`}></i>
                                          </div>
                                          <div className='col-10 ps-0'>
                                            <h1 className="notification-title">

                                              {item.memberName}


                                              {/* Delete Button with stopPropagation */}
                                              <Link onClick={(event) => {
                                                event.stopPropagation();  // Prevent dropdown from closing
                                                handleRemove(index, item.notificationId);
                                              }}>
                                                <i className="bi bi-trash" aria-hidden="true" style={{ fontSize: "14px" }} ></i>
                                              </Link>
                                            </h1>

                                            <h4 className="notification-subtitle">{item.notificationText}</h4>
                                            <span className="tw-text-xs tw-text-gray-500">{item.notificationTime}</span>
                                          </div>
                                        </div>
                                      </Link>
                                    </div>
                                  </a>
                                </li>
                              );
                            }
                          })
                        }
                      </ul>

                    </div>

                    {/* <div className="tw-relative tw-pe-4 tw-ps-3">
                      <button 
                        type="button" 
                        onClick={() => setIsOpen(!isOpen)}
                        className="btn message-icon position-relative"
                      >
                        <i className="bi bi-chat-left-fill"></i>
                        <span className="tw-absolute tw-top-0 tw-start-100 tw-translate-middle tw-bg-yellow-400 tw-border tw-border-white tw-rounded-full tw-h-2 tw-w-2">
                        </span>
                      </button> */}

                    <NotificationDropdown />
                    {/* </div> */}

                  </>}
              </div>
              <div className='col-auto ps-3'>

                <div className='col-auto ps-3'>
                  <div className="dropdown-center">
                    <button className="btn btn-secondary dropdown-toggle profile-dropdown" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                      <img src="https://storage.googleapis.com/ksabupatest/2024/09/24/dhdjh/898otsm9c0.png" className="profile-images" alt="Profile" /> {decruptCipherString(localStorage.getItem('user'))}
                    </button>
                    <ul className="dropdown-menu dropdown-menu-light">
                      {validUrl && <li><Link className="dropdown-item" to={validUrl}><i className="bi bi-card-list"></i> Switch Dashboard</Link></li>}
                      <li><a className="dropdown-item" href='/logout'><i className='bx bx-power-off' style={{ color: "#FF0000" }}></i> Log out</a></li>
                    </ul>
                  </div>
                </div>
                {/* <div className="col-auto px-3 text-end">
              <LanguageSetting />
            </div> */}
              </div>
            </div>
          </section>
        </div>
      </nav>
      {title === "cnDashboard" && <Notifications handleShowUnreadDot={handleShowUnreadDot} />}
      {/* <Commingsoon /> */}
    </>
  );
};

export default Navbar;
