import React, { useState, useEffect, forwardRef, useImperativeHandle } from 'react';
import BackendDrivenDataTable from '../../cn/components/common/BackendDrivenDataTable';
import { fetch_service_recommendation_info, edit_service_recommendation, fetch_service_recommendation_list, remove_service_recommendation, add_service_recommendation, fetch_metadoc_service_list } from '../../../services/medengage/Service';
import { validateFormServiceRecommendationsAdd } from '../../utils/validationUtils';
import { CustomLoader } from '../../utils/CommonLibrary';
import { MultiSelect } from 'react-multi-select-component';

const ServiceRecommendations = forwardRef((props, ref) => {

    const [totalRows, setTotalRows] = useState(0);
    const [page, setPage] = useState(1);
    const [perPage, setPerPage] = useState(10);
    const [sortColumn, setSortColumn] = useState("title");
    const [sortDirection, setSortDirection] = useState('asc');

    const [serviceList, setServiceList] = useState([]);
    const [selectedOptions, setSelectedOptions] = useState([]);

    const [passData, setPassData] = useState([]);
    const [isNewLoading, setIsNewLoading] = useState(false);
    const [searchQuery, setSearchQuery] = useState("");
    const [editId, setEditId] = useState("");
    const [deleteId, setDeleteId] = useState("");
    const [deleteMessage, setDeleteMessage] = useState("");
    const [dispositionDataList, setDispositionDataList] = useState([]);
    const [editShowModal, setEditShowModal] = useState(false);

    const handleClose = () => { setEditShowModal(false); }
    const [showModal, setShowModal] = useState(false);

    const handleAddClose = () => { setShowModal(false); }

    const [deleteShowModal, setDeleteShowModal] = useState(false);
    const handleDeleteClose = () => { setDeleteShowModal(false); }

    const [addCPContractName, setAddCPContractName] = useState("");
    const [addCPMemberCount, setAddCPMemberCount] = useState("");

    const [editCPContractName, setEditCPContractName] = useState("");
    const [editCPMemberCount, setEditCPMemberCount] = useState("");

    const formObject = {
        serviceType: "",
        title: "",
        services: []
    }

    const [formData, setFormData] = useState({ ...formObject });

    const [errors, setErrors] = useState({
        serviceType: "",
        title: "",
        services: ""
    })

    //List
    useEffect(() => {
        fetchData();
    }, [])

    useEffect(() => {
        fetchData();
    }, [page, perPage, sortColumn, sortDirection, searchQuery]);

    const fetchData = async () => {
        setIsNewLoading(true);
        try {
            let resp = await fetch_service_recommendation_list(searchQuery, page, perPage, sortColumn, sortDirection);
            if (resp.data.code == 200) {
                setPassData(resp.data.data.recommendations);
                setTotalRows(resp.data.data.totalRows);
            }
        } catch (error) {
            console.error(error);
        } finally {
            setIsNewLoading(false);
        }
    }

    const columns = [
        {
            name: 'Service Type',
            selector: row => row.serviceType,
            sortable: true,
        },
        {
            name: 'Title',
            selector: row => row.title,
            sortable: true,

        },
        {
            name: 'Action',
            cell: row => (
                <div><button onClick={() => {
                    setErrors("");
                    setShowEditModalOpen(row.id);
                }} class="btn btn-Edit"><i className="bi bi-pencil"></i></button> | <button onClick={() => setShowDeleteModalOpen(row.id)} class="btn btn-Cancel"><i className="bi bi-trash"></i></button></div>
            ),
        },
    ];

    const handleClear = () => {
        setSearchQuery("");
    };


    //Add Modal
    const setShowModalOpen = async (e) => {
        setErrors({});
        setSelectedOptions([]);
        setServiceList([]);
        setFormData({ ...formObject });
        setShowModal(true);
    }

    const getServiceList = async (serviceType) => {
        setIsNewLoading(true);
        try {
            let resp = await fetch_metadoc_service_list(serviceType);
            if (resp.data.code == 200) {
                setServiceList(resp.data.data.serviceList);
            }
        } catch (error) {
            console.error(error);
        } finally {
            setIsNewLoading(false);
        }
    }

    const addNew = async (e) => {
        e.preventDefault();
        setErrors("")

        const validationErrors = validateFormServiceRecommendationsAdd(formData);
        if (Object.keys(validationErrors).length > 0) {
            setErrors(validationErrors);
        } else {
            add_service_recommendation(formData)
                .then((response) => {
                    if (response.data.code == 200) {
                        setShowModal(false);
                        fetchData();
                    } else {
                        setErrors({
                            title: response.data.data.message
                        })
                    }
                });
        }
    }

    //Edit Modal
    const setShowEditModalOpen = async (id) => {
        setEditId(id);
        setErrors("");
        setIsNewLoading(true);
        try {
            let resp = await fetch_service_recommendation_info(id);
            if (resp.data.code == 200) {
                getServiceList(resp.data.data[0].serviceType);
                setSelectedOptions(resp.data.data[0].services);
                setFormData(resp.data.data[0]);
                setEditShowModal(true);
            }
        } catch (error) {
            console.error(error);
        } finally {
            setIsNewLoading(false);
        }

    }
    const setShowDeleteModalOpen = async (id) => {
        setDeleteId(id);
        setDeleteShowModal(true);
    };
    const submitDeleteDisposition = async (deleteId) => {

        try {
            let resp = await remove_service_recommendation(deleteId);
            if (resp.data.code == 200) {
                setDeleteMessage(resp.data.data.message);
                setDeleteShowModal(false);
                fetchData();
            }
        } catch (error) {
            console.error(error);
        } finally {
            //setIsLoading(false); // Will be called regardless of success or failure
        }


    }

    const handleChange = (event) => {
        setFormData((prevData) => ({ ...prevData, ['contractNumber']: event.target.value }));
    };


    const editServiceRecommendation = async (e) => {
        e.preventDefault();
        setErrors("")

        const validationErrors = validateFormServiceRecommendationsAdd(formData);

        if (Object.keys(validationErrors).length > 0) {
            setErrors(validationErrors);
        } else {
            const combinedData = {
                ...formData,
                id: editId
            };
            edit_service_recommendation(combinedData)
                .then((response) => {
                    if (response.data.code == 200) {
                        setEditShowModal(false);
                        fetchData();

                    }
                });
        }
    }

    const onValueChange = (e) => {
        if (e.target.name == "serviceType" && e.target.value == "") {
            setFormData({ ...formData, [e.target.name]: e.target.value, services: [] });
            setServiceList([]);
            setSelectedOptions([]);
        } else {
            setFormData({ ...formData, [e.target.name]: e.target.value });
        }
    }
    console.log(formData, errors);
    const handleChangeService = (selected) => {
        setSelectedOptions(selected);
        //const selectedValues = selected.map(option => option.value).join(',');
        setFormData({ ...formData, services: selected });
    };
    const selectedUserNamesTemp = selectedOptions.map(option => option.label).join(', ');


    return (
        <>
            <div className='container-fluid'>
                <div className='row mb-3'>
                    <div className='col-md-4 d-flex px-1'>
                        <div className="form-group" style={{ width: "100%" }}>
                            <input
                                type="text"
                                placeholder="Search by Service Type or Title"
                                value={searchQuery}
                                onChange={e => {
                                    setPage(1);
                                    setSearchQuery(e.target.value);
                                }}
                                className="form-control input-field-ui ps-5"
                            />
                            <img src="https://storage.googleapis.com/ksabupatest/2024/09/24/ljce8/fac3csq13o.png" className="input-icon-seacrh" alt="" />
                        </div>
                    </div>
                    <div className='col-md-4 px-1'>
                        <button onClick={handleClear} className='btn btn-primary clear-search-btn'>Clear</button>
                    </div>
                    <div className='col-md-4 px-1' style={{ textAlign: 'right' }}>
                        <button onClick={setShowModalOpen} className="btn btn-primary add-new-program-btn">
                            <i className="bi bi-card-checklist me-2"></i> Add New Service Recommendation
                        </button>
                    </div>

                </div>
            </div>

            {
                isNewLoading ? <CustomLoader /> :
                    <BackendDrivenDataTable
                        data={passData}
                        loading={isNewLoading}
                        totalRows={totalRows}
                        page={page}
                        perPage={perPage}
                        sortColumn={sortColumn}
                        sortDirection={sortDirection}
                        filterText={searchQuery}
                        columns={columns}
                        fetchTableData={fetchData}
                        setPage={setPage}
                        setSortColumn={setSortColumn}
                        setSortDirection={setSortDirection}
                        setPerPage={setPerPage}
                    />
            }
            {
                showModal &&
                <>
                    <div className="modal fade show" id="addNewModal" tabIndex="-1" aria-hidden="false" style={{ display: 'block' }}>
                        <div className="modal-dialog modal-dialog-centered modal-md">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h4 className="main-title">Add New Service Recommendation</h4>
                                    <button
                                        type="button"
                                        className="btn-close"
                                        onClick={handleAddClose}
                                    ></button>
                                </div>
                                <div class="modal-body pt-1 mb-2">
                                    <form className="" onSubmit={addNew}>
                                        <div className="row">
                                            <div className="col pe-0 mb-3">
                                                <label htmlFor='Condition' class="form-label">Service Type </label>
                                                <span style={{ color: 'red' }}> *</span>
                                                <select class="form-select input-field-ui" name="serviceType" aria-label="Default select example" value={formData['serviceType']} onChange={(e) => {
                                                    onValueChange(e);
                                                    if (e.target.value != "") {
                                                        getServiceList(e.target.value);
                                                    }
                                                }}>
                                                    <option value="">Select</option>
                                                    <option value="hbl">Lab Request</option>
                                                </select>
                                                {errors['serviceType'] && <div className="text-danger">{errors['serviceType']}</div>}
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col pe-0 mb-3">
                                                <label htmlFor='Condition' class="form-label">Title </label>
                                                <span style={{ color: 'red' }}> *</span>
                                                <input type="text"
                                                    className="form-control input-field-ui"
                                                    placeholder="Title"
                                                    rows="3"
                                                    name="title"
                                                    onChange={(e) => {
                                                        onValueChange(e);
                                                    }} value={formData['title']}
                                                />
                                                {errors['title'] && <div className="text-danger">{errors['title']}</div>}
                                            </div>
                                            {
                                                serviceList.length > 0 &&
                                                <>
                                                    <div className="col-md-12 mb-3">
                                                        <label htmlFor='Condition' class="form-label">Services</label>
                                                        <span style={{ color: 'red' }}> *</span>
                                                        <MultiSelect
                                                            options={serviceList}
                                                            value={selectedOptions}
                                                            onChange={handleChangeService}
                                                            labelledBy="Select"
                                                            hasSelectAll={false}
                                                            disableSearch={false}
                                                            overrideStrings={{
                                                                selectAll: 'Select All',
                                                                allItemsAreSelected: 'All items are selected',
                                                                selectSomeItems: 'Select items',
                                                            }}
                                                            className="form-select select-input-ui select-input-ui-select"
                                                            name="requestedLabTest"
                                                            id="requestedLabTest"

                                                        />
                                                        {(errors['services']) && <div className="text-danger">{errors['services']}</div>}
                                                    </div>
                                                    <div className='col-md-12 mb-3'>
                                                        {selectedUserNamesTemp}
                                                    </div>
                                                </>

                                            }
                                            <div className="col-md-12 mt-2 text-center">
                                                <button
                                                    type="submit"
                                                    className="btn btn-primary program-save-btn"
                                                >
                                                    Add
                                                </button>
                                            </div>
                                        </div>
                                    </form>
                                </div>

                            </div>
                        </div>
                    </div >
                    <div className="modal-backdrop fade show"></div>
                </>
            }
            {
                editShowModal &&
                <>
                    <div className="modal fade show" id="editNewServiceModal" tabIndex="-1" aria-hidden="false" style={{ display: 'block' }}>
                        <div className="modal-dialog modal-dialog-centered modal-md">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h4 className="main-title">Edit Service Recomendation</h4>
                                    <button
                                        type="button"
                                        className="btn-close"
                                        onClick={handleClose}
                                    ></button>
                                </div>
                                <div class="modal-body pt-1 mb-2">
                                    <form className="" onSubmit={editServiceRecommendation}>
                                        <div className="row">
                                            <div className="col-md-12 mb-3">
                                                <label htmlFor='Condition' class="form-label">Service Type </label>
                                                <span style={{ color: 'red' }}> *</span>
                                                <select class="form-select input-field-ui" name="serviceType" aria-label="Default select example" value={formData['serviceType']} onChange={(e) => {
                                                    onValueChange(e);
                                                    if (e.target.value != "") {
                                                        getServiceList(e.target.value);
                                                    }
                                                }}>
                                                    <option value="">Select</option>
                                                    <option value="hbl">Lab Request</option>
                                                </select>
                                                {(errors['serviceType']) && <div className="text-danger">{errors['serviceType']}</div>}
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col pe-0 mb-3">
                                                <label htmlFor='Condition' class="form-label">Title </label>
                                                <span style={{ color: 'red' }}> *</span>
                                                <input type="text"
                                                    className="form-control input-field-ui"
                                                    placeholder="Title"
                                                    rows="3"
                                                    name="title"
                                                    onChange={(e) => {
                                                        onValueChange(e);
                                                    }}
                                                    value={formData['title']}
                                                />
                                                {errors['title'] && <div className="text-danger">{errors['title']}</div>}
                                            </div>
                                            {
                                                serviceList.length > 0 &&
                                                <>
                                                    <div className="col-md-12 mb-3">
                                                        <label htmlFor='Condition' class="form-label">Services</label>
                                                        <span style={{ color: 'red' }}> *</span>
                                                        <MultiSelect
                                                            options={serviceList}
                                                            value={selectedOptions}
                                                            onChange={handleChangeService}
                                                            labelledBy="Select"
                                                            hasSelectAll={false}
                                                            disableSearch={false}
                                                            overrideStrings={{
                                                                selectAll: 'Select All',
                                                                allItemsAreSelected: 'All items are selected',
                                                                selectSomeItems: 'Select items',
                                                            }}
                                                            className="form-select select-input-ui select-input-ui-select"
                                                            name="requestedLabTest"
                                                            id="requestedLabTest"

                                                        />
                                                        {(errors['services']) && <div className="text-danger">{errors['services']}</div>}
                                                    </div>
                                                    <div className='col-md-12 mb-3'>
                                                        {selectedUserNamesTemp}
                                                    </div>
                                                </>
                                            }
                                        </div>
                                        <div className="col-md-12 mt-2 text-center">
                                            <button
                                                type="submit"
                                                className="btn btn-primary program-save-btn"
                                            >
                                                Save
                                            </button>
                                        </div>
                                    </form>
                                </div>

                            </div>
                        </div>

                    </div >
                    <div className="modal-backdrop fade show"></div>
                </>
            }



            {
                deleteShowModal && (
                    <div className="modal fade show" id="deleteConfirmationModal" tabIndex="-1" aria-hidden="false" style={{ display: 'block' }}>
                        <div className="modal-dialog modal-dialog-centered">
                            <div className="modal-content">
                                {/* Modal Header */}
                                <div className="modal-header">
                                    <h5 className="modal-title fw-semibold">Delete Confirmation</h5>
                                    <button
                                        type="button"
                                        className="btn-close"
                                        onClick={handleDeleteClose}
                                        aria-label="Close"
                                    ></button>
                                </div>

                                {/* Modal Body */}
                                <div className="modal-body py-4">
                                    <p className="text-center mb-0">
                                        Are you sure you want to delete this service recommendation?
                                    </p>
                                </div>

                                {/* Modal Footer */}
                                <div className="tw-flex tw-justify-center tw-py-4">
                                    {/* <button
                                    type="button"
                                    className="btn btn-secondary"
                                    onClick={handleDeleteClose}
                                >
                                    Cancel
                                </button> */}
                                    <button
                                        type="button"
                                        className="btn btn-primary program-save-btn"
                                        onClick={() => submitDeleteDisposition(deleteId)}
                                    >
                                        Delete
                                    </button>
                                </div>
                            </div>
                        </div>

                        {/* Modal Backdrop */}
                        <div className="modal-backdrop fade show"></div>
                    </div>
                )
            }


        </>

    );
});

export default ServiceRecommendations