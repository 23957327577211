import React, { useState, useEffect } from "react";

const TreeStructure = ({ contentData, onSelectionChange, clearSelectionsTrigger,selectedProcedureNew,selectedTopicNew,selectedContentsNew }) => {
  const [selectedItems, setSelectedItems] = useState({
    procedure: [],
    topics: ['104_7'],
    contents: [],
  });

  const [collapsedSections, setCollapsedSections] = useState({});

  // Initialize collapsed sections when contentData changes
  useEffect(() => {
    if (contentData && contentData.length > 0) {
      const initialCollapsedState = initializeCollapsedSections(contentData);
      setCollapsedSections(initialCollapsedState);
    }
  }, [contentData]);

  useEffect(() => {
    if (onSelectionChange) {
      onSelectionChange(selectedItems);
    }
  }, [selectedItems, onSelectionChange]);

  useEffect(() => {
    // Reset selected items when clearSelectionsTrigger changes
    setSelectedItems({
      procedure: [],
      topics: [],
      contents: [],
    });
  }, [clearSelectionsTrigger]);

  useEffect(() => {
    
    if (selectedProcedureNew && selectedProcedureNew.length > 0) {
      selectedProcedureNew.forEach((selectedProcedureId) => {
        handleCheckboxChange("procedure", selectedProcedureId,'pageload');
      });
    }

    if (selectedTopicNew && selectedTopicNew.length > 0) {
      selectedTopicNew.forEach((selectedTopicId) => {
        handleCheckboxChange("topics", selectedTopicId,'pageload');
      });
    }

    if (selectedContentsNew && selectedContentsNew.length > 0) {
      selectedContentsNew.forEach((selectedContentsId) => {
        handleCheckboxChange("contents", selectedContentsId,'pageload');
      });
    }
  }, [selectedProcedureNew,selectedTopicNew,selectedContentsNew]);

  // Dynamically initialize collapsedSections based on response data
  const initializeCollapsedSections = (contentData) => {
    const initialState = {
      procedure: {},
      topics: {},
      contents: {},
    };

    contentData.forEach((procedure) => {
      initialState.procedure[procedure.id] = true; // Collapse procedures by default

      procedure.topics.forEach((topic) => {
        initialState.topics[topic.id] = true; // Collapse all topics by default

        topic.contents.forEach((content) => {
          initialState.contents[content.id] = true; // Collapse all contents by default
        });
      });
    });

    return initialState;
  };

  const handleCheckboxChange = (type, id,from) => {
    setSelectedItems((prev) => {
      const updatedItems = { ...prev };
  
      if (type === "procedure") {
        const procedure = contentData.find((procedure) => procedure.id === id);
  
        if (updatedItems.procedure.includes(id)) {
          // Deselect all related topics and contents
          updatedItems.procedure = updatedItems?.procedure.filter((item) => item !== id);
          
          procedure?.topics?.forEach((topic) => {
            updatedItems.topics = updatedItems?.topics.filter((topicId) => topicId !== topic.id);
            topic?.contents?.forEach((content) => {
              updatedItems.contents = updatedItems.contents.filter((contentId) => contentId !== content.id);
            });
          });
        } else {
          // Select all related topics and contents
          
          updatedItems.procedure.push(id);
          procedure?.topics?.forEach((topic) => {
            if (!updatedItems.topics.includes(topic.id)) {
              updatedItems.topics.push(topic.id);
            }
            const element = document.getElementById(`topic-${topic.id}`);
            if (element) {
              element.classList.remove('selected-checkbox');
            }
            topic?.contents?.forEach((content) => {
              if (!updatedItems.contents.includes(content.id)) {
                updatedItems.contents.push(content.id);
              }
            });
          });
          // document.getElementById(`procedure-${procedure.id}`).classList?.remove('selected-checkbox');
          if(procedure){
            const element = document.getElementById(`procedure-${procedure.id}`);
            if (element) {
              element.classList.remove('selected-checkbox');
            }
          }
        }
      } else if (type === "topics") {
        const topic = contentData
          .flatMap((procedure) => procedure.topics)
          .find((topic) => topic.id === id);
  
        if (updatedItems.topics.includes(id)) {
          // Deselect topic and its contents
          updatedItems.topics = updatedItems.topics.filter((topicId) => topicId !== id);
          topic?.contents?.forEach((content) => {
            updatedItems.contents = updatedItems.contents.filter((contentId) => contentId !== content.id);
          });
  
          // Check if the parent procedure should be partially selected
          const parentProcedure = contentData.find((procedure) =>
            procedure.topics.some((t) => t.id === id)
          );
          if (parentProcedure) {
            const allTopicsSelected = parentProcedure.topics.every((t) =>
              updatedItems.topics.includes(t.id)
            );
            if (!allTopicsSelected) {
              updatedItems.procedure = updatedItems.procedure.filter((procedureId) => procedureId !== parentProcedure.id);
              document.getElementById(`procedure-${parentProcedure.id}`).classList.add('selected-checkbox');
            }else{
              document.getElementById(`procedure-${parentProcedure.id}`).classList.remove('selected-checkbox');
              document.getElementById(`topic-${topic.id}`).classList.remove('selected-checkbox');
            }

            const allTopicsCount = parentProcedure.topics.filter((t) =>
              updatedItems.topics.includes(t.id)
            ).length;
            
            if(allTopicsCount == 0){
              document.getElementById(`procedure-${parentProcedure.id}`).classList.remove('selected-checkbox');
            }
          }
        } else {
          // Select topic and its contents
          updatedItems.topics.push(id);
          topic?.contents?.forEach((content) => {
            if (!updatedItems.contents.includes(content.id)) {
              updatedItems.contents.push(content.id);
              
            }
          });
  
          // Check if the parent procedure should be fully selected
          const parentProcedure = contentData.find((procedure) =>
            procedure.topics.some((t) => t.id === id)
          );
          
          if (parentProcedure) {
            const allTopicsSelected = parentProcedure.topics.every((t) =>
              updatedItems.topics.includes(t.id)
            );
            
            if (allTopicsSelected && !updatedItems.procedure.includes(parentProcedure.id)) {
              updatedItems.procedure.push(parentProcedure.id);
              document.getElementById(`procedure-${parentProcedure.id}`).classList.remove('selected-checkbox');
              document.getElementById(`topic-${topic.id}`).classList.remove('selected-checkbox');
            }else{
              document.getElementById(`topic-${topic.id}`).classList.remove('selected-checkbox');
              document.getElementById(`procedure-${parentProcedure.id}`).classList.add('selected-checkbox');
            }
          }
        }
      } else {
        // Handle content selection/deselection
        const topic = contentData
          .flatMap((procedure) => procedure.topics)
          .find((topic) => topic.contents.some((content) => content.id === id));
        
        if (updatedItems.contents.includes(id)) {
          // Deselect content
          if(from == 'checkbox'){
            updatedItems.contents = updatedItems.contents.filter((contentId) => contentId !== id);
          }
  
          // Check if the parent topic should be partially selected
          if (topic) {
            const allContentsSelected = topic.contents.every((content) =>
              updatedItems.contents.includes(content.id)
            );
            
            if (!allContentsSelected) {
              updatedItems.topics = updatedItems.topics.filter((topicId) => topicId !== topic.id);
              document.getElementById(`topic-${topic.id}`).classList.add('selected-checkbox');
            }

            const selectedCount = topic.contents.filter((content) =>
              updatedItems.contents.includes(content.id)
            ).length;
            
            if(selectedCount == 0){
              document.getElementById(`topic-${topic.id}`).classList.remove('selected-checkbox');
            }
  
            // Check if the parent procedure should be partially selected
            const parentProcedure = contentData.find((procedure) =>
              procedure.topics.some((t) => t.id === topic.id)
            );

            if (parentProcedure) {
              const allTopicsSelected = parentProcedure.topics.every((t) =>
                updatedItems.topics.includes(t.id)
              );
              if (!allTopicsSelected) {
                updatedItems.procedure = updatedItems.procedure.filter(
                  (procedureId) => procedureId !== parentProcedure.id
                );
              }

              const selectedTopicCount = parentProcedure.topics.filter((t) =>
                updatedItems.topics.includes(t.id)
              ).length;
              

              if(selectedCount == 0 && selectedTopicCount == 0){
                document.getElementById(`procedure-${parentProcedure.id}`).classList.remove('selected-checkbox');
              }else{
                document.getElementById(`procedure-${parentProcedure.id}`).classList.add('selected-checkbox');
              }
            }
          }
        }  else {
          // Select content
          updatedItems.contents.push(id);
  
          // Check if the parent topic should be fully selected
          if (topic) {
            const allContentsSelected = topic.contents.every((content) =>
              updatedItems.contents.includes(content.id)
            );
            
            if (allContentsSelected && !updatedItems.topics.includes(topic.id)) {
              updatedItems.topics.push(topic.id);
              document.getElementById(`topic-${topic.id}`).classList.remove('selected-checkbox');
            }else{
              document.getElementById(`topic-${topic.id}`).classList.add('selected-checkbox');
            }
  
            // Check if the parent procedure should be fully selected
            const parentProcedure = contentData.find((procedure) =>
              procedure.topics.some((t) => t.id === topic.id)
            );
            
            if (parentProcedure) {
              const allTopicsSelected = parentProcedure.topics.every((t) =>
                updatedItems.topics.includes(t.id)
              );
              if (allTopicsSelected && !updatedItems.procedure.includes(parentProcedure.id)) {
                updatedItems.procedure.push(parentProcedure.id);
                document.getElementById(`procedure-${parentProcedure.id}`).classList.remove('selected-checkbox');
              }else{
                document.getElementById(`procedure-${parentProcedure.id}`).classList.add('selected-checkbox');
              }
            }
          }
        }
      }
  
      return updatedItems;
    });
  };

  const toggleCollapse = (type, id) => {
    setCollapsedSections((prev) => {
      const updatedSections = { ...prev };

      // Ensure the section exists before toggling its state
      if (!updatedSections[type]) {
        updatedSections[type] = {};
      }

      updatedSections[type][id] = !updatedSections[type][id];
      return updatedSections;
    });
  };

  const formatTime = (totalSeconds) => {
    const minutes = Math.floor(totalSeconds / 60);
    const remainingSeconds = totalSeconds % 60;
    return `${minutes}:${remainingSeconds < 10 ? "0" : ""}${remainingSeconds}`;
  };

  if (!contentData || contentData.length === 0) {
    return <div>Loading...</div>;
  }

  return (
    <div>
      {contentData.map((procedure) => (
        <div key={procedure.id} className="expandable-title-new mb-2">
          <div className="row">
            <div className="col-auto pe-2">
              <input
                type="checkbox"
                id={`procedure-${procedure.id}`}
                className="form-check-input form-check-input-ui m-0"
                checked={selectedItems.procedure.includes(procedure.id)}
                onChange={() => handleCheckboxChange("procedure", procedure.id,'checkbox')}
              />
            </div>
            <div
              className="col text-start ps-0"
              onClick={() => toggleCollapse("procedure", procedure.id)}
              style={{ cursor: "pointer" }}
            >
              {procedure.title}
            </div>
            <div
              className="col-auto"
              onClick={() => toggleCollapse("procedure", procedure.id)}
              style={{ cursor: "pointer" }}
            >
              {collapsedSections.procedure && collapsedSections.procedure[procedure.id] ? (
                <i className="bi bi-caret-down-fill"></i>
              ) : (
                <i className="bi bi-caret-up-fill"></i>
              )}
            </div>
          </div>

          {/* Show topics only if the procedure is expanded */}
          {/* collapsedSections.procedure && !collapsedSections.procedure[procedure.id] */}
          {
            procedure.topics.map((topic) => (
              <div
                key={topic.id}
                className="card card-task-appoint mt-2"
                // style={{ borderRadius: "6px" }}
                style={{
                  display: collapsedSections.procedure && !collapsedSections.procedure[procedure.id] ? 'block' : 'none',borderRadius: "6px"
                }}
              >
                <div className="card-body p-2">
                  <h3 className="expandable-title-inner-content mb-0">
                    <input
                      type="checkbox"
                      id={`topic-${topic.id}`}
                      className="form-check-input form-check-input-ui mt-0 me-1"
                      checked={selectedItems.topics.includes(topic.id)}
                      onChange={() => handleCheckboxChange("topics", topic.id,'checkbox')}
                    />
                    <button
                      onClick={() => toggleCollapse("topics", topic.id)}
                      className="expandable-title-inner-button"
                    >
                      <div className="row align-items-center">
                        <div className="col text-start">{topic.title}</div>
                        <div className="col-auto text-end pe-2">
                          <div className="action-button blue-btn py-1 px-2">
                          <span >
                          <i className="bi bi-play-fill pe-1"></i>  {topic.contents.length}
                          </span>

                          {collapsedSections.topics && collapsedSections.topics[topic.id] ? (
                            <i className="bi-chevron-down ms-2"></i>
                          ) : (
                            <i className="bi-chevron-up ms-2"></i>
                          )}
                          </div>
                          

                        </div>
                      </div>
                    </button>
                  </h3>
                  {/* Show contents only if the topic is expanded */}
                  {collapsedSections.topics && !collapsedSections.topics[topic.id] &&
                    topic.contents.map((content) => (
                      <div key={content.id} className="checkbox-fl1">
                        <div style={{ display: "inline-flex", width:"100%" }}>
                          <input
                            type="checkbox"
                            id={content.id}
                            className="form-check-input form-check-input-ui"
                            checked={selectedItems.contents.includes(content.id)}
                            onChange={() => handleCheckboxChange("contents", content.id,'checkbox')}
                          />
                          <label
                            htmlFor={content.id}
                            className="form-check-label-expandeable"
                          >
                            {content?.flavor?.downloadable && (
                              <>
                                <video
                                  width="122"
                                  height="68"
                                  controls
                                  src={content.flavor.downloadable}
                                  poster={content.flavor.picture}
                                  className="me-3"
                                  style={{borderRadius:"8px"}}
                                  controlsList="nodownload"
                                >
                                  Your browser does not support the video tag.
                                </video>
                              </>
                            )}
                            
                            <div className="row align-items-center" style={{width:"100%"}}>
                                <div className="col-12">
                                  <h6 className="mb-1" style={{fontSize:"14px"}}>
                                    {content.title}
                                  </h6>
                                </div>
                                <div className="col-7 d-grid">
                                  <span><i className="bi bi-clock"></i>  {formatTime(content.flavor.duration)}</span>
                                  <span><i style={{fontSize:"12px"}}>Assigned at 01 Jan, 2025</i></span>
                                  <span><i style={{fontSize:"12px"}}>Last viewed at 07 Jan, 2025</i></span>
                                </div>
                                <div className="col-5 d-grid">
                                  <span class="badge badge-bg-completed my-1">100% Completed</span>
                                  <span class="badge badge-bg-inprogress my-1">64% Completed</span>
                                  <span class="badge badge-bg-pending my-1">0% Completed</span>
                                </div>
                              </div>
                          </label>
                        </div>
                      </div>
                    ))}
                </div>
              </div>
            ))}
        </div>
      ))}
    </div>
  );
};

export default TreeStructure;