import React, { useEffect, useState } from 'react'; 
import { Link } from 'react-router-dom';
import PageDescription from '../../components/PageDescription';
import Pagination from '../../../utils/Pagination';
import Table from '../../components/Table';
import { list_all_observations, fetch_all_observation_statuses, export_observations_data, fetch_observation_stats } from '../../../../services/observations/Service';
import '../../css/observations.css';
import useDashboard from '../../../../Hooks/useDashboard';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import StatusDistribution from '../../components/Graphs/Statusdistribution';
import PriorityDistribution from '../../components/Graphs/PriorityDistribution';
import ToolTip from '../../components/ToolTip';
import TypeDistribution from '../../components/Graphs/TypeDistributino';
import { capitalizeFirstLetter, replaceUnderScoreWithSpace } from '../../../utils/Utils';
import { useSelector, useDispatch } from 'react-redux';
import {setObseravationsCurrentPage, setObservationsPerPage, setObservationsTotalEntries, setObservationsTotalPages} from '../../../../store/paginationSlice'
import {setSortFilterDirection, setSortFilterField, setFilter} from '../../../../store/fitler/observationFitlerSlice';

const AllObservations = ({paths={add: '/add-observation', view: '/observations'}, role=null}) => {
    const pagination = useSelector(state => state.pagination.observations);
    const sortConfig = useSelector(state => state.filter.sort);
    const filters = useSelector(state => state.filter.filter);
    const [query, setQuery] = useState(""); 
    const dispatch = useDispatch();

    const {add, view} = paths;
    const dashboardData = useDashboard();
    const [loading, setLoading] = useState(true);
    const [observations, setObservations] = useState([]); 
    const [error, setError] = useState("");




    const [statusList, setStatusList] = useState([]); 
    const [descriptionText, setDescriptionText] = useState({
        moderator: "You can raise new observations, share suggestions, or request enhancements to help us improve your experience. Track the progress of your submissions, view comments, and check the status of each observation right here.", 

        user: "You can raise new observations, share suggestions, or request enhancements to help us improve your experience. Track the progress of your submissions, view comments, and check the status of each observation right here", 
        
        goqii: "Here you can review, approve, or reject observations submitted by users. Use the available tools to manage priority levels, request additional information, and ensure observations are routed effectively for resolution."
    });

    // const [filters, setFilters] = useState({
    //     searchQuery: '',
    //     status: '',
    //     priority: ''
    // });
    
    const [stats, setStats] = useState({
        total: 0,
        open: 0,
        closed: 0,
        graphs: {
            statusDistribution: {
                PendingReview: 0,
                Closed: 0,
                Reopened: 0,
                InProgress: 0,
                Rejected: 0,
                NeedMoreInformation: 0,
                PendingAcceptance: 0,
                NotanIssue: 0,
                Duplicate: 0,
                OutOfScope: 0,
                BupaDependancy: 0,
                DependancyResolved: 0
            },
            priorityDistribution: {
                blocker: 0, 
                major: 0,
                minor: 0,
                good_to_have: 0
            }, 
            typeDistribution: {
                newRequirement: 0,
                suggestion: 0,
                issue: 0,
                enhancement: 0
            }
        }
    });

    useEffect(() => {
        let timer = setTimeout(() => {
            setQuery(filters.searchQuery);
        }, 300); 
        return () => clearTimeout(timer);
    }, [filters.searchQuery])

    useEffect(() => { 
        if(pagination.totalPages < pagination.currentPage){ 
            dispatch(setObseravationsCurrentPage(1));
        }
    },[pagination.totalPages]); 

    useEffect(() => { 
        // setObservations([]); 
        fetchAllObservations(filters.searchQuery, filters.status, filters.priority, sortConfig); 
    }, [pagination.currentPage, pagination.perPage, sortConfig, query, filters.status, filters.priority]);

    useEffect(() => {   
        fetchAllStatuses(); 
    }, []);

    // useEffect(() => {
    //     const timer = setTimeout(() => {
    //         fetchAllObservations(filters.searchQuery, filters.status, filters.priority, sortConfig);
    //     }, 500);

    //     return () => clearTimeout(timer);
    // }, [filters.searchQuery, filters.status, filters.priority, sortConfig]);

    useEffect(() => {
        fetchStats();
    }, [dashboardData.value]);

    // fetching all observations here 
    async function fetchAllObservations(searchQuery, status, priority) {
        setLoading(true); 
        try {     
            const response = await list_all_observations({
                perPage: pagination.perPage, 
                pageNumber: pagination.currentPage, 
                searchQuery, 
                status,
                priority,
                sortField: sortConfig.field,
                sortDirection: sortConfig.direction, 
                application: dashboardData.value != 11 ? dashboardData.value : ''
            });
 
            if(response.data.code === 200) {
                setObservations(response.data.data?.observations || []);
                dispatch(setObservationsTotalPages(Number(response.data.data?.totalPages)));
                dispatch(setObservationsTotalEntries(Number(response.data.data?.totalEntries)));
                setError("");
            } else { 
                setError(response.data?.message || "Something went wrong");
            }   
        } catch(e) { 
            setError("Something went wrong while fetching observations");
        }finally{ 
            setLoading(false);
        }
    }

    // Updated fetchAllStatuses with error handling
    async function fetchAllStatuses() {
        try {
            const response = await fetch_all_observation_statuses({role: null});
            if (response.data.code === 200) {
                setStatusList(response.data?.data?.statuses || []);
                setError("");
            } else {
                setError(response.data?.message || "Something went wrong"); 
            }
        } catch (e) {
            setError("Something went wrong while fetching status list");
        }
    }

    async function fetchStats() {
        try {
            const response = await fetch_observation_stats({
                application: dashboardData.value != 11 ? dashboardData.value : ''
            });

            if (response.data.code === 200) {
                setStats({...stats,
                    total: response.data.data?.stats?.total || 0,
                    open: response.data.data?.stats?.open || 0,
                    closed: response.data.data?.stats?.closed || 0,
                    graphs: {
                        statusDistribution: {
                            PendingReview: response.data.data?.stats?.statusDistribution?.PendingReview || 0,
                            Closed: response.data.data?.stats?.statusDistribution?.Closed || 0,
                            Reopened: response.data.data?.stats?.statusDistribution?.Reopened || 0,
                            InProgress: response.data.data?.stats?.statusDistribution?.InProgress || 0,
                            Rejected: response.data.data?.stats?.statusDistribution?.Rejected || 0,
                            NeedMoreInformation: response.data.data?.stats?.statusDistribution?.NeedMoreInformation || 0,
                            PendingAcceptance: response.data.data?.stats?.statusDistribution?.PendingAcceptance || 0,
                            NotanIssue: response.data.data?.stats?.statusDistribution?.NotanIssue || 0,
                            Duplicate: response.data.data?.stats?.statusDistribution?.Duplicate || 0,
                            OutOfScope: response.data.data?.stats?.statusDistribution?.OutOfScope || 0, 
                            BupaDependancy: response.data.data?.stats?.statusDistribution?.BupaDependancy || 0,
                            DependancyResolved: response.data.data?.stats?.statusDistribution?.DependancyResolved || 0
                        },
                        priorityDistribution: {
                            blocker: response.data.data?.stats?.priorityDistribution?.blocker || 0,
                            major: response.data.data?.stats?.priorityDistribution?.major || 0,
                            minor: response.data.data?.stats?.priorityDistribution?.minor || 0,
                            good_to_have: response.data.data?.stats?.priorityDistribution?.good_to_have || 0
                        }, 
                        typeDistribution: {
                            newRequirement: response.data.data?.stats?.typeDistribution?.newRequirement || 0,
                            suggestion: response.data.data?.stats?.typeDistribution?.suggestion || 0,
                            issue: response.data.data?.stats?.typeDistribution?.issue || 0,
                            enhancement: response.data.data?.stats?.typeDistribution?.enhancementRequest || 0
                        }
                    }
                });
            }
        } catch (e) {
            console.error("Error fetching observation stats:", e);
        }
    }

    const handleFilterChange = (e) => {
        const { name, value } = e.target;

        dispatch(setFilter({...filters, [name]: value}));
    };

    const handleClearFilters = () => {
        dispatch(setFilter({searchQuery: '', status: '', priority: ''}));

        dispatch(setSortFilterDirection(null)); 
        dispatch(setSortFilterField(null));
        // fetchAllObservations('', '', '');
    };

    const exportObservations = async (e) => {
        try {
            const response = await export_observations_data({
                searchQuery: filters.searchQuery,
                status: filters.status,
                priority: filters.priority, 
                application: dashboardData.value != 11 ? dashboardData.value : ''
            });

            let downloadUrl = response.data.downloadLink;

            if (downloadUrl) {
                const link = document.createElement('a');
                const filename = `${new Date().toISOString().split('T')[0]} _observations.xlsx`;
                link.href = downloadUrl;
                link.setAttribute('download', filename); 
                document.body.appendChild(link);
                link.click();
                // Clean up and remove the link
                link.parentNode.removeChild(link);
            } else {
                console.error('Download URL not found in response');
            }
        } catch (e) {
            console.log(e);
            setError("Something went wrong while exporting observations");
        }
    };

    // Add handler for sorting
    const handleSort = (field) => {
        dispatch(setSortFilterDirection(field === sortConfig.field && sortConfig.direction === 'asc' ? 'desc' : 'asc')); 
        dispatch(setSortFilterField(field));
    };

    const renderPP4Tooltip = (props) => (
        <Tooltip className="custom-tooltip" {...props}>
            Number of members currently in the pathway
        </Tooltip>
    );

    const renderPP6Tooltip = (props) => (
        <Tooltip className="custom-tooltip" {...props}>
            Number of members de-enrolled from the pathway
        </Tooltip>
    );

    const renderPP7Tooltip = (props) => (
        <Tooltip className="custom-tooltip" {...props}>
            Number of observations by Status
        </Tooltip>
    );
    const renderPP8Tooltip = (props) => (
        <Tooltip className="custom-tooltip" {...props}>
            Number of observations by Priority
        </Tooltip>
    );
    const renderPP9Tooltip = (props) => (
        <Tooltip className="custom-tooltip" {...props}>
            Number of observations by type
        </Tooltip>
    );
    
  return (
    <div className='container-fluid observation-dashboard-page'>
      <div className="row align-items-center pb-3 sticky-top-bar">
          <div className="col-md"> 
              <h4 className="mb-0 content-main-title">Observations</h4>
          </div>
          <div className="col-md">
              <div className="row g-3 align-items-center">
              </div>
          </div>

          {role && role != 'goqii' && (
          <div className="col-md text-end">
              <Link to={`${add}`} className="btn btn-primary add-new-program-btn">
                  <i className="bi bi-person-plus-fill me-2"></i> Add New Observation
              </Link>
              </div>
          )}
        </div>

          
        <PageDescription image={"https://storage.googleapis.com/ksabupatest/2024/11/21/l4qs7/32llgolhd4.jpg"} text={descriptionText[role]} />

<div className="member-engagement mb-3">
        <div className="row text-center px-2">
                       
           <div className="col-3 px-1" >
                <div className={`card bg-grey`} style={{ borderRadius: '14px', border: 'none', height: "100%" }}>
                    <div className="card-body">
                        <div className="row">
                            <div className="col">
                                <h1 className="card-title1">{stats.total?.toLocaleString()}</h1>
                                <h5 className="card-text1">Total Observations
                                    <OverlayTrigger placement="top" overlay={<Tooltip className="custom-tooltip">Total Number of Observations</Tooltip>}>
                                        <i className='bx bx-info-circle ms-1'></i>
                                    </OverlayTrigger>
                                </h5>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="col-3 px-1" >
                <div className={`card bg-grey`} style={{ borderRadius: '14px', border: 'none', height: "100%" }}>
                    <div className="card-body">
                        <div className="row">
                            <div className="col">
                                <h1 className="card-title1">{stats.closed?.toLocaleString()}</h1>
                                <h5 className="card-text1">Closed / Rejected
                                    <OverlayTrigger placement="top" overlay={<Tooltip className="custom-tooltip">Number of closed / Rejected     observations</Tooltip>}>
                                        <i className='bx bx-info-circle ms-1'></i>
                                    </OverlayTrigger>
                                </h5>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="col-6 px-1" >
                    <div className={`card bg-grey`} style={{ borderRadius: '14px', border: 'none', height:"100%" }}>
                        <div className="card-body p-3">
                        <div className="row">
                            <div className="col-4 border-end">
                                <div className="row">
                                    <div className="col">
                                        <h1 className="card-title1">{(stats?.open?.Moderator) ? stats?.open?.Moderator?.toLocaleString(): 0}</h1>
                                        <h5 className="card-text1">Open With Moderator
                                        <OverlayTrigger 
                                                placement="top"
                                                overlay={<Tooltip className="custom-tooltip">Observations currently assigned to the moderator for review.</Tooltip>}
                                            >
                                                <i className='bx bx-info-circle ms-1'></i>
                                            </OverlayTrigger>
                                        </h5>
                                    </div>
                                </div>
                            </div>

                            <div className="col-4 border-end">
                                <div className="row">
                                    <div className="col">
                                        <h1 className="card-title1">{(stats?.open?.User) ? stats?.open?.User?.toLocaleString(): 0}</h1>
                                        <h5 className="card-text1">Open With Business
                                        <OverlayTrigger 
                                                placement="top"
                                                overlay={<Tooltip className="custom-tooltip">Observations requiring input or action from the business team.</Tooltip>}
                                            >
                                                <i className='bx bx-info-circle ms-1'></i>
                                            </OverlayTrigger>
                                        </h5>
                                    </div>
                                </div>
                            </div>

                            <div className="col-4">
                                <div className="row">
                                    <div className="col">
                                        <h1 className="card-title1">{(stats?.open?.GOQii) ?  stats?.open?.GOQii?.toLocaleString(): 0 }</h1>
                                        <h5 className="card-text1">Open With GOQii
                                        <OverlayTrigger 
                                                placement="top"
                                                overlay={<Tooltip className="custom-tooltip">Observations under the GOQii's queue for resolution.</Tooltip>}
                                            >
                                                <i className='bx bx-info-circle ms-1'></i>
                                            </OverlayTrigger>
                                        </h5>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </div>

        <div className='row mb-3'>
            <div className="col-md-4 mb-3">
                <div className="dashboard-graph-box mb-3">
                    <h1 className='dashboard-graph-title '>
                        <span>Status 
                        <OverlayTrigger placement="top" overlay={<Tooltip className="custom-tooltip">Number of observations by status</Tooltip>}>
                            <i className='bx bx-info-circle ms-1'></i>
                        </OverlayTrigger>
                        </span> 
                        {/* <span className="dashboard-graph-number">{navigatorStatsNew.serviceComplete}</span> */}
                    </h1>

                    <StatusDistribution data={stats.graphs.statusDistribution} />
                    
                </div>
            </div>
            <div className="col-md-4 mb-3">
                <div className="dashboard-graph-box mb-3">
                    <h1 className='dashboard-graph-title '>
                        <span>Priority 
                        <OverlayTrigger placement="top" overlay={<Tooltip className="custom-tooltip">Number of observations by priority</Tooltip>}>
                            <i className='bx bx-info-circle ms-1'></i>
                        </OverlayTrigger>
                        </span> 
                        {/* <span className="dashboard-graph-number">{navigatorStatsNew.serviceComplete}</span> */}
                    </h1>

                    <PriorityDistribution data={stats.graphs.priorityDistribution} />

                </div>
            </div>

            <div className="col-md-4 mb-3">
                    <div className="dashboard-graph-box mb-3">
                        <h1 className='dashboard-graph-title '>
                            <span>Type 
                            <OverlayTrigger placement="top" overlay={<Tooltip className="custom-tooltip">Number of observations by type</Tooltip>}>
                                <i className='bx bx-info-circle ms-1'></i>
                            </OverlayTrigger>
                            </span> 
                            {/* <span className="dashboard-graph-number">{navigatorStatsNew.serviceComplete}</span> */}
                        </h1>

                        <TypeDistribution data={stats.graphs.typeDistribution} />
                        
                    </div>
             </div>
        </div>


        

        {/* --------------------- Main Content -------------------- */}
        <div className='comntent-UserManagement'>
            <div className='container-fluid'>
                    
                    <div className='row mb-3'>
                        
                        <div className='col-md-4 px-0'>
                            <div className="form-group">
                                <input
                                    type="text"
                                    name="searchQuery"
                                    value={filters.searchQuery}
                                    onChange={handleFilterChange}
                                    placeholder="Search By Observation ID"
                                    className="form-control input-field-ui ps-5 w-100"
                                />
                                <img src="https://storage.googleapis.com/ksabupatest/2024/09/24/ljce8/fac3csq13o.png" className="input-icon-seacrh" alt="" />
                            </div>
                        </div>
                        <div className='col-md-3 px-1'>
                            <select
                                name="status"
                                value={filters.status}
                                onChange={handleFilterChange}
                                className='form-select input-field-ui w-100'
                            >
                                <option value="">--Select Status--</option>
                                {statusList.map((status, index) => (
                                    <option key={index} value={status.status}>{status.status}</option>
                                ))}
                            </select>
                        </div>
                        <div className='col-md-3 px-1'>
                            <select
                                name="priority"
                                value={filters.priority}
                                onChange={handleFilterChange}
                                className='form-select input-field-ui w-100'
                            >
                                <option value="">--Select Priority--</option>
                                <option value="blocker">Blocker</option>
                                <option value="major">Major</option>
                                <option value="minor">Minor</option>
                                <option value="good_to_have">Good to have</option>
                            </select>
                        </div>
                        <div className='col-md-1 px-1'>
                            <button 
                                className='btn btn-primary btn-primary clear-search-btn w-100'
                                onClick={handleClearFilters}
                            >
                                Clear
                            </button>
                        </div>

                        <div className='col-md-1 px-0'>
                            <button 
                                className='btn btn-primary btn-primary clear-search-btn w-100'
                                onClick={e => exportObservations(e)}
                            >
                                Export
                            </button>
                        </div>
                </div>
            </div>

            <Table
                data={observations} 
                setError={setError}
                sortConfig={sortConfig}
                onSort={handleSort}
                view={view} 
                dashboardData={dashboardData}
                isLoading={loading}
            />
        </div>

    </div>
  )
}
    
export default AllObservations; 
