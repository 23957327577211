import React, { useState, useEffect } from 'react';
import DataTable from 'react-data-table-component';
import DatePicker from "react-datepicker";
import { Link } from 'react-router-dom';
import moment from "moment";
import "react-datepicker/dist/react-datepicker.css";
import CalendarDatePicker from '../../pages/componenents/CalendarDatePicker';
import { generateReport, fetchReports, getFileLink } from '../../services/hih/Service';
import { LoaderLoader, formatDate } from '../../pages/utils/CommonLibrary';
import { decryptData } from '../../pages/utils/Utils';
import { toast } from "react-toastify";
import { NotificationToast } from '../componenents/ContentCareNavigator/NotificationToast';
import BackendDrivenDataTable from '../../pages/cn/components/common/BackendDrivenDataTable';

const HIHReports = () => {
    const storedDates = localStorage.getItem('selectedDates') ? JSON.parse(decryptData(localStorage.getItem('selectedDates'))) : '';

    const [startDate, setStartDate] = useState(
        storedDates && storedDates.length === 4 ? moment(storedDates[0]) : moment()
    );
    const [endDate, setEndDate] = useState(
        storedDates && storedDates.length === 4 ? moment(storedDates[1]) : moment()
    );

    const [searchQuery, setSearchQuery] = useState('');
    const [selectedStatus, setSelectedStatus] = useState('All');
    const [selectedReport, setSelectedReport] = useState('All');
    const [dateRange, setDateRange] = useState([null, null]);
    //const [startDate, endDate] = dateRange;
    const [reportData, setReportData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [errorMsg, setErrorMsg] = useState('');
    const [error, setError] = useState('');
    const [message, setMessage] = useState('');

    const [filterText, setFilterText] = useState('');
    const [totalRows, setTotalRows] = useState(0);
    const [page, setPage] = useState(1);
    const [perPage, setPerPage] = useState(10);
    const [sortColumn, setSortColumn] = useState("createdTime");
    const [sortDirection, setSortDirection] = useState('desc');
    const [download, setDownload] = useState('N');

    const today = new Date();
    const sevenDaysAgo = new Date();
    // sevenDaysAgo.setDate(today.getDate() - 7);

    const handleDateChange = ({ start, end }) => {
        setStartDate(start);
        setEndDate(end);
    };


    useEffect(() => {

        fetchCareCoordinatorData();

    }, [page, perPage, sortColumn, sortDirection, download]);

    const downloadLink = async (reportId) => {
        try {
            setIsLoading(true);
            let resp = await getFileLink(reportId);
            let downloadUrl = resp.data.data.url;
            if (downloadUrl) {
                const link = document.createElement('a');
                const filename = `report_${reportId}.xls`;
                link.href = downloadUrl;
                link.setAttribute('download', filename); // Specify the filename here if necessary
                document.body.appendChild(link);
                link.click();
                // console.log(link);
                // Clean up and remove the link
                link.parentNode.removeChild(link);
            } else {
                console.error('Download URL not found in response');
            }
        } catch (error) {
            console.error(error);
        }
        finally {
            setIsLoading(false);  // Will be called regardless of success or failure
        }
    }

    const columns = [
        {
            name: 'Generated By',
            selector: row => row.creatorName,
            sortable: true,
        },
        {
            name: 'Report Type',
            selector: row => row.reportType,
            sortable: true,
        },

        {
            name: 'Start Date',
            selector: row => row.startDate,
            sortable: true,
        },
        {
            name: 'End Date',
            selector: row => row.endDate,
            sortable: true,
        },
        {
            name: 'Generated On',
            selector: row => row.createdTime,
            sortable: true,
        },
        {
            name: 'Download Reports',
            cell: row => (
                row.status == "Completed" ?
                    <div>
                        <span onClick={() => downloadLink(row.reportId)} style={{ cursor: 'pointer' }}> <i className="bi bi-download"></i></span>
                    </div> :
                    <span>{row.status}</span>
            ),
        },
    ];


    const fetchCareCoordinatorData = async () => {
        try {
            setIsLoading(true);
            let response = await fetchReports(page, perPage, sortColumn, sortDirection);
            if (response.data.code === 200) {
                setReportData(response.data.data.records);
                setTotalRows(response.data.data.totalRows);
            }
        } catch (error) {
            console.error(error);
        }
        finally {
            setIsLoading(false);  // Will be called regardless of success or failure
        }
    }
    const data = reportData;


    const customStyles = {
        headCells: {
            style: {
                backgroundColor: '#D4E6EF',
                fontWeight: '500',
                fontSize: '14px',
                whiteSpace: 'normal',
            },
        },
        rows: {
            style: {
                whiteSpace: 'normal',
            },
        },
        cells: {
            style: {
                whiteSpace: 'normal',
            },
        },
    };
    /*const filterData = data.filter(user => {
 
        const matchesSearchQuery =
            (typeof user.generatedBy === 'string' && user.generatedBy.toLowerCase().includes(searchQuery.toLowerCase()));
 
        return matchesSearchQuery;
    });*/

    const genrateReport = async () => {
        setErrorMsg('');
        if (selectedReport == 'All') {
            setErrorMsg('Select Report type');
            return false;
        }
        try {
            setIsLoading(true); // show loader
            let sdate = formatDate(startDate);
            let edate = formatDate(endDate);
            let response = await generateReport(sdate, edate, selectedReport);
            console.log('newresponse', response.data);
            if (response.data.code === 200) {
                if (response.data.data.status == 1) {
                    fetchCareCoordinatorData();
                    //setMessage(response.data.data.msg);
                    //setTimeout(() => setMessage(false), 3000);
                    toast.success(response.data.data.msg, {
                        position: 'top-right'
                    })
                } else {
                    //setError(response.data.data.msg);
                    //setTimeout(() => setError(false), 3000);
                    toast.error(response.data.data.msg, {
                        position: 'top-right'
                    })
                }

            }

        } catch (error) {
            console.error(error);
        }
        finally {
            setIsLoading(false);  // Will be called regardless of success or failure
        }
    }

    return (
        <>
            <NotificationToast />
            <section className='CareNavigatorReports'>
                <div className="row align-items-center pb-3 sticky-top-bar">
                    <div className="col-md-12">
                        <h1 className="main-title">Reports</h1>
                    </div>
                </div>
                <div className='reports-programs mb-3'>
                    <div className="row align-items-center">
                        <div className="col-md-auto">
                            <img src="https://storage.googleapis.com/ksabupatest/2024/09/24/daz4c/g4h2h47hv2.png" alt="Invited Members icon" style={{ height: "140px" }} className="img-fluid mb-0" />
                        </div>
                        <div className="col-md">
                            <p className="mb-0">The system provides robust reporting capabilities, allowing the extraction of raw data and reports in XLS format.</p>
                        </div>
                    </div>
                </div>

                <div className="container-fluid">
                    <div className="row align-items-center pb-3">
                        <div className='comntent-CareNavigator mb-3'>
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="table-responsive ContentCNReportsTable">
                                        <div className='container-fluid'>
                                            <div className='row mb-3'>
                                                <div className='col-md-4 p-2' style={{ backgroundColor: "#f0f4ff", borderRadius: "14px  0 0 14px" }}>
                                                    <select
                                                        value={selectedReport}
                                                        onChange={e => setSelectedReport(e.target.value)}
                                                        className='form-select input-field-ui'
                                                    >
                                                        <option value="">--Select Report:--</option>
                                                        <option value="aibuparecommendationreport">AI BUPA Recommendation Report</option>

                                                    </select>
                                                    {errorMsg && <div className="text-danger">{errorMsg}</div>}
                                                </div>
                                                <div className='col-md-3  py-2 px-0' style={{ backgroundColor: "#f0f4ff", borderRadius: "0px" }}>
                                                    <CalendarDatePicker startDate={startDate} endDate={endDate} onDateChange={handleDateChange} updateDates={false} />
                                                </div>
                                                <div className='col-md-auto p-2' style={{ backgroundColor: "#f0f4ff", borderRadius: "0 14px 14px 0" }}>
                                                    <button type='button' className='btn btn-primary add-new-program-btn' onClick={genrateReport}>Generate
                                                    </button>
                                                </div>
                                                <div className={`${error != "" ? "errorDiv" : "hide"}`} style={{ fontWeight: "400 !important" }}>{error}</div>
                                                <div className={`${message != "" ? "messageDiv" : "hide"}`} style={{ fontWeight: "400 !important" }}>{message}</div>
                                            </div>
                                        </div>
                                        {
                                            isLoading ? <LoaderLoader isLoading={isLoading} /> :
                                                <BackendDrivenDataTable
                                                    data={data}
                                                    loading={isLoading}
                                                    totalRows={totalRows}
                                                    page={page}
                                                    perPage={perPage}
                                                    sortColumn={sortColumn}
                                                    sortDirection={sortDirection}
                                                    filterText={filterText}
                                                    columns={columns}
                                                    fetchTableData={fetchCareCoordinatorData}
                                                    setPage={setPage}
                                                    setSortColumn={setSortColumn}
                                                    setSortDirection={setSortDirection}
                                                    setPerPage={setPerPage}
                                                />
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default HIHReports