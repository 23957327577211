import React from 'react';
import { Link } from 'react-router-dom';
import { downloadFile } from '../../services/hih/Service';

function DataUploadActionDropDown({ originalFileLink, successFileLink, failedFileLink }) {

    const downloadFileApi = async (fileUrl) => {
        downloadFile(fileUrl)
            .then((response) => {
                if (response.data.code == 200) {
                    window.location.href = response.data.data.fileUrl;
                }
            }
            );
    }

    return (
        <>
            <div className='DropDownAction'>
                <div className="btn-group dropend">
                    <button type="button" className="btn btn-light dropdown-toggle action-dropdown-btn" data-bs-toggle="dropdown" aria-expanded="false">
                        <i className="bi bi-download"></i>
                    </button>
                    <ul className="dropdown-menu">
                        {originalFileLink != "" && <li className='list-class'><Link className="dropdown-item" onClick={(e) => downloadFileApi(originalFileLink)}>All</Link></li>}
                        {successFileLink != "" && <li className='list-class'><Link className="dropdown-item" onClick={(e) => downloadFileApi(successFileLink)}>Successfully imported records</Link></li>}
                        {failedFileLink != "" && <li className='list-class'><Link className="dropdown-item" onClick={(e) => downloadFileApi(failedFileLink)}>Failed Records</Link></li>}
                    </ul>
                </div>
            </div>
        </>
    );
}

export default DataUploadActionDropDown