import common_axios from "./commonAxios";

let baseURL = common_axios.defaults.baseURL;


export const fetch_role_data = () => {
    var dataPass = common_axios.get( baseURL + 'whatsapp/fetch_role_data');
    return dataPass;
};

export const fetch_admin_user_data = () => {
    var dataPass = common_axios.get( baseURL + 'whatsapp/fetch_admin_user_data');
    return dataPass;
};

export const whatsapp_fetch_admin_user_data = (userId) => {
    var dataPass = common_axios.get( baseURL + 'whatsapp/whatsapp_fetch_admin_user_data?userId='+userId);
    return dataPass;
};



export const fetch_ad_users_list = (searchString) => {
    var requestBody={searchString};
    var dataPass = common_axios.post( baseURL + 'whatsapp/fetch_ad_users',requestBody);
    return dataPass;
};

export const fetch_program_data = () => {
    console.log("fetch_program_data",baseURL);
    var dataPass = common_axios.get( baseURL + 'whatsapp/fetch_program_data');
    return dataPass;
};

export const user_creation_data = (formData) => {
    var dataPass = common_axios.post( baseURL + 'whatsapp/user_creation_data',formData);
    return dataPass;
};

export const admin_user_update_data = (userId,formData) => {
    var dataPass = common_axios.post( baseURL + 'whatsapp/admin_user_update_data?userId='+userId,formData);
    return dataPass;
};


// fetch admin userdata 
export const member_list = (params) => {
    var dataPass = common_axios.post(baseURL + 'whatsapp/member_list', params)
    return dataPass;
};

export const member_details = (params) => {
    var dataPass = common_axios.post(baseURL + 'whatsapp/member_details', params)
    return dataPass;
};
export const send_message = (params) => {
    var dataPass = common_axios.post(baseURL + 'whatsapp/send_message', params)
    return dataPass;
};
export const fetch_chat = (params) => {
    var dataPass = common_axios.post(baseURL + 'whatsapp/fetch_chat', params)
    return dataPass;
};

export const update_status = (params) => {
    var dataPass = common_axios.post(baseURL + 'whatsapp/update_status', params)
    return dataPass;
};

export const whatsapp_list = (params) => {
    var dataPass = common_axios.post(baseURL + 'whatsapp/whatsapp_list', params)
    return dataPass;
};
export const sendTemplateApi = (params) => {
    var dataPass = common_axios.post(baseURL + 'whatsapp/whatsapp_send', params)
    return dataPass;
};

export const check_status = (params) => {
    var dataPass = common_axios.post(baseURL + 'whatsapp/check_status', params)
    return dataPass;
};

export const fetchCampaignCreatedList = () => {
    var dataPass = common_axios.get( baseURL + 'mtp/fetch_campaign_created_list');
    return dataPass;
};

export const fetchCampgainDetail = (campaignId) => {
    let params = {'campaignId':campaignId}
    var dataPass = common_axios.post( baseURL + 'mtp/fetch_campgain_detail',params);
    return dataPass;
};

export const fetchCampgainDetailStatistics = (campaignId,status) => {
    let params = {'campaignId':campaignId,'status':status}
    var dataPass = common_axios.post( baseURL + 'mtp/fetch_campgain_detail_statistics',params);
    return dataPass;
};

export const fetchFilterMemberCampgain = (queries) => {
    const params = { 'filterCondition': queries }
    var dataPass = common_axios.post( baseURL + 'mtp/fetch_filter_member_campgain',params);
    return dataPass;
};

export const whatsappCampaignList = () => {
    var dataPass = common_axios.get( baseURL + 'mtp/whatsapp_campaign_list');
    return dataPass;
};

export const whatsAppCreateCampaign = (formData) => {
    var dataPass = common_axios.post( baseURL + 'mtp/whatsapp_campaign_creation',formData);
    return dataPass;
};

export const fetch_tag_list = () => {
    var dataPass = common_axios.get( baseURL + 'whatsapp/fetch_tag_list');
    return dataPass;
};

export const whatsappCampaignCompletedDataDownload = (campaignId) => {
    const params = { 'campaignId': campaignId }
    var dataPass = common_axios.post( baseURL + 'whatsapp/whatsapp_campaign_completed_data_download',params, { responseType: 'blob', headers: {
        'Content-Type': 'application/json',
        'Accept': 'text/csv'
    } });
    return dataPass;
};

export const filterUserDataDownload = (filterQuery,userStatus,totalCountUserList) => {
    const params = { 'filterQuery': filterQuery,'userStatus':userStatus,'totalCount':totalCountUserList}
    var dataPass = common_axios.post( baseURL + 'enagehub/filter_user_data_download',params, { responseType: 'blob', headers: {
        'Content-Type': 'application/json',
        'Accept': 'text/csv'
    } });
    return dataPass;
};

export const fetch_count = (params) => {
    var dataPass = common_axios.post(baseURL + 'chatanalytics/fetch_count', params)
    return dataPass;
}; 

export const fetch_carenavigator = (params) => {
    var dataPass = common_axios.post(baseURL + 'chatanalytics/fetch_carenavigator', params)
    return dataPass;
}; 

export const fetch_admin_insights = (params) => {
    var dataPass = common_axios.post(baseURL + 'chatanalytics/fetch_admin_insights', params)
    return dataPass;
}; 

export const fetch_admins = (params) => {
    var dataPass = common_axios.post(baseURL + 'chatanalytics/fetch_admins', params)
    return dataPass;
}; 


export const member_list_ur = (params) => {
    var dataPass = common_axios.post(baseURL + 'unregisterchat/member_list', params)
    return dataPass;
};

export const member_details_ur = (params) => {
    var dataPass = common_axios.post(baseURL + 'unregisterchat/member_details', params)
    return dataPass;
};
export const send_message_ur = (params) => {
    var dataPass = common_axios.post(baseURL + 'unregisterchat/send_message', params)
    return dataPass;
};
export const fetch_chat_ur = (params) => {
    var dataPass = common_axios.post(baseURL + 'unregisterchat/fetch_chat', params)
    return dataPass;
};

export const update_status_ur = (params) => {
    var dataPass = common_axios.post(baseURL + 'unregisterchat/update_status', params)
    return dataPass;
};

export const whatsapp_list_ur = (params) => {
    var dataPass = common_axios.post(baseURL + 'unregisterchat/whatsapp_list', params)
    return dataPass;
};
export const sendTemplateApi_ur = (params) => {
    var dataPass = common_axios.post(baseURL + 'unregisterchat/whatsapp_send', params)
    return dataPass;
};

export const check_status_ur = (params) => {
    var dataPass = common_axios.post(baseURL + 'unregisterchat/check_status', params)
    return dataPass;
};

export const filterCarenavigatornameList = () => {
    var dataPass = common_axios.get(baseURL + 'whatsapp/whatsapp_filter_carenavigatornamelist')
    return dataPass;
};

export const fetchDashboardList = () => {
    var dataPass = common_axios.get(baseURL + 'chatanalytics/fetch_dashboard')
    return dataPass;
};

export const fetch_agent_insights = (params) => {
    var dataPass = common_axios.post(baseURL + 'chatanalytics/fetch_agent_insights', params)
    return dataPass;
};

export const fetch_campaign_insights = (params) => {
    var dataPass = common_axios.post(baseURL + 'chatanalytics/fetch_campaign_insights', params)
    return dataPass;
};

export const fetch_unregistered_insights = (params) => {
    var dataPass = common_axios.post(baseURL + 'chatanalytics/fetch_unregistered_insights', params)
    return dataPass;
};

export const resend_message = (params) => {
    var dataPass = common_axios.post(baseURL + 'whatsapp/resend_message', params)
    return dataPass;
};

