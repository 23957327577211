import React, { useState, useEffect } from 'react';
//import './MemberOnboarding.css';
import { useParams } from "react-router-dom";
import { fetchServiceRecommendationHistory } from '../../services/hih/Service';
import { CustomLoader, ModalBoxButton } from '../../pages/utils/CommonLibrary';
import DatePicker from "react-datepicker";
import { useNavigate } from 'react-router-dom';
import BackendDrivenDataTable from '../cn/components/common/BackendDrivenDataTable';
import { MultiSelect } from "react-multi-select-component";

function RecommendationHistory() {

    const { id } = useParams();
    const navigate = useNavigate();
    const [careUserName, setCareUserName] = useState('');
    const [transferFromCNTotalMembers, setTransferFromCNTotalMembers] = useState("");
    const [careData, setCareData] = useState([]);
    const [checked, setChecked] = useState([]);
    const [dropdownValues, setDropdownValues] = useState({});
    const [reasonSelect, setReasonSelect] = useState('All');
    const [typeSelect, setTypeSelect] = useState('All');
    const [reasonText, setReasonText] = useState('');
    const [selectError, setSelectError] = useState('');
    const [careError, setCareError] = useState('');
    const [loading, setLoading] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [modalTitle, setModalTitle] = useState('');
    const [modalClass, setModalClass] = useState('md');
    const [modalBody, setModalBody] = useState('');
    const [showModalButton, setShowModalButton] = useState(false);
    const [showSubmit, setShowSubmit] = useState(false);
    const [reloadKey, setReloadKey] = useState(0);
    const [showHide, setShowHide] = useState(false);
    const [filterText, setFilterText] = useState('');
    const [showBulkTransferModal, setShowBulkTransferModal] = useState(false);
    const [transferToCNList, setTransferToCNList] = useState("");
    const [allChecked, setAllChecked] = useState(false);

    //Changes related to backend driven data table 
    const [totalRows, setTotalRows] = useState(0);
    const [page, setPage] = useState(1);
    const [perPage, setPerPage] = useState(10);
    const [sortColumn, setSortColumn] = useState("createdTime");
    const [sortDirection, setSortDirection] = useState('desc');
    const [download, setDownload] = useState('N');
    const [conditionList, setConditionList] = useState([]);
    const [selectedConditions, setSelectedConditions] = useState([]);
    const [cnList, setCNList] = useState([]);
    const [selectedCN, setSelectedCN] = useState(0);

    useEffect(() => {
        loadUserData();
    }, [page, perPage, sortColumn, sortDirection, filterText]);

    useEffect(() => {
        setAllChecked(false);
        setChecked([])
    }, [careData]);
    //=============================================


    const handleClose = () => {
        setShowModal(false);

        //setLoading(true);
        loadUserData();
        setReloadKey(prevKey => prevKey + 1);
    }

    const getNextWeekRange = () => {
        const end = new Date();
        const start = new Date();
        end.setDate(end.getDate() + 7);
        return [start, end];
    };

    const [dateRange, setDateRange] = useState(getNextWeekRange);
    const [startDate, endDate] = dateRange;
    const [dateStartNew, setDateStartNew] = useState(new Date());

    const [errors, setErrors] = useState({
        conditions: '',
        carenavigator: ''
    });


    useEffect(() => {
        loadUserData();
    }, []);


    const loadUserData = async () => {
        try {
            setCareData([]);
            setLoading(true);
            fetchServiceRecommendationHistory(filterText, page, perPage, sortColumn, sortDirection)
                .then((response) => {
                    if (response.data.code == 200) {
                        setCareData(response.data.data.members);
                        setTotalRows(response.data.data.totalRows)
                    }
                }).finally(() => {
                    setLoading(false);
                });
        } catch (error) {
            console.error('Error=', error);
        }
    }

    // function formatDate(dateString) {
    //     const date = new Date(dateString);
    //     const year = date.getFullYear();
    //     const month = String(date.getMonth() + 1).padStart(2, '0');
    //     const day = String(date.getDate()).padStart(2, '0');
    //     return `${day}/${month}/${year}`;
    // }

    function formatDateYmd(dateString) {
        const date = new Date(dateString);
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    }



    const handleAllChange = (e) => {
        const { checked } = e.target;
        if (checked) {
            setChecked(careData.map(item => item.membershipNo));
            setAllChecked(true);
        } else {
            setChecked([]);
            setAllChecked(false);
        }
    };

    const handleCheckboxChange = (e, membershipNumber) => {
        const { checked } = e.target;

        setChecked(prevChecked =>
            checked
                ? [...prevChecked, membershipNumber]
                : prevChecked.filter(id => id !== membershipNumber)
        );

    };

    const columns = [
        {
            name: 'Member ID',
            selector: row => row.membershipNo,
            sortable: true,
        },
        {
            name: 'Main Membership No',
            selector: row => row.mainMembershipNo,
            sortable: true,
        },
        {
            name: 'Member Name',
            selector: row => row.memberName,
            sortable: false,
        },
        {
            name: 'Mobile',
            selector: row => row.memberMobile,
            sortable: true,
        },
        {
            name: 'Gender',
            selector: row => row.gender,
            sortable: true,
        },
        {
            name: 'Age',
            selector: row => row.age,
            sortable: true,
        },
        {
            name: 'VIP Code',
            selector: row => row.vipCode,
            sortable: true,
        },
        {
            name: 'Ambassador',
            selector: row => row.ambassadorInd,
            sortable: true,
        },
        {
            name: 'ID Card No',
            selector: row => row.idCardNo,
            sortable: true,
        },
        {
            name: 'Net Type',
            selector: row => row.netType,
            sortable: true,
        },
        {
            name: 'Contract No',
            selector: row => row.contractNo,
            sortable: true,
        },
        {
            name: 'Customer Name',
            selector: row => row.customerName,
            sortable: false,
        },
        {
            name: 'Contract Term Date',
            selector: row => row.contractTermDate,
            sortable: true,
        },
        {
            name: 'Service Supply Date',
            selector: row => row.serviceSupplyTo,
            sortable: true,
        },
        {
            name: 'Service Code',
            selector: row => row.serviceCode,
            sortable: true,
        },
        {
            name: 'Service Description',
            selector: row => row.serviceDesc,
            sortable: false,
        },
        {
            name: 'Quantity',
            selector: row => row.servicelineItemQuantity,
            sortable: true,
        },
        {
            name: 'Dispensed Provider',
            selector: row => row.dispensedProvider,
            sortable: false,
        },
        {
            name: 'Dispensed Provider City',
            selector: row => row.dispensedProviderCity,
            sortable: false,
        },
        {
            name: 'Chronic Conditions',
            selector: row => row.chronicConditions,
            sortable: false,
        },
        {
            name: 'Generated Date',
            selector: row => row.generatedDate,
            sortable: true,
        },
        {
            name: 'Member Type',
            selector: row => row.memberType,
            sortable: true,
        },
        {
            name: 'Current Status',
            selector: row => row.currentStatus,
            sortable: true,
        },
        {
            name: 'CN Action Date',
            selector: row => row.actionTime,
            sortable: true,
        },
        {
            name: 'CN Dismissal Comments',
            selector: row => row.dismissalComments,
            sortable: false,
        },
        {
            name: 'Created Date',
            selector: row => row.createdTime,
            sortable: true,
        }
    ];

    const handleConditions = (selectedOptions) => {
        //const selectedValues = selectedOptions.map((option) => option.value);
        setSelectedConditions(selectedOptions);
    };
    return (
        <>

            <section className='ContentMemberTransfer'>
                <div className="row align-items-center pb-3 sticky-top-bar">
                    <div className="col-md-12">
                        <h1 className="main-title mb-0 ">Service Recommendation History</h1>
                    </div>
                </div>
                <div className='reports-programs mb-3'>
                    <div className="row align-items-center">
                        <div className="col-md-auto">
                            <img src="https://storage.googleapis.com/ksabupatest/2024/09/24/daz4c/g4h2h47hv2.png" alt="Invited Members icon" style={{ height: "140px" }} className="img-fluid mb-0" />
                        </div>
                        <div className="col-md">
                            <p className="mb-0">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining.</p>
                        </div>
                    </div>
                </div>
                <div className="row align-items-center pb-3 sticky-top-bar">
                    <div className="col-md" style={{
                        display: 'flex',
                        alignItems: 'center'
                    }}>
                    </div>
                </div>
                <div className="container-fluid">
                    <div className="row align-items-center pb-3">
                        <div className='comntent-MemberTransfer mb-3 p-3'>
                            <div className="row">
                                <div className="col-md-4 mb-3">
                                    <div className="row">
                                        <div className="col">
                                            <div className="form-group" style={{ width: "100%" }}>
                                                <input
                                                    type="text"
                                                    placeholder="Search by Member ID"
                                                    value={filterText}
                                                    onChange={e => {
                                                        setPage(1);
                                                        setFilterText(e.target.value);
                                                    }}
                                                    className="form-control input-field-ui ps-5"
                                                />
                                                <img src="https://storage.googleapis.com/ksabupatest/2024/09/24/wjm48/fh6lw97sch.png" className="input-icon-seacrh" alt="" />
                                            </div>
                                        </div>
                                    </div>
                                </div>



                                <div className="col-md-12">
                                    <div className="table-responsive MemberTransferTable">
                                        <div className="text-danger">{selectError}</div>
                                        <div className="text-danger">{careError}</div>
                                        {

                                            <div>
                                                {
                                                    loading ? <CustomLoader /> :
                                                        <BackendDrivenDataTable
                                                            data={careData}
                                                            loading={loading}
                                                            totalRows={totalRows}
                                                            page={page}
                                                            perPage={perPage}
                                                            sortColumn={sortColumn}
                                                            sortDirection={sortDirection}
                                                            columns={columns}
                                                            fetchTableData={loadUserData}
                                                            setPage={setPage}
                                                            setSortColumn={setSortColumn}
                                                            setSortDirection={setSortDirection}
                                                            setPerPage={setPerPage}
                                                        />
                                                }
                                            </div>
                                        }

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </>
    );
}

export default RecommendationHistory