import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Modal } from 'react-bootstrap';
import axios from "axios";
import moment from 'moment';
import DatePicker from 'react-datepicker';
import Line from '../components/graphs/Line';
import Pie from '../components/graphs/Pie';
import { Row, Col } from 'react-bootstrap';
import MetricsFreeTextInsights from '../components/memberservices/MetricsFreeTextInsights';
import { fetchGenericHealthMetrics, addGenericMetricsVals, addMetricsWeight } from '../components/services/Service';
import { LoaderLoader } from '../../utils/CommonLibrary';

const HealthMetrics = (props) => {

    const [searchStr, setSearchStr] = useState("");
    const [loading, setLoading] = useState(false);
    const [updateError, setUpdateError] = useState("");
    const [updateMessage, setUpdateMessage] = useState("");
    const metricsObj = {
        userId: props.userId,
        metricsId: "",
        metricsName: "",
        metricsVal: "",
        metricsDate: null
    }
    const bmiMetricsObj = {
        userId: props.userId,
        metricsId: "",
        metricsName: "",
        metricsVal: "",
        metricsWeight: "",
        metricsHeight: "",
        metricsObservation: "",
        metricsDate: null
    }
    const [selectedMetrics, setSelectedMetrics] = useState({});
    const [formData, setFormData] = useState(metricsObj);
    const [bmiFormData, setBMIFormData] = useState(bmiMetricsObj);
    const [genericHealthMetricsListAll, setGenericHealthMetricsListAll] = useState([]);
    const [genericHealthMetricsListFiltered, setGenericHealthMetricsListFiltered] = useState([]);
    const [weightUpdateMessage, setWeightUpdateMessage] = useState("");
    const [weightUpdateError, setWeightUpdateError] = useState("");

    /*const [weight, setWeight] = useState("");
    const [height, setHeight] = useState("");
    const [bmi, setBMI] = useState("");
    const [bmiObservation, setBMIObservation] = useState("");*/

    useEffect(() => {
        getGenericHealthMetrics();
    }, [props.userId])

    useEffect(() => {
        if (bmiFormData.metricsWeight !== "" && bmiFormData.metricsHeight !== "") {
            const weight = bmiFormData.metricsWeight;
            const height = bmiFormData.metricsHeight;
            const heightInMeters = height / 100;
            let BMI = (weight / (heightInMeters * heightInMeters));

            BMI = parseFloat(BMI).toFixed(1)
            let observation = (BMI < 18.5) ? "Underweight" : (BMI >= 18.5 && BMI <= 24.9) ? "Healthy" : (BMI >= 25 && BMI <= 29.9) ? "Overweight" : "Obese";

            //setBMI(BMI);
            //setBMIObservation(observation);

            setBMIFormData((prevFormData) => ({
                ...prevFormData,
                "metricsVal": BMI,
                "metricsObservation": observation
            }));
        } else {
            //setBMI("");
            //setBMIObservation("");
            setBMIFormData((prevFormData) => ({
                ...prevFormData,
                "metricsVal": "",
                "metricsObservation": ""
            }));
        }
    }, [bmiFormData.metricsWeight, bmiFormData.metricsHeight])
    //=====================================
    const [expandedRow, setExpandedRow] = useState(null);


    const toggleRowExpansion = (index) => {
        setUpdateError("");
        setUpdateMessage("");
        setExpandedRow((prevState) => (prevState === index ? null : index));
    };

    const handleIconClick = (e, item) => {
        e.stopPropagation(); // Prevent the row expansion from being triggered
        e.preventDefault();  // Prevent the default link navigation behavior
        setSelectedMetrics(item);
        handleShowHealthMetricsDetailsPopUpModal(); // Show the modal
    };

    const [showHealthMetricsDetailsPopUpModal, setShowHealthMetricsDetailsPopUpModal] = useState(false);
    const handleShowHealthMetricsDetailsPopUpModal = () => {
        setShowHealthMetricsDetailsPopUpModal(true);
    }
    //=========================================

    const searchTitle = async (searchTitle) => {
        if (searchTitle === "") {
            setGenericHealthMetricsListFiltered(genericHealthMetricsListAll);
        } else {
            const filteredDivs = genericHealthMetricsListAll.filter((div) =>
                div.metricsName.toLowerCase().includes(searchTitle.toLowerCase())
            );
            setGenericHealthMetricsListFiltered(filteredDivs);
        }
    }



    const getGenericHealthMetrics = async () => {
        const request = axios.CancelToken.source();
        let ct = {
            cancelToken: request.token
        }
        await fetchGenericHealthMetrics(props.userId, ct)
            .then((response) => {
                if (response.data.code === 200) {
                    setGenericHealthMetricsListFiltered(response.data.data.metrics);
                    setGenericHealthMetricsListAll(response.data.data.metrics);
                }
            }).catch(function (error) {
                console.log(error);
            });
        return () => request.cancel();
    }

    const handleMetricsValChange = (name, newValue, inputType) => {
        setUpdateError("");
        setUpdateMessage("");

        if (inputType === "numeric" && !/^\d*\.?\d*$/.test(newValue)) {
            return;  // Prevent invalid value from updating the state
        }

        setFormData((prevFormData) => ({
            ...prevFormData,
            [name]: newValue, // Dynamically update the specific property
        }));

    };

    const handleBMIMetricsValChange = (name, newValue, inputType) => {
        setWeightUpdateError("");
        setWeightUpdateMessage("");

        if (inputType === "numeric" && !/^\d*\.?\d*$/.test(newValue)) {
            return;  // Prevent invalid value from updating the state
        }

        setBMIFormData((prevFormData) => ({
            ...prevFormData,
            [name]: newValue, // Dynamically update the specific property
        }));

    };

    const handleUpdateForm = async () => {
        setUpdateError("");
        setUpdateMessage("");
        let valFound = 0;
        let errorFound = 0;

        const item = formData;

        // Check if metricsVal exists and is not an empty string
        const metricsValExists = (item.metricsVal?.trim() || "") !== "";
        const metricsDateExists = item.metricsDate && item.metricsDate !== "";

        if (metricsValExists) {
            valFound++;
        }

        // Validate metricsVal and metricsDate
        if (metricsValExists && !metricsDateExists) {
            setUpdateError("Please enter date for " + item.metricsName);
            errorFound++;
        } else if (!metricsValExists && metricsDateExists) {
            setUpdateError("Please enter value for " + item.metricsName);
            errorFound++;
        }

        if (valFound === 0 && errorFound == 0) {
            setUpdateError("Please enter the details first.");
            errorFound++;
        }

        if (errorFound === 0) {
            setLoading(true);
            let fd = structuredClone(formData);
            if (fd.metricsDate) {
                if ((fd.metricsDate) != "") {
                    fd.metricsDate = (moment(fd.metricsDate).isValid()) ? moment(fd.metricsDate).format('YYYY-MM-DD') : "";
                }
            }

            const request = axios.CancelToken.source();
            let ct = {
                cancelToken: request.token
            }
            await addGenericMetricsVals(fd, ct)
                .then((response) => {
                    setLoading(false);
                    if (response.data.code === 200) {
                        setUpdateMessage(response.data.data.message);
                        setFormData((prevFormData) => ({
                            ...prevFormData,
                            metricsVal: "",
                            metricsDate: null
                        }));
                        props.getMemberBasicInfo();
                        getGenericHealthMetrics();
                    } else {
                        setUpdateError(response.data.data.message);
                    }
                }).catch(function (error) {
                    console.log(error);
                });
            return () => request.cancel();
        }
    }
    /*const handleAddWeightHeight = (e) => {
        const { name, value } = e.target;
        if (name == "weight" || name == "height") {
            if (!/^\d*\.?\d*$/.test(value)) {
                return false;
            }
            if (name === "weight") {
                setWeight(value);
            }
            if (name == "height") {
                setHeight(value)
            }
        }
    }*/

    const handleAddWeightForm = async () => {
        setWeightUpdateError("");
        setWeightUpdateMessage("");

        if (bmiFormData.metricsWeight === "") {
            setWeightUpdateError("Please enter weight");
        } else if (bmiFormData.metricsHeight === "") {
            setWeightUpdateError("Please enter height");
        } else if (bmiFormData.metricsDate == null || bmiFormData.metricsDate == "") {
            setWeightUpdateError("Please enter date");
        } else {

            let fd = structuredClone(bmiFormData);
            if (fd.metricsDate) {
                if ((fd.metricsDate) != "") {
                    fd.metricsDate = (moment(fd.metricsDate).isValid()) ? moment(fd.metricsDate).format('YYYY-MM-DD') : "";
                }
            }

            const request = axios.CancelToken.source();
            let ct = {
                cancelToken: request.token
            }
            await addMetricsWeight(fd, ct)
                .then((response) => {
                    if (response.data.code === 200) {
                        /*setWeight("");
                        setHeight("");
                        setBMI("");
                        setBMIObservation("");*/
                        setBMIFormData((prevFormData) => ({
                            ...prevFormData,
                            userId: props.userId,
                            metricsId: "",
                            metricsName: "",
                            metricsVal: "",
                            metricsWeight: "",
                            metricsHeight: "",
                            metricsObservation: "",
                            metricsDate: null
                        }));
                        props.getMemberBasicInfo(props.userId);
                        setWeightUpdateMessage(response.data.data.message);
                        props.setRefreshVar(new Date().getTime());
                    } else {
                        setWeightUpdateError(response.data.data.message);
                    }
                }).catch(function (error) {
                    console.log(error);
                });
            return () => request.cancel();
        }
    }

    const preventNonNumeric = (e) => {
        setTimeout(() => {
            e.target.value = e.target.value.replace(/[^0-9.]/g, '')
                .replace(/(\..*)\./g, '$1');
        }, 0);
    };

    return (
        <>
            <LoaderLoader isLoading={loading} />
            <div className='col-md-12 mb-3'>
                <div className='row'>
                    <div className='col-5'>
                        <input type="text" placeholder="Search Health Metrics by Title" className='form-control control-input-ui-addmember' onChange={(e) => searchTitle(e.target.value.trim())} />
                    </div>
                </div>
            </div>

            <div className="" style={{ maxHeight: "60vh", overflowY: "scroll" }}>
                <div className="row">
                    {
                        genericHealthMetricsListFiltered.map((item, index) => {
                            {/* Health metrics Content 1 */ }
                            return <div className="col-md-12 mb-2">
                                <Link
                                    className={`expandable-title-new d-flex tw-justify-between ${expandedRow === index ? 'active' : ''}`}
                                    onClick={(e) => {
                                        if (item.metricsName === "BMI") {
                                            setWeightUpdateError("");
                                            setWeightUpdateMessage("");
                                            setBMIFormData((prevFormData) => ({
                                                ...prevFormData,
                                                metricsId: item.metricsId,
                                                metricsName: item.metricsName,
                                                metricsVal: "",
                                                metricsWeight: "",
                                                metricsHeight: "",
                                                metricsObservation: "",
                                                metricsDate: null,
                                            }));
                                        } else {
                                            setUpdateError("");
                                            setUpdateMessage("");
                                            setFormData((prevFormData) => ({
                                                ...prevFormData,
                                                metricsId: item.metricsId,
                                                metricsName: item.metricsName,
                                                metricsVal: "",
                                                metricsDate: null,
                                            }));
                                        }
                                        toggleRowExpansion(index);
                                    }}
                                >

                                    <div>
                                        {item.metricsName}
                                        {
                                            item.showInsights === 'Y' &&
                                            <i
                                                className="fa fa-bar-chart icons-hover"
                                                aria-hidden="true"
                                                style={{ marginLeft: "18px" }}
                                                onClick={(e) => handleIconClick(e, item)}
                                            />
                                        }
                                    </div>

                                    {expandedRow === index ? (

                                        <i className="bi bi-caret-up-fill" />
                                    ) : (
                                        <i className="bi bi-caret-down-fill" />
                                    )}
                                </Link>

                                {
                                    expandedRow === index && (
                                        (item.metricsName != "BMI") ?
                                            <div className="row">
                                                <div className="col-md-12 mt-3 px-4">
                                                    <div className='row'>
                                                        <div className='col-md-6 mb-3'>
                                                            <label className="form-label">
                                                                Value
                                                                {
                                                                    item.unit !== "" && " (" + item.unit + ")"
                                                                }
                                                            </label>
                                                            {
                                                                item.inputType == "numeric" && item.metricsName !== "BMI" &&
                                                                <input type='text' name={`metricsVal_${index}`} value={formData.metricsVal} className='form-control control-input-ui' placeholder={`Enter Value`} onChange={(e) => handleMetricsValChange("metricsVal", e.target.value, "numeric")} onInput={preventNonNumeric} />
                                                            }
                                                            {
                                                                item.inputType == "freetext" &&
                                                                <textarea name={`metricsVal_${index}`} value={formData.metricsVal} className='form-control control-input-ui' onChange={(e) => handleMetricsValChange("metricsVal", e.target.value, "freetext")}></textarea>
                                                            }
                                                            {
                                                                item.inputType == "select" &&
                                                                <select name={`metricsVal_${index}`} value={formData.metricsVal} className='form-control control-input-ui' onChange={(e) => handleMetricsValChange("metricsVal", e.target.value, "select")}>
                                                                    <option value="">Select</option>
                                                                    {
                                                                        item.possibleValues.map((pval, pindex) => {
                                                                            return <option key={pindex} value={pval.value}>{pval.label}</option>
                                                                        })
                                                                    }
                                                                </select>
                                                            }
                                                        </div>
                                                        <div className='col-md-6 mb-3'>
                                                            <label className="form-label">Date</label>
                                                            <DatePicker
                                                                dateFormat="dd-MM-yyyy"
                                                                className='form-control control-input-ui'
                                                                selected={formData.metricsDate || null} // Ensure null when no date is selected
                                                                placeholderText="Enter Date" // Use placeholderText for DatePicker
                                                                maxDate={new Date()}
                                                                onChange={(date) => handleMetricsValChange("metricsDate", date, "date")}
                                                                onKeyDown={(e) => e.preventDefault()}
                                                                onPaste={(e) => e.preventDefault()}
                                                            />
                                                        </div>
                                                        <div className='col-md-12 mb-3 text-center'>
                                                            <div className={`${updateError != "" ? "errorDiv" : "hide"} `}>{updateError}</div>
                                                            <div className={`${updateMessage != "" ? "messageDiv" : "hide"}`}>{updateMessage}</div>
                                                            <button type="submit" class="btn btn-primary btn-primary program-save-btn me-2" onClick={handleUpdateForm}>Save</button>
                                                            <button type="reset" class="btn clear-btn" onClick={(e) => {
                                                                setFormData((prevFormData) => ({
                                                                    ...prevFormData,
                                                                    metricsId: item.metricsId,
                                                                    metricsName: item.metricsName,
                                                                    metricsVal: "",
                                                                    metricsDate: null
                                                                }));
                                                            }}>Clear</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            :
                                            <div className='row'>
                                                <div className='col-4 mb-3'>
                                                    <label className="form-label">Weight (in kgs):</label>
                                                    <input type="text" value={bmiFormData.metricsWeight} name="metricsWeight" className='form-control control-input-ui' onChange={(e) => handleBMIMetricsValChange("metricsWeight", e.target.value, "numeric")} />
                                                </div>
                                                <div className='col-4 mb-3'>
                                                    <label className="form-label">Height (in cms):</label>
                                                    <input type="text" value={bmiFormData.metricsHeight} name="metricsHeight" className='form-control control-input-ui' onChange={(e) => handleBMIMetricsValChange("metricsHeight", e.target.value, "numeric")} />
                                                </div>
                                                <div className='col-4 mb-3'>
                                                    <label className="form-label">Date</label>
                                                    <DatePicker
                                                        dateFormat="dd-MM-yyyy"
                                                        className='form-control control-input-ui'
                                                        selected={bmiFormData.metricsDate || null} // Ensure null when no date is selected
                                                        placeholderText="Enter Date" // Use placeholderText for DatePicker
                                                        maxDate={new Date()}
                                                        onChange={(date) => handleBMIMetricsValChange("metricsDate", date, "date")}
                                                        onKeyDown={(e) => e.preventDefault()}
                                                        onPaste={(e) => e.preventDefault()}
                                                    />
                                                </div>
                                                <div className='col-12'>
                                                    <span className={`${bmiFormData.metricsObservation !== "" ? "" : "hide"}`}>BMI: {bmiFormData.metricsVal}  ({bmiFormData.metricsObservation})</span>
                                                </div>
                                                <div className='col-12 text-center'>
                                                    <div className={`${weightUpdateError != "" ? "errorDiv" : "hide"}`}>{weightUpdateError}</div>
                                                    <div className={`${weightUpdateMessage != "" ? "messageDiv" : "hide"}`}>{weightUpdateMessage}</div>

                                                    <button className="btn btn-primary btn-primary program-save-btn me-2" onClick={handleAddWeightForm}>Save</button>
                                                    <button type="reset" class="btn clear-btn" onClick={(e) => {
                                                        setBMIFormData((prevFormData) => ({
                                                            ...prevFormData,
                                                            metricsId: "",
                                                            metricsName: "",
                                                            metricsVal: "",
                                                            metricsWeight: "",
                                                            metricsHeight: "",
                                                            metricsObservation: "",
                                                            metricsDate: null
                                                        }));
                                                        /*setWeight("");
                                                        setHeight("");
                                                        setBMI("");
                                                        setBMIObservation("");*/
                                                        setWeightUpdateError("");
                                                        setWeightUpdateMessage("");
                                                    }}>Clear</button>
                                                </div>
                                            </div>
                                    )
                                }
                            </div>
                        })
                    }
                </div>
            </div >

            <Modal id="HealthMetricsDetailsPopUp" show={showHealthMetricsDetailsPopUpModal} onHide={() => setShowHealthMetricsDetailsPopUpModal(false)} size="lg" backdrop="static" keyboard={false} fullscreen={false}>
                <Modal.Header closeButton>
                    <h1 className='modal-title'>{selectedMetrics.metricsName}</h1>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col lg="12" className=' mb-4'>
                            <div className='dashboard-graph-box card-task p-2 ps-1'>
                                {
                                    selectedMetrics.inputType == "numeric" &&
                                    <Line lineGraphTitle={selectedMetrics.metricsName} lineGraphType="metrics_insights_numeric_data" userId={props.userId} refreshVar={props.refreshVar} metricsId={selectedMetrics.metricsId} />
                                }
                                {
                                    selectedMetrics.inputType == "select" &&
                                    <Pie pieGraphTitle={selectedMetrics.metricsName} pieGraphType="metrics_insights_select_data" userId={props.userId} refreshVar={props.refreshVar} metricsId={selectedMetrics.metricsId} />
                                }
                                {
                                    selectedMetrics.inputType == "freetext" &&
                                    <MetricsFreeTextInsights userId={props.userId} metricsId={selectedMetrics.metricsId} metricsName={selectedMetrics.metricsName} />
                                }
                            </div>
                        </Col>
                    </Row>
                </Modal.Body>
            </Modal>
        </>

    );
};

export default HealthMetrics;