import React from 'react'; 
import { Modal } from 'react-bootstrap';

const OverFlowedText = ({show, handleClose, text}) => {
  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton></Modal.Header>
      <Modal.Body>{text}</Modal.Body>
    </Modal>
  )
}

export default OverFlowedText; 
