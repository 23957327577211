import React, { useState, useEffect, useRef } from 'react';
import { useParams } from "react-router-dom";
import { useNavigate } from 'react-router-dom';
import { fetchProcessedDataInsights, fetchProcessedDataInsightsGraphs, downloadFile } from '../../services/hih/Service';
import { Button, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { CustomLoader, ModalBoxButton } from '../utils/CommonLibrary';
import MembesAccrossPathwayMonthsBar from '../componenents/DashboardGraphsCNP/MembesAccrossPathwayMonthsBar';
import MemberPathwayStatusPie from "../componenents/DashboardGraphs/MemberPathwayStatusPie";

const ProcessedDataInsights = () => {

    const [viewData, setViewData] = useState({});
    const [loading, setLoading] = useState(true);

    const [totalViewData, setTotalViewData] = useState({
        'totalAgeMember': '', 'totalGenderMember': '', 'totalNetworkTypeMember': '', 'totalMemberTypeMember': '', 'totalServiceTypeMember': ''
    });

    const [seriesGraph, setSeriesGraph] = useState({
        ageDistribution: [],
        networkTypeGraph: [],
        genderMember: [],
        memberTypeGraph: [],
        serviceTypeGraph: []
    });

    var { id } = useParams();
    const navigate = useNavigate();
    const handleBackButton = () => {
        navigate(-1);
    };

    useEffect(() => {
        loadUserData();
        loadUserGraphData();
    }, [])

    const loadUserData = async () => {
        try {
            fetchProcessedDataInsights(id)
                .then((response) => {
                    if (response.data.code == 200) {
                        setViewData(response.data.data.insights);
                    }
                }).finally(() => {
                    setLoading(false);
                });
        } catch (error) {
            console.error('Error=', error);
        }
    }

    const loadUserGraphData = async () => {
        try {
            fetchProcessedDataInsightsGraphs(id)
                .then((response) => {
                    if (response.data.code == 200) {
                        const finalResponse = response.data.data;
                        setSeriesGraph(prevState => ({
                            ...prevState, ageDistribution: finalResponse.ageMember, networkTypeGraph: finalResponse.networkTypeMember, genderMember: finalResponse.genderMember.series.data, memberTypeGraph: finalResponse.memberTypeMember.series.data, serviceTypeGraph: finalResponse.serviceTypeMember.series.data
                        }));
                        setTotalViewData(prevState => ({
                            ...prevState, totalAgeMember: finalResponse.totalAgeMember, totalGenderMember: finalResponse.totalGenderMember, totalMemberTypeMember: finalResponse.totalMemberTypeMember, totalServiceTypeMember: finalResponse.totalServiceTypeMember, totalNetworkTypeMember: finalResponse.totalUsersNetworkType
                        }));
                    }
                }).finally(() => {
                    setLoading(false);
                });
        } catch (error) {
            console.error('Error=', error);
        }
    }

    const downloadFileApi = async (fileUrl) => {
        downloadFile(fileUrl)
            .then((response) => {
                if (response.data.code == 200) {
                    window.location.href = response.data.data.fileUrl;
                }
            }
            );
    }

    return (
        <>
            <section className='ContentMemberTransfer'>
                <div className="row align-items-center pb-3 sticky-top-bar">
                    <div className="col-md" style={{
                        display: 'flex',
                        alignItems: 'center'
                    }}>
                        <button className="btn btn-primary back-btn mb-0 me-3" onClick={handleBackButton}><i className="bi bi-chevron-left me-1"></i> Back</button>
                        <h1 className="main-title mb-0 ">Data Insights</h1>
                    </div>
                </div>
                <div className="container-fluid">
                    <div className="row align-items-center pb-3">
                        <div className='content-ProgramPerformance mb-3 p-2'>

                            <div className='row text-center px-2'>

                                <div className="col-6 px-1">
                                    <div className="card bg-grey" style={{ borderRadius: '14px', border: 'none', height: '100%' }}>
                                        <div className="card-body">
                                            <div className="row">
                                                <div className="col">
                                                    <h1 className="card-title1" style={{ fontSize: "16px", fontWeight: "500" }}>{viewData.uploadedBy}</h1>
                                                    <h5 className="card-text1">
                                                        Uploaded by
                                                    </h5>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-6 px-1">
                                    <div className="card bg-grey" style={{ borderRadius: '14px', border: 'none', height: '100%' }}>
                                        <div className="card-body">
                                            <div className="row">
                                                <div className="col">
                                                    <h1 className="card-title1" style={{ fontSize: "16px", fontWeight: "500" }}>{viewData.date}</h1>
                                                    <h5 className="card-text1">
                                                        Upload Date
                                                    </h5>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className='row text-center px-2 mt-2'>

                                <div className="col px-1">
                                    <div className="card bg-grey" style={{ borderRadius: '14px', border: 'none', height: '100%' }}>
                                        <div className="card-body">
                                            <div className="row">
                                                <div className="col">
                                                    <h1 className="card-title1 d-flex align-items-center">{viewData.totalRecordsCount} {viewData.totalRecordsCount > 0 && <Button className="dropdown-item btns-downloads ms-2" onClick={(e) => downloadFileApi(viewData.originalFileLink)}><i className="bi bi-download"></i></Button>}</h1>
                                                    <h5 className="card-text1">
                                                        Upload Records
                                                    </h5>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="col px-1">
                                    <div className="card bg-grey" style={{ borderRadius: '14px', border: 'none', height: '100%' }}>
                                        <div className="card-body">
                                            <div className="row">
                                                <div className="col">
                                                    <h1 className="card-title1 d-flex align-items-center">{viewData.successRecordsCount} {viewData.successRecordsCount > 0 && <Button className="dropdown-item btns-downloads ms-2" onClick={(e) => downloadFileApi(viewData.successFileLink)}><i className="bi bi-download"></i></Button>}</h1>
                                                    <h5 className="card-text1">
                                                        Upload Successful
                                                    </h5>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="col px-1">
                                    <div className="card bg-grey" style={{ borderRadius: '14px', border: 'none', height: '100%' }}>
                                        <div className="card-body">
                                            <div className="row">
                                                <div className="col">
                                                    <h1 className="card-title1 d-flex align-items-center">{viewData.failedRecordsCount}  {viewData.failedRecordsCount > 0 && <Button type="button" className="dropdown-item btns-downloads ms-2" onClick={() => downloadFileApi(viewData.failedFileLink)}><i className="bi bi-download"></i></Button>}</h1>
                                                    <h5 className="card-text1">
                                                        Upload Failed
                                                    </h5>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                            <div className='row text-center px-2 mt-2'>
                                <div className="col px-1">
                                    <div className="card bg-grey" style={{ borderRadius: '14px', border: 'none', height: '100%' }}>
                                        <div className="card-body">
                                            <div className="row">
                                                <div className="col">
                                                    <h1 className="card-title1 d-flex align-items-center">{viewData.ccmCount}</h1>
                                                    <h5 className="card-text1">
                                                        CCM Recommendations
                                                    </h5>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="col px-1">
                                    <div className="card bg-grey" style={{ borderRadius: '14px', border: 'none', height: '100%' }}>
                                        <div className="card-body">
                                            <div className="row">
                                                <div className="col">
                                                    <h1 className="card-title1 d-flex align-items-center">{viewData.nonCcmCount}</h1>
                                                    <h5 className="card-text1">
                                                        Non-CCM Recommendations
                                                    </h5>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col px-1">
                                    <div className="card bg-grey" style={{ borderRadius: '14px', border: 'none', height: '100%' }}>
                                        <div className="card-body">
                                            <div className="row">
                                                <div className="col">
                                                    <h1 className="card-title1 d-flex align-items-center">{viewData.vipCount}</h1>
                                                    <h5 className="card-text1">
                                                        VIP Recommendations
                                                    </h5>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="col px-1">
                                    <div className="card bg-grey" style={{ borderRadius: '14px', border: 'none', height: '100%' }}>
                                        <div className="card-body">
                                            <div className="row">
                                                <div className="col">
                                                    <h1 className="card-title1 d-flex align-items-center">{viewData.ambassadorIndCount}</h1>
                                                    <h5 className="card-text1">
                                                        Ambassador Recommendations
                                                    </h5>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {
                            viewData.successRecordsCount > 0 &&
                            <div className='container-fluid px-0 mb-4'>
                                <div className="row">
                                    <div className="col-md-12"><h1 className="main-title mt-2">Successful Graphs</h1></div>

                                    <div className="col-md-4 mb-3">
                                        <div className="dashboard-graph-box mb-3">
                                            <h1 className='dashboard-graph-title'>
                                                <span>Age Group
                                                    <OverlayTrigger placement="top"
                                                        overlay={<Tooltip className="custom-tooltip">Members segmented by age groups.</Tooltip>}>
                                                        <i className='bx bx-info-circle ms-1'></i>
                                                    </OverlayTrigger>
                                                </span>
                                                <span className="dashboard-graph-number">{totalViewData.totalAgeMember}</span>
                                            </h1>

                                            {loading ? (
                                                <></>
                                            ) : (
                                                seriesGraph.ageDistribution.dataValue ? (
                                                    <MembesAccrossPathwayMonthsBar dataMonth={seriesGraph.ageDistribution} />
                                                ) : (
                                                    <div className="emty-column">
                                                        <h5 className="emty-title-graph">There are no data to display</h5>
                                                    </div>
                                                )
                                            )}
                                        </div>
                                    </div>

                                    <div className="col-md-4 mb-3">
                                        <div className="dashboard-graph-box mb-3">
                                            <h1 className='dashboard-graph-title'>
                                                <span>Gender Distribution
                                                    <OverlayTrigger placement="top"
                                                        overlay={<Tooltip className="custom-tooltip">Gender wise Member distribution.</Tooltip>}>
                                                        <i className='bx bx-info-circle ms-1'></i>
                                                    </OverlayTrigger>
                                                </span>
                                                <span className="dashboard-graph-number">{totalViewData.totalGenderMember}</span>
                                            </h1>

                                            {loading ? (
                                                <></>
                                            ) : (
                                                seriesGraph.genderMember ? (
                                                    <MemberPathwayStatusPie series={seriesGraph.genderMember} />
                                                ) : (
                                                    <div className="emty-pie">
                                                        <h5 className="emty-title-graph">There are no data to display</h5>
                                                    </div>
                                                )
                                            )}
                                        </div>
                                    </div>

                                    <div className="col-md-4 mb-3">
                                        <div className="dashboard-graph-box mb-3">
                                            <h1 className='dashboard-graph-title'>
                                                <span>Network Distribution
                                                    <OverlayTrigger placement="top"
                                                        overlay={<Tooltip className="custom-tooltip">Network wise Member distribution.</Tooltip>}>
                                                        <i className='bx bx-info-circle ms-1'></i>
                                                    </OverlayTrigger>
                                                </span>
                                                <span className="dashboard-graph-number">{totalViewData.totalNetworkTypeMember}</span>
                                            </h1>

                                            {loading ? (
                                                <></>
                                            ) : (
                                                seriesGraph.networkTypeGraph ? (
                                                    <MembesAccrossPathwayMonthsBar dataMonth={seriesGraph.networkTypeGraph} />
                                                ) : (
                                                    <div className="emty-pie">
                                                        <h5 className="emty-title-graph">There are no data to display</h5>
                                                    </div>
                                                )
                                            )}
                                        </div>
                                    </div>

                                    <div className="col-md-4 mb-3">
                                        <div className="dashboard-graph-box mb-3">
                                            <h1 className='dashboard-graph-title'>
                                                <span>Service Type Distribution
                                                    <OverlayTrigger placement="top"
                                                        overlay={<Tooltip className="custom-tooltip">Service-Type wise Member distribution.</Tooltip>}>
                                                        <i className='bx bx-info-circle ms-1'></i>
                                                    </OverlayTrigger>
                                                </span>
                                                <span className="dashboard-graph-number">{totalViewData.totalServiceTypeMember}</span>
                                            </h1>

                                            {loading ? (
                                                <></>
                                            ) : (
                                                seriesGraph.serviceTypeGraph ? (
                                                    <MemberPathwayStatusPie series={seriesGraph.serviceTypeGraph} />
                                                ) : (
                                                    <div className="emty-pie">
                                                        <h5 className="emty-title-graph">There are no data to display</h5>
                                                    </div>
                                                )
                                            )}
                                        </div>
                                    </div>

                                    <div className="col-md-4 mb-3">
                                        <div className="dashboard-graph-box mb-3">
                                            <h1 className='dashboard-graph-title'>
                                                <span>Member Type Distribution
                                                    <OverlayTrigger placement="top"
                                                        overlay={<Tooltip className="custom-tooltip">Member-Type wise Member distribution.</Tooltip>}>
                                                        <i className='bx bx-info-circle ms-1'></i>
                                                    </OverlayTrigger>
                                                </span>
                                                <span className="dashboard-graph-number">{totalViewData.totalMemberTypeMember}</span>
                                            </h1>

                                            {loading ? (
                                                <></>
                                            ) : (
                                                seriesGraph.serviceTypeGraph ? (
                                                    <MemberPathwayStatusPie series={seriesGraph.memberTypeGraph} />
                                                ) : (
                                                    <div className="emty-pie">
                                                        <h5 className="emty-title-graph">There are no data to display</h5>
                                                    </div>
                                                )
                                            )}
                                        </div>
                                    </div>

                                </div>
                            </div>
                        }
                        <div className='row align-items-center mb-2'>
                            <div className="col-md-6 px-0"><h1 className="main-title mt-2 ">Status - CCM</h1></div>
                        </div>

                        <div className='content-ProgramPerformance mb-3 p-2'>

                            <div className='row text-center px-2'>

                                <div className="col-6 px-1">
                                    <div className="card bg-grey" style={{ borderRadius: '14px', border: 'none', height: '100%' }}>
                                        <div className="card-body">
                                            <div className="row">
                                                <div className="col">
                                                    <h1 className="card-title1" style={{ fontSize: "16px", fontWeight: "500" }}>{viewData.ccmCount}</h1>
                                                    <h5 className="card-text1">
                                                        Total Recommendation
                                                    </h5>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-6 px-1">
                                    <div className="card bg-grey" style={{ borderRadius: '14px', border: 'none', height: '100%' }}>
                                        <div className="card-body">
                                            <div className="row">
                                                <div className="col">
                                                    <h1 className="card-title1" style={{ fontSize: "16px", fontWeight: "500" }}>{viewData.ccmPendingCount}</h1>
                                                    <h5 className="card-text1">
                                                        Pending
                                                    </h5>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className='row text-center px-2 mt-2'>

                                <div className="col px-1">
                                    <div className="card bg-grey" style={{ borderRadius: '14px', border: 'none', height: '100%' }}>
                                        <div className="card-body">
                                            <div className="row">
                                                <div className="col">
                                                    <h1 className="card-title1" style={{ fontSize: "16px", fontWeight: "500" }}>{viewData.ccmRecommendedCount}</h1>
                                                    <h5 className="card-text1">
                                                        Recommended
                                                    </h5>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="col px-1">
                                    <div className="card bg-grey" style={{ borderRadius: '14px', border: 'none', height: '100%' }}>
                                        <div className="card-body">
                                            <div className="row">
                                                <div className="col">
                                                    <h1 className="card-title1" style={{ fontSize: "16px", fontWeight: "500" }}>{viewData.ccmDismissedCount}</h1>
                                                    <h5 className="card-text1">
                                                        Dismissed
                                                    </h5>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>


                {loading && <CustomLoader />}
            </section>

        </>
    )
}

export default ProcessedDataInsights