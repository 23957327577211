import common_axios from "./commonAxios";

let baseURL = common_axios.defaults.baseURL;

export const fetchDataUploadHistory = (startDateNew, endDateNew, pageNumber, perPage, sortColumn, sortDirection) => {
    const params = { "startDate": startDateNew, "endDate": endDateNew, 'pageNumber': pageNumber, 'perPage': perPage, 'sortColumn': sortColumn, 'sortDirection': sortDirection }
    var dataPass = common_axios.post(baseURL + 'hih/fetch_data_upload_history', params);
    return dataPass;
};

export const fetchProcessedDataInsights = (id) => {
    const params = { "id": id }
    var dataPass = common_axios.post(baseURL + 'hih/fetch_processed_data_insights', params);
    return dataPass;
};

export const fetchProcessedDataInsightsGraphs = (id) => {
    const params = { "id": id }
    var dataPass = common_axios.post(baseURL + 'hih/fetch_processed_data_insights_graphs', params);
    return dataPass;
};

export const downloadFile = (fileUrl) => {
    const params = { "fileUrl": fileUrl }
    var dataPass = common_axios.post(baseURL + 'hih/download_file', params);
    return dataPass;
};

export const fetchReports = (pageNumber, perPage, sortColumn, sortDirection) => {
    const params = { 'pageNumber': pageNumber, 'perPage': perPage, 'sortColumn': sortColumn, 'sortDirection': sortDirection }
    var dataPass = common_axios.post(baseURL + 'hih/fetch_reports', params);
    return dataPass;
};

export const generateReport = (startDate, endDate, downloadEvent) => {
    let params = { 'startDate': startDate, 'endDate': endDate, 'downloadEvent': downloadEvent }
    let response = common_axios.post('hih/generate_reports', params);
    return response;
}

export const getFileLink = (id) => {
    let params = { 'reportId': id }
    var dataPass = common_axios.post(baseURL + 'hih/fetch_fileurl', params);
    return dataPass;
};

export const fetchServiceRecommendationHistory = (searchStr, pageNumber, perPage, sortColumn, sortDirection) => {
    const params = { 'searchStr': searchStr, 'pageNumber': pageNumber, 'perPage': perPage, 'sortColumn': sortColumn, 'sortDirection': sortDirection }
    var dataPass = common_axios.post(baseURL + 'hih/fetch_service_recommendation_history', params)
    return dataPass;
}
