import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { agent_configuration_setting, fetch_progtramcondition_data, fetch_agent_setting_script_data, fetch_last_save_setting_list } from '../../../services/met/Service';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { LoaderLoader } from '../../utils/CommonLibrary';
import { formatDate } from '../utils/CommonLibrary';


function ConfigurationSettingsAgentCallsDetails() {

  const [startDate, setStartDate] = useState(() => {
    const today = new Date();
    today.setHours(9, 0, 0, 0);
    return today;
  }); 

  const [endDate, setEndDate] = useState(() => {
    const today = new Date();
    today.setHours(18, 0, 0, 0);
    return today;
  });

  const [breakStartDate1, setBreakStartDate1] = useState(() => {
    const today = new Date();
    today.setHours(13, 0, 0, 0);
    return today;
  });

  const [breakEndDate1, setBreakEndDate1] = useState(() => {
    const today = new Date();
    today.setHours(14, 0, 0, 0);
    return today;
  });

  const [breakStartDate2, setBreakStartDate2] = useState(() => {
    const today = new Date();
    today.setHours(16, 0, 0, 0);
    return today;
  });

  const [breakEndDate2, setBreakEndDate2] = useState(() => {
    const today = new Date();
    today.setHours(16, 30, 0, 0);
    return today;
  });


  const [programData, setprogramData] = useState([]);
  const [successMsg, setSuccessMsg] = useState('');
  const [errormsg, setErrormsg] = useState(null);
  const [errormsg1, setErrormsg1] = useState(null);
  const [errormsg2, setErrormsg2] = useState(null);

  const handleDateChange = (setter) => (date) => {
    const currentDate = new Date();
    const selectedDate = new Date(date);
    selectedDate.setFullYear(currentDate.getFullYear());
    selectedDate.setMonth(currentDate.getMonth());
    selectedDate.setDate(currentDate.getDate());
    setter(selectedDate);
  };

  const [isLoading, setIsLoading] = useState(false);
  const [language, setLanguage] = useState('Eng');

  const [script, setScript] = useState({
    DiabetesMellitus: { Eng: '', Arb: '' },
    Hypertension: { Eng: '', Arb: '' },
    Dyslipidemia: { Eng: '', Arb: '' },
  });
  
  const [agentScriptData, setAgentScriptData] = useState([]);

  const { register, handleSubmit, setValue, watch, formState: { errors }, setError, clearErrors } = useForm({
    defaultValues: {  
      batchSize: "25",
      enrollmentTarget: "15",
      callRetries: "4",
      callRetryGap: "30 minutes",
      poolingDayDifference: "1", 
    }
  });

  // loading  api's
  useEffect(() => {
    fetchProgramData();
    fetchAgentScript();
    fetchLastSaveSettingList();
  }, [setValue]);


  const formatDateConvertionTime = (date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const seconds = String(date.getSeconds()).padStart(2, '0');

    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
  };

  const fetchLastSaveSettingList = async () => {

    setIsLoading(true); //to show loader
    try {
      let resp = await fetch_last_save_setting_list();
      if (resp.data.code === 200) {
        setStartDate(new Date(resp.data.data[0].shiftStartTime));
        setEndDate(new Date(resp.data.data[0].shiftEndTime));
        setBreakStartDate1(new Date(resp.data.data[0].breakStartTime1));
        setBreakEndDate1(new Date(resp.data.data[0].breakEndTime1));
        setBreakStartDate2(new Date(resp.data.data[0].breakStartTime2));
        setBreakEndDate2(new Date(resp.data.data[0].breakEndTime2));
        setValue('batchSize', resp.data.data[0].poolingLimit.toString());
        setValue('enrollmentTarget', resp.data.data[0].enrollmentTargetBatch.toString());
        setValue('callRetries', resp.data.data[0].callAttempt.toString());
        setValue('callRetryGap', resp.data.data[0].nextAttempedDifference.toString());
        setValue('poolingDayDifference', resp.data.data[0].poolingDayDifference.toString()); 
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }

  }


  const areDatesSame = (dateStr1, dateStr2) => {
    let date1 = new Date(dateStr1);
    let date2 = new Date(dateStr2);
    const d1 = date1.toISOString().split('T')[0];
    const d2 = date2.toISOString().split('T')[0];
    return d1 === d2;
  };

  const isValidDateRange = (startStr, endStr) => {
    const startDate = new Date(startStr);
    const endDate = new Date(endStr);

    return startDate < endDate;
  };

  const getTimeComponents = (date) => {
    return {
      hours: date.getHours(),
      minutes: date.getMinutes(),
      seconds: date.getSeconds(),
    };
  };
  
  // Function to compare time values
  const isTimeWithinShift = (breakStart, breakEnd, shiftStart, shiftEnd) => {
    return (
      (breakStart.hours > shiftStart.hours || (breakStart.hours === shiftStart.hours && breakStart.minutes >= shiftStart.minutes)) &&
      (breakEnd.hours < shiftEnd.hours || (breakEnd.hours === shiftEnd.hours && breakEnd.minutes <= shiftEnd.minutes))
    );
  };

  const doTimesOverlap = (start1, end1, start2, end2) => {
    return (
      (start1.hours < end2.hours || (start1.hours === end2.hours && start1.minutes < end2.minutes)) &&
      (start2.hours < end1.hours || (start2.hours === end1.hours && start2.minutes < end1.minutes))
    );
  };
  

  const onSubmit = async (formData) => {
    const shiftStartTime = formatDateConvertionTime(startDate);
    const shiftEndTime = formatDateConvertionTime(endDate);
    const breakStartTime = formatDateConvertionTime(breakStartDate1);
    const breakEndTime = formatDateConvertionTime(breakEndDate1);
    const breakStartTime1 = formatDateConvertionTime(breakStartDate2);
    const breakEndTime1 = formatDateConvertionTime(breakEndDate2);

    let sdate = formatDate(shiftStartTime);
    let edate = formatDate(shiftEndTime);
    
    if (startDate.getTime() === endDate.getTime()) {
      setErrormsg('Select valid shift start and end times.');
      return;
    }
 
    if (!isValidDateRange(shiftStartTime, shiftEndTime)) {
      setErrormsg('Select valid shift start and end times.');
      return;
    }

    // validating the breaks for the agents 
    // Get time components for the shifts
const startTime = getTimeComponents(startDate);
const endTime = getTimeComponents(endDate);

// Get time components for breaks
const breakStart1Time = getTimeComponents(breakStartDate1);
const breakEnd1Time = getTimeComponents(breakEndDate1);
const breakStart2Time = getTimeComponents(breakStartDate2);
const breakEnd2Time = getTimeComponents(breakEndDate2);


    // Validating the breaks for the agents
    if (!isTimeWithinShift(breakStart1Time, breakEnd1Time, startTime, endTime)) {
      setErrormsg1('Break times must be within the shift times.');
      return;
    }

    if((breakStart1Time.hours === breakEnd1Time.hours && breakStart1Time.minutes === breakEnd1Time.minutes)){ 
      setErrormsg1('Break times cannot be identical.');
      return;
    }

    if (
      (breakStart1Time.hours === breakStart2Time.hours && breakStart1Time.minutes === breakStart2Time.minutes) || 
      (breakStart1Time.hours > breakEnd1Time.hours || (breakStart1Time.hours === breakEnd1Time.hours && breakStart1Time.minutes > breakEnd1Time.minutes))
    ) {
      setErrormsg1('Break times cannot be identical.');
      return;
    }

    if (
      (breakEnd1Time.hours === breakEnd2Time.hours && breakEnd1Time.minutes === breakEnd2Time.minutes) || 
      (breakStart2Time.hours > breakEnd2Time.hours || (breakStart2Time.hours === breakEnd2Time.hours && breakStart2Time.minutes > breakEnd2Time.minutes))
    ) {
      setErrormsg2('Break times cannot be identical.');
      return;
    }

    if (!isTimeWithinShift(breakStart2Time, breakEnd2Time, startTime, endTime)) {
      setErrormsg2('Break times must be within the shift times.');
      return;
    }

    if (
      !isTimeWithinShift(breakStart1Time, breakEnd1Time, startTime, endTime) ||
      !isTimeWithinShift(breakStart2Time, breakEnd2Time, startTime, endTime)
    ) { 
      setErrormsg2('Break times must be within shift hours.');
      return;
    }


    if (doTimesOverlap(breakStart1Time, breakEnd1Time, breakStart2Time, breakEnd2Time)) {
      setErrormsg1('Breaks cannot overlap.');
      setErrormsg2('Breaks cannot overlap.');
      return;
    }


    const payload = { 
      ...formData,
      shiftStartTime: shiftStartTime,
      shiftEndTime: shiftEndTime,
      breakStartTime: breakStartTime,
      breakEndTime: breakEndTime,
      breakStartTime1: breakStartTime1,
      breakEndTime1: breakEndTime1,
    };

    if (!parseInt(payload.enrollmentTarget)) {
      setError('enrollmentTarget', { type: 'manual', message: 'Requires a numeric value.' });
      return;
    } else if (parseInt(payload.batchSize) < parseInt(payload.enrollmentTarget)) {
      setError('enrollmentTarget', { type: 'manual', message: 'Daily Enrollment Target should be less than  Data Per Batch' });
      return;
    }


    // validate the form data 
    if (!payload.batchSize) {
      setError('batchSize', {
        type: 'manual',
        message: 'This field is required'
      });

      return;
    }

    if (!payload.callRetries) {
      setError('callRetries', {
        type: 'manual',
        message: 'This field is required'
      });

      return;
    }

    if (!payload.callRetryGap) {
      setError('callRetryGap', {
        type: 'manual',
        message: 'This field is required'
      });

      return;
    }

    setIsLoading(true); //show loader
    

    try {

      const response = await agent_configuration_setting(payload);
      
      if (response.data.code == 200) {
        console.log(response.data.data.message);
        setSuccessMsg(response.data.data.message);
        // setStartDate(new Date());
        // setEndDate(new Date());
        // setBreakStartDate1(new Date());
        // setBreakEndDate1(new Date());
        // setBreakStartDate2(new Date());
        // setBreakEndDate2(new Date());
        setErrormsg('');
        setErrormsg1('');
        setErrormsg2('');
      } else {
        
      }
      //message disappear 
      setTimeout(() => {
        setSuccessMsg("");
      }, 1000);

    } catch (error) {
      console.error(error);
    }
    finally {
      setIsLoading(false); //show loader
    }

  }

  const handleLanguageChange = (condition, lang) => {
    setLanguage(lang);
    setScript({ ...script });
  };

  const handleScriptChange = (condition, lang, value) => {
    setScript(prevState => ({
      ...prevState,
      [condition]: {
        ...prevState[condition],
        [lang]: value,
      },
    }));
  };

  const handleFormDataChange = (key, value) => {
    setValue(key, value); 
  };

  //fetch program data
  const fetchProgramData = async () => {
    try {
      const resp = await fetch_progtramcondition_data();
      if (resp.data.code == 200) {
        console.log(resp.data);
        setprogramData(resp.data.data);
      }
    } catch (error) {
      console.error(error);
    }
  }

  // fetching agent scriptss
  const fetchAgentScript = async () => {
    try {
      const resp = await fetch_agent_setting_script_data();
      if (resp.data.code == 200) {
        
        setAgentScriptData(resp.data.data);
        const keyValueArray = [
          { key: 'eng', value: resp.data.data[1].script },
          { key: 'arb', value: resp.data.data[0].script },
        ];
        const newScript = {
          DiabetesMellitus: { Eng: resp.data.data[1].script, Arb: resp.data.data[0].script },
          Hypertension: { Eng: '', Arb: '' },
          Dyslipidemia: { Eng: '', Arb: '' },
        };
        setScript(newScript);

        setValue('DiabetesMellitus.Eng', newScript.DiabetesMellitus.Eng);
        setValue('DiabetesMellitus.Arb', newScript.DiabetesMellitus.Arb);
      }
    } catch (error) {
      console.error(error);
    }
  }


  // handle form change 
  function handleFormChange(e) { 
    const {value, name} = e.target; 
    clearErrors(name); 
    setValue(name, value); 
  }


  const shiftStartTime = watch('shiftStartTime');
  const shiftEndTime = watch('shiftEndTime');
  const breakStartTime = watch('breakStartTime');
  const breakEndTime = watch('breakEndTime');
  const breakStartTime1 = watch('breakStartTime1');
  const breakEndTime1 = watch('breakEndTime1');


  return (
    <>
      <section className='ConfigurationSettingsAgentCall'>
        <div className="row align-items-center pb-3 sticky-top-bar">
          <div className="col-md-12">
            <h1 className="main-title">Agent Call Settings</h1>
          </div>
        </div>

        <div className='reports-programs mb-3'>
          <div className="row align-items-center">
            <div className="col-md-auto">
              <img src="https://storage.googleapis.com/ksabupatest/2024/09/24/5jw18/wh4l7e7hk4.png" alt="Invited Members icon" style={{ height: "140px" }} className="img-fluid mb-0" />
            </div>
            <div className="col-md">
              <p className="mb-0">The agent Call Settings allows you to easily manage all settings for agents making calls to enroll members in programs. You can configure call batches, retries, target enrollments, and specific scripts for various programs and conditions.</p>
            </div>
          </div>
        </div>

        <div className="container-fluid">
          <div className="row align-items-center pb-3">
            <div className='comntent-ConfigurationSettingsAgentCall p-3'>
              <LoaderLoader isLoading={isLoading} />
              <form onSubmit={handleSubmit(onSubmit)} className="row">

                <div className="col-md-8 pe-4">
                  <div className="row">
                    <div className="col-md-6 mb-4">
                      <div className='row'>
                        <div className="col-12 mb-2">
                          <h1 className="ConfigurationSettings-title mb-0">Calling Data per Batch <span style={{ color: "red" }}>*</span>  </h1>
                        </div>
                        <div className="col-12">

                          <div className="form-check form-check-inline">
                            <input className="form-check-input" name='batchSize' id="callingDataRadio1" type="radio" {...register('batchSize', { required: true })} value="25"
                            onChange={handleFormChange}
                            />
                            <label htmlFor='callingDataRadio1' className="form-check-label pt-1">25</label>
                          </div>

                          <div className="form-check form-check-inline">
                            <input className="form-check-input" name='batchSize' id="callingDataRadio2" type="radio" {...register('batchSize', { required: true })} value="50"  
                            onChange={handleFormChange}
                            />
                            <label htmlFor='callingDataRadio2' className="form-check-label pt-1">50</label>
                          </div>

                          <div className="form-check form-check-inline">
                            <input className="form-check-input" name='batchSize' id="callingDataRadio3" type="radio" {...register('batchSize', { required: true })} value="75" 
                            onChange={handleFormChange}
                            />
                            <label htmlFor='callingDataRadio3' className="form-check-label pt-1">75</label>
                          </div>

                          <div className="form-check form-check-inline">
                            <input className="form-check-input" name='batchSize' id="callingDataRadio4" type="radio" {...register('batchSize', { required: true })} value="100" 
                              onChange={handleFormChange}
                            />
                            <label htmlFor='callingDataRadio4' className="form-check-label pt-1">100</label>
                          </div>

                          <div className="form-check form-check-inline">
                            <input className="form-check-input" name='batchSize' id="callingDataRadio5" type="radio" {...register('batchSize', { required: true })} value="125" 
                            onChange={handleFormChange}
                            />
                            <label htmlFor='callingDataRadio5' className="form-check-label pt-1">125</label>
                          </div>
                          <br />
                          {errors.batchSize && <span className="text-danger">This field is required</span>}
                        </div>
                      </div>
                    </div>

                    <div className="col-md-6 mb-4">
                      <div className='row'>
                        <div className="col-12 mb-2">
                          <h1 className="ConfigurationSettings-title mb-0">Daily Enrollment Target Per Agent <span style={{ color: "red" }}>*</span></h1>
                        </div>
                        <div className="col-12">
                          <input type="text" className="input-field-ui" name="enrollmentTarget" placeholder="Select Daily Conversion Target" min={1} {...register('enrollmentTarget', { required: true, })} maxLength="2"
                            onChange={handleFormChange}
                          />

                          {errors.enrollmentTarget && <p className="text-danger">{errors.enrollmentTarget.message || 'This field is required'}</p>}
                        </div>
                      </div>
                    </div>

                    <div className="col-md-6 mb-4">
                      <div className='row'>
                        <div className="col-12 mb-2">
                          <h1 className="ConfigurationSettings-title mb-0">Call Retries if Not Answered  <span style={{ color: "red" }}>*</span> </h1>
                        </div>
                        <div className="col-12">
                          <div className="form-check form-check-inline">
                            <input className="form-check-input" name='callRetries' id="callRetriesRadio1" type="radio" {...register('callRetries', { required: true })} value="2" 
                            onChange={handleFormChange}
                            />
                            <label htmlFor='callRetriesRadio1' className="form-check-label pt-1">2</label>
                          </div>
                          <div className="form-check form-check-inline">
                            <input className="form-check-input" name='callRetries' id="callRetriesRadio2" type="radio" {...register('callRetries', { required: true })} value="3" 
                            onChange={handleFormChange}
                            />
                            <label htmlFor='callRetriesRadio2' className="form-check-label pt-1">3</label>
                          </div>
                          <div className="form-check form-check-inline">
                            <input className="form-check-input" name='callRetries' id="callRetriesRadio3" type="radio" {...register('callRetries', { required: true })} value="4" onChange={handleFormChange} />
                            <label htmlFor='callRetriesRadio3' className="form-check-label pt-1">4</label>
                          </div>
                          <div className="form-check form-check-inline">
                            <input className="form-check-input" name='callRetries' id="callRetriesRadio4" type="radio" {...register('callRetries', { required: true })} value="5" onChange={handleFormChange} />
                            <label htmlFor='callRetriesRadio4' className="form-check-label pt-1">5</label>
                          </div>
                          <div className="form-check form-check-inline">
                            <input className="form-check-input" name='callRetries' id="callRetriesRadio5" type="radio" {...register('callRetries', { required: true })} value="6" onChange={handleFormChange} />
                            <label htmlFor='callRetriesRadio5' className="form-check-label pt-1">6</label>
                          </div>
                          <div className="form-check form-check-inline">
                            <input className="form-check-input" name='callRetries' id="callRetriesRadio6" type="radio" {...register('callRetries', { required: true })} value="7" onChange={handleFormChange} />
                            <label htmlFor='callRetriesRadio6' className="form-check-label pt-1">7</label>
                          </div>
                          <br />
                          {errors.callRetries && <span className="text-danger">This field is required</span>}
                        </div>
                      </div>
                    </div>

                    <div className="col-md-6 mb-4">
                      <div className='row'>
                        <div className="col-12 mb-2">
                          <h1 className="ConfigurationSettings-title mb-0">Call Retries Minimum Gap Before Next Attempt  <span style={{ color: "red" }}>*</span> </h1>
                        </div>
                        <div className="col-12">
                          <div className="form-check form-check-inline">
                            <input className="form-check-input" name='callRetryGap' id="callRetriesAttemptRadio1" type="radio" {...register('callRetryGap', { required: true })} value="10 minutes" 
                            onChange={handleFormChange}
                            />
                            <label htmlFor='callRetriesAttemptRadio1' className="form-check-label pt-1">10 min</label>
                          </div>
                          <div className="form-check form-check-inline">
                            <input className="form-check-input" name='callRetryGap' id="callRetriesAttemptRadio2" type="radio" {...register('callRetryGap', { required: true })} value="20 minutes" onChange={handleFormChange}
                             />
                            <label htmlFor='callRetriesAttemptRadio2' className="form-check-label pt-1">20 min</label>
                          </div>
                          <div className="form-check form-check-inline">
                            <input className="form-check-input" name='callRetryGap' id="callRetriesAttemptRadio3" type="radio" {...register('callRetryGap', { required: true })} value="30 minutes" onChange={handleFormChange}
                            />
                            <label htmlFor='callRetriesAttemptRadio3' className="form-check-label pt-1">30 min</label>
                          </div>
                          <div className="form-check form-check-inline">
                            <input className="form-check-input" name='callRetryGap' id="callRetriesAttemptRadio4" type="radio" {...register('callRetryGap', { required: true })} value="40 minutes" onChange={handleFormChange}
                            />
                            <label htmlFor='callRetriesAttemptRadio4' className="form-check-label pt-1">40 min</label>
                          </div>
                          {errors.callRetryGap && <span className="text-danger">This field is required</span>}
                        </div>
                      </div>
                    </div>

                    <div className='col-md-6 mb-4'>
                      <div className='row'>
                        <div className="col-12 mb-2">
                          <h1 className="ConfigurationSettings-title mb-0">Retarget Members After (Days) <span style={{ color: "red" }}>*</span></h1>
                        </div>
                        <div className="col-12">
                          <input type="text" name='poolingDayDifference' class="input-field-ui" placeholder="Select Daily Conversion Target" min={1} {...register('poolingDayDifference', { required: true, })} maxLength="2"
                          onChange={handleFormChange}
                          />

                          {errors.poolingDayDifference && <p className="text-danger">{errors.poolingDayDifference.message || 'This field is required'}</p>}
                        </div>
                      </div>
                    </div>

                   

                    <div className='col-md-12 mb-4'>
                      <div className='bg-light p-3 input-field-ui'>
                        <div className='row'>

                          <div className="col-md-9 mb-4">
                            <div className='row'>
                              <div className="col-12 mb-2">
                                <h1 className="ConfigurationSettings-title mb-0">Agent Working Hours</h1>
                              </div>
                              <div className="col-4 pe-1" style={{ position: 'relative' }}>
                                <DatePicker
                                  selected={startDate}
                                  onChange={handleDateChange(setStartDate)}
                                  showTimeSelect
                                  showTimeSelectOnly
                                  timeIntervals={10}
                                  timeCaption="Time"
                                  dateFormat="h:mm aa"
                                  className="form-control input-field-ui ps-5"
                                  onKeyDown={(e) => {
                                    e.preventDefault();
                                  }}
                                />
                                <img src="https://storage.googleapis.com/ksabupatest/2024/09/24/7sjac/l8s4x2s84s.png" className='clock-icon1' alt="" />

                                {/* <input type="time" className="form-control input-field-ui" {...register('shiftStartTime', { required: true })} /> */}
                                <label className="col-form-label">Shift Start Time</label><br />
                                {errors.shiftStartTime && <span className="text-danger">{errors.shiftStartTime.message || 'This field is required'}</span>}
                              </div>
                              <div className="col-4 ps-1" style={{ position: 'relative' }}>
                                <DatePicker
                                  selected={endDate}
                                  onChange={handleDateChange(setEndDate)}
                                  showTimeSelect
                                  showTimeSelectOnly
                                  timeIntervals={10}
                                  timeCaption="Time"
                                  dateFormat="h:mm aa"
                                  className="form-control input-field-ui ps-5"
                                  onKeyDown={(e) => {
                                    e.preventDefault();
                                  }}
                                />
                                <img src="https://storage.googleapis.com/ksabupatest/2024/09/24/7sjac/l8s4x2s84s.png" className='clock-icon' alt="" />
                                {/* <input type="time" className="form-control input-field-ui" {...register('shiftEndTime', { required: true })} /> */}
                                <label className="col-form-label">Shift End Time</label><br />
                                {errormsg && <div className="text-danger">{errormsg}</div>}
                              </div>
                              <div className="col">
                                {/* <h1 className="ConfigurationSettings-title mt-3 mb-0">(9 Hours)</h1> */}
                              </div>
                            </div>
                          </div>

                          <div className="col-md-12">
                            <div className='row'>
                              <div className="col-12 mb-2">
                                <h1 className="ConfigurationSettings-title mb-0">Break Time   </h1>
                              </div>
                              <div className="col-3 pe-1" style={{ position: 'relative' }}>
                                <DatePicker
                                  selected={breakStartDate1}
                                  onChange={handleDateChange(setBreakStartDate1)}
                                  showTimeSelect
                                  showTimeSelectOnly
                                  timeIntervals={10}
                                  timeCaption="Time"
                                  dateFormat="h:mm aa"
                                  className="form-control input-field-ui ps-5"
                                  onKeyDown={(e) => {
                                    e.preventDefault();
                                  }}
                                />
                                <img src="https://storage.googleapis.com/ksabupatest/2024/09/24/7sjac/l8s4x2s84s.png" className='clock-icon1' alt="" />
                                {/* <input type="time" className="form-control input-field-ui" {...register('breakStartTime', { required: true })}  /> */}
                                <label className="col-form-label">Break Time 1</label><br />
                                {errormsg1 && <div className="text-danger">{errormsg1}</div>}
                              </div>
                              <div className="col-3 ps-1" style={{ position: 'relative' }}>
                                <DatePicker
                                  selected={breakEndDate1}
                                  onChange={handleDateChange(setBreakEndDate1)}

                                  showTimeSelect
                                  showTimeSelectOnly
                                  timeIntervals={10}
                                  timeCaption="Time"
                                  dateFormat="h:mm aa"
                                  className="form-control input-field-ui ps-5"
                                  onKeyDown={(e) => {
                                    e.preventDefault();
                                  }}
                                />
                                <img src="https://storage.googleapis.com/ksabupatest/2024/09/24/7sjac/l8s4x2s84s.png" className='clock-icon' alt="" />
                                {/* <input type="time" className="form-control input-field-ui" {...register('breakEndTime', { required: true })} /> */}
                                {errors.breakEndTime && <span className="text-danger">{errors.breakEndTime.message || 'This field is required'}</span>}
                              </div>
                              <div className="col-3 pe-1" style={{ position: 'relative' }}>
                                <DatePicker
                                  selected={breakStartDate2}
                                  onChange={handleDateChange(setBreakStartDate2)}

                                  showTimeSelect
                                  showTimeSelectOnly
                                  timeIntervals={10}
                                  timeCaption="Time"
                                  dateFormat="h:mm aa"
                                  className="form-control input-field-ui ps-5"
                                  onKeyDown={(e) => {
                                    e.preventDefault();
                                  }}
                                />
                                <img src="https://storage.googleapis.com/ksabupatest/2024/09/24/7sjac/l8s4x2s84s.png" className='clock-icon1' alt="" />
                                {/* <input type="time" className="form-control input-field-ui" {...register('breakStartTime1', { required: true})} /> */}
                                <label className="col-form-label">Break Time 2</label><br />
                                {errormsg2 && <div className="text-danger">{errormsg2}</div>}
                              </div>
                              <div className="col-3 ps-1" style={{ position: 'relative' }}>
                                <DatePicker
                                  selected={breakEndDate2}
                                  onChange={handleDateChange(setBreakEndDate2)}

                                  showTimeSelect
                                  showTimeSelectOnly
                                  timeIntervals={10}
                                  timeCaption="Time"
                                  dateFormat="h:mm aa"
                                  className="form-control input-field-ui ps-5"
                                  onKeyDown={(e) => {
                                    e.preventDefault();
                                  }}
                                />
                                <img src="https://storage.googleapis.com/ksabupatest/2024/09/24/7sjac/l8s4x2s84s.png" className='clock-icon' alt="" />
                                {/* <input type="time" className="form-control input-field-ui" {...register('breakEndTime1', { required: true})} /> */}
                                {errors.breakEndDate2 && <span className="text-danger">{errors.breakEndDate2.message || 'This field is required'}</span>}
                              </div>
                            </div>


                          </div>
                        </div>
                      </div>
                    </div>


                  </div>
                </div>
                <div className="col-md-4 mb-3 ps-4 border-start">
                  <div className='row mb-3' style={{ display: 'none' }}>
                    <div className="col-12 mb-2">
                      <h1 className="ConfigurationSettings-title mb-0">Select Condition for Script </h1>
                    </div>
                    <div className="col-12">
                      <select className="form-select input-field-ui" {...register('programId', { required: true })}>
                        <option value="1" selected>Diabetes Mellitus</option>
                        {/* {programData.map(option => (
                                <option key={option.conditionId} value={option.conditionId} selected>{option.conditionName}</option>
                            ))
                } */}
                      </select>
                      {/* {errors.programId && <p className="text-danger">This field is required</p>} */}
                    </div>
                  </div>
                  <div className='row mb-1 align-items-center'>
                    <div className='col'>
                      <label className="ConfigurationSettings-title mb-0">Langauage   </label>
                    </div>
                    <div className='col-auto'>
                      <div className="form-check form-check-inline mb-0">
                        <input className="form-check-input" type="radio" name="diabetesLanguage" id="diabetesLanguageRadio1" onClick={() => handleLanguageChange('DiabetesMellitus', 'Eng')} defaultChecked />
                        <label htmlFor="diabetesLanguageRadio1" className="form-check-label pt-1">English</label>
                      </div>
                      <div className="form-check form-check-inline mb-0">
                        <input className="form-check-input" type="radio" name="diabetesLanguage" id="diabetesLanguageRadio2" onClick={() => handleLanguageChange('DiabetesMellitus', 'Arb')} />
                        <label htmlFor="diabetesLanguageRadio2" className="form-check-label pt-1">Arabic</label>
                      </div>
                    </div>
                  </div>
                  <textarea
                    className="form-control input-field-ui"
                    placeholder="Program/Condition Specific Script"
                    rows="13"
                    value={script.DiabetesMellitus[language]}
                    {...register(`DiabetesMellitus.${language}`, { required: true })}
                    onChange={(e) => handleScriptChange('DiabetesMellitus', language, e.target.value)}

                    style={language == 'Arb' ? { direction: 'rtl' } : {}}
                  ></textarea>
                  {errors.DiabetesMellitus && (
                    <span className="text-danger">This field is required</span>
                  )}
                </div>
                {successMsg && <div className="col-md-12 mt-2 mb-4 text-center">
                  <h1 className="ConfigurationSettings-title mb-0 text-center"><span style={{ color: 'green' }}>{successMsg}</span></h1>
                </div>}
                <div className="col-12 mb-2 text-center">
                  <button type="submit" className="btn btn-dark program-save-btn">Save</button>
                </div>


              </form>



            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default ConfigurationSettingsAgentCallsDetails;
