// import React from 'react';
import React, { useState } from "react";
import CryptoJS from 'crypto-js';
import Loader from "react-js-loader";
import { Modal } from "react-bootstrap";
import { Link } from "react-router-dom";
import * as XLSX from "xlsx"
import { jsPDF } from 'jspdf';
import html2canvas from 'html2canvas';
import 'jspdf-autotable';
import { validateFormNewConditionAdd, validateFormNewProgramAdd, validateFormPathwayAdd } from "./validationUtils";
import { AddNewCondition, deleteNewPathway, editFetchNewPathway, editFetchNewProgram, editNewPathway, editNewProgram, publishNewProgram, publishNewPathway, removeNewProgram, displayMessagePathway } from "../../services/mtp/Service";
import { DisplayMessage } from "../componenents/ContentPrograms/DropDownAction";
import DOMPurify from 'dompurify';


export const encryptDataEdge = (text, key) => {
  try {

    // const key ="8nelrpD8YInUKDM5H6K5WIk9iBhJCVjAOkgU9hQOi2mz4CkbNIFVfVFVk6CaSqUU";
    const encrypted = CryptoJS.AES.encrypt(text, key).toString();
    const urlEncodeString = encodeURIComponent(encrypted);
    return urlEncodeString;
  } catch (error) {
    console.error("encryptData Exception = ", error);
    return false;
  }
};

export const encryptDataNew = (text) => {
  try {
    const key_hex = process.env.REACT_APP_KEY;
    const iv_hex = process.env.REACT_APP_IV;

    const key = CryptoJS.enc.Hex.parse(key_hex);
    const iv = CryptoJS.enc.Hex.parse(iv_hex);

    const encrypted = CryptoJS.AES.encrypt(text, key, {
      iv: iv,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7
    });
    return encrypted.toString();
  } catch (error) {
    console.error("encryptData Exception = ", error);
    return false;
  }
};

export const decryptData = (encryptedText) => {
  const key = CryptoJS.enc.Hex.parse(process.env.REACT_APP_KEY);
  const iv = CryptoJS.enc.Hex.parse(process.env.REACT_APP_IV);

  const decrypted = CryptoJS.AES.decrypt(encryptedText, key, {
    iv: iv,
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.Pkcs7
  });
  return decrypted.toString(CryptoJS.enc.Utf8);
};

export const decruptCipherJson = (ciphertext) => {
  try {
    var key = 'a1b2c3d4e5f6g7h8';
    var ciphertextWA = CryptoJS.enc.Base64.parse(ciphertext);
    var keyWA = CryptoJS.enc.Utf8.parse(key);
    var ivWA = CryptoJS.lib.WordArray.create(ciphertextWA.words.slice(0, 4));
    var hmacWA = CryptoJS.lib.WordArray.create(ciphertextWA.words.slice(4, 4 + 8));
    var actualCiphertextWA = CryptoJS.lib.WordArray.create(ciphertextWA.words.slice(4 + 8));
    var hmacCalculatedWA = CryptoJS.HmacSHA256(actualCiphertextWA, keyWA);
    var decryptedMessageWA = CryptoJS.AES.decrypt({ ciphertext: actualCiphertextWA }, keyWA, { iv: ivWA });
    var decryptedMessage = CryptoJS.enc.Utf8.stringify(decryptedMessageWA);

    if (!decryptedMessage) {
      window.location.href = '/logout';
    }
  } catch {
    window.location.href = '/logout';
  }

  return JSON.parse(decryptedMessage);
}

export const encryptCipherJson = (data) => {
  const key = 'a1b2c3d4e5f6g7h8';
  const keyWA = CryptoJS.enc.Utf8.parse(key);
  const ivWA = CryptoJS.lib.WordArray.random(16);
  const jsonString = JSON.stringify(data);
  const encryptedMessage = CryptoJS.AES.encrypt(jsonString, keyWA, { iv: ivWA });
  const hmacWA = CryptoJS.HmacSHA256(encryptedMessage.ciphertext, keyWA);
  const combinedWA = ivWA.concat(hmacWA).concat(encryptedMessage.ciphertext);
  const combinedBase64 = CryptoJS.enc.Base64.stringify(combinedWA);

  return combinedBase64;
}

export const encryptCipherJsonReact = (ciphertext) => {

  var key = 'a1b2c3d4e5f6g7h8';
  var ciphertext = CryptoJS.AES.encrypt(JSON.stringify(ciphertext), key).toString();
  return ciphertext;
}

export const decruptCipherJsonReact = (ciphertext) => {
  try {
    var key = 'a1b2c3d4e5f6g7h8';
    var bytes = CryptoJS.AES.decrypt(ciphertext, key);
    var decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
  } catch {
    window.location.href = '/logout';
  }
  return decryptedData;
}

export const decruptCipherString = (ciphertext) => {

  try {
    var key = 'a1b2c3d4e5f6g7h8';

    var ciphertextWA = CryptoJS.enc.Base64.parse(ciphertext);
    var keyWA = CryptoJS.enc.Utf8.parse(key);
    var ivWA = CryptoJS.lib.WordArray.create(ciphertextWA.words.slice(0, 4));
    var hmacWA = CryptoJS.lib.WordArray.create(ciphertextWA.words.slice(4, 4 + 8));
    var actualCiphertextWA = CryptoJS.lib.WordArray.create(ciphertextWA.words.slice(4 + 8));
    var hmacCalculatedWA = CryptoJS.HmacSHA256(actualCiphertextWA, keyWA);
    var decryptedMessageWA = CryptoJS.AES.decrypt({ ciphertext: actualCiphertextWA }, keyWA, { iv: ivWA });
    var decryptedMessage = CryptoJS.enc.Utf8.stringify(decryptedMessageWA);

  } catch {
    window.location.href = '/logout';
  }

  return decryptedMessage;
}

export const CustomFieldLevelLoader = () => {
  return (
    <img src="https://storage.googleapis.com/ksabupatest/2024/09/24/f0j83/menjruksxj.gif" style={{ width: '80px' }} alt="Loader..."></img>
  )
}

export const CustomLoader = () => {

  return (
    <div className="loading-overlay col-md-12" style={{ height: "100%", position: "fixed", width: "100%", display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column", background: "rgba(0, 0, 0, 0.7)" }}>
      <div className="loader-position">
        <Loader type="spinner-default" bgColor={"#fff"} color={'#FFFFFF'} size={100} />
        {/* <p style={{marginTop: '10px', color: '#fff'}}><b>Configuring...</b></p> */}
      </div>
    </div>
  )

}

export const SelectProgram = ({ programData, selectedProgram, onProgramChange }) => {
  return (
    <div className="row g-3 align-items-center">
      <div className="col-auto">
        <label htmlFor='SelectProgram' className="col-form-label">Select Program</label>
      </div>
      <div className="col">
        <select
          className="form-select input-field-ui"
          id="programId"
          name="programId"
          value={selectedProgram}
          onChange={onProgramChange}
          aria-label="Default select example"
        >
          <option>Select Role</option>
          {programData.map(option => (
            <option key={option.programId} value={option.programId}>
              {option.programName}
            </option>
          ))}
        </select>
      </div>
    </div>
  );
};


export const LoaderLoader = ({ isLoading }) => {
  if (!isLoading) return null;

  return (
    <div className="loading-overlay col-md-12" style={{ height: "100%", position: "fixed", width: "100%", display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column", background: "rgba(0, 0, 0, 0.7)" }}>
      <div className="loader-position">
        <Loader type="spinner-default" bgColor={"#fff"} color={'#FFFFFF'} size={100} />
        {/* <p style={{marginTop: '10px', color: '#fff'}}><b>Configuring...</b></p> */}
      </div>
    </div>
  );
};

export const LoaderLoaderConnect = ({ isLoading }) => {
  if (!isLoading) return null;

  return (
    <div className="loading-overlay col-md-12" style={{ height: "100%", position: "fixed", width: "100%", display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column", background: "rgba(0, 0, 0, 0.7)" }}>
      <div className="loader-position">
        <Loader type="spinner-default" bgColor={"#fff"} color={'#FFFFFF'} size={100} />
        <p style={{ marginTop: '10px', color: '#fff' }}><b>Configuring...</b></p>
      </div>
    </div>
  );
};

export const LoaderLoaderConnectNew = ({ isLoading }) => {
  if (!isLoading) return null;

  return (
    <div className="loading-overlay col-md-12" style={{ height: "14%", position: "fixed", width: "50%", display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column", background: "rgba(0, 0, 0, 0.0)" }}>
      <div className="loader-position text-center" style={{ left: "80%" }}>
        <Loader type="bubble-top" bgColor={"#03335b"} color={'#03335b'} size={40} />
        <p style={{ marginTop: '10px', color: '#03335b' }}><b>Configuring...</b></p>
      </div>
    </div>
  );
};

export const ModalBox = (props) => {

  const showModal = props.showModaln;
  const modalbodyComponent = props.bodyn;
  const title = props.titlen;
  const type = props.type;
  let cls = '';
  if (type == '' || typeof (type) == 'undefined') {
    cls = (title === 'Alert') ? 'modal-sm' : ((title === 'Alert!') ? 'modal-md' : 'modal-xl');
  } else {
    cls = (type === 'sm') ? 'modal-sm' : ((type === 'md') ? 'modal-md' : 'modal-xl');
  }

  return (
    <>
      <Modal dialogClassName={cls} show={showModal} onHide={props.handleClose}>
        <Modal.Header closeButton>
          <Modal.Title
            style={{
              width: "100%",
              textAlign: "center",
              fontWeight: "700",
              color: title === "Alert" ? "red" : "black"
            }}
          >
            {title}
          </Modal.Title>
        </Modal.Header>
        <hr />
        <Modal.Body >
          {modalbodyComponent}
        </Modal.Body>
      </Modal>
    </>
  );

}


export const ModalBoxButton = (props) => {

  const showModal = props.showModaln;
  const modalbodyComponent = props.bodyn;
  const title = props.titlen;
  const type = props.type;
  const buttonAction = props.button;
  const hideButton = props.hideButton;

  let cls = '';
  if (type == '' || typeof (type) == 'undefined') {
    cls = (title === 'Alert') ? 'modal-sm' : ((title === 'Alert!') ? 'modal-md' : 'modal-xl');
  } else if (type == 'modal-fullscreen') {
    cls = "modal-xl modal-fullscreen";
  } else {
    cls = (type === 'sm') ? 'modal-sm' : ((type === 'md') ? 'modal-md' : 'modal-xl');
  }

  return (
    <>
      <Modal dialogClassName={cls} show={showModal} className="modal-dialog-centered" centered id="MemberTransferModuleBox" onHide={props.handleClose} backdrop="static" keyboard={false}>
        <Modal.Header closeButton={!!hideButton}>
          <Modal.Title
            style={{
              width: "90%",
              color: title === "Alert" ? "red" : "black"
            }}
          >
            {title}
          </Modal.Title>
        </Modal.Header>
        <hr />
        <Modal.Body >
          <div className="row">
            <div className="col-md-12 text-center">
              <h3 className="main-subtitle "> {modalbodyComponent}</h3>
            </div>
            <div className="col-md-12 my-3 text-center">
              {buttonAction && <button className='btn program-save-btn me-2' onClick={buttonAction}>OK</button>}
            </div>
          </div>

        </Modal.Body>
      </Modal>
    </>
  );

}


export const ModalBoxYesNo = (props) => {

  const showModal = props.showModaln;
  const modalbodyComponent = props.bodyn;
  const title = props.titlen;
  const type = props.type;
  const buttonYes = props.buttonYes;
  const buttonNo = props.buttonNo;


  let cls = '';
  if (type == '' || typeof (type) == 'undefined') {
    cls = (title === 'Alert') ? 'modal-sm' : ((title === 'Alert!') ? 'modal-md' : 'modal-xl');
  } else {
    cls = (type === 'sm') ? 'modal-sm' : ((type === 'md') ? 'modal-md' : 'modal-xl');
  }

  return (
    <>
      <Modal dialogClassName={cls} id="commonModulePop" centered show={showModal} onHide={props.handleClose} backdrop="static" keyboard={false}>
        <Modal.Header closeButton>
          <Modal.Title
            style={{
              // textAlign: "center",
              color: title === "Alert" ? "#FF0000" : "#404040"
            }}
          >
            {title}
          </Modal.Title>
        </Modal.Header>

        <Modal.Body >
          <div className="row">
            <div className="col-12 mb-3">
              <p>{modalbodyComponent}</p>
            </div>
            <div className="col-12 text-center">
              {buttonYes && <button className='btn program-save-btn me-2' onClick={buttonYes}>Yes</button>}
              {buttonNo && <button className='btn program-save-btn' onClick={buttonNo}>No</button>}
            </div>
          </div>


        </Modal.Body>
      </Modal>
    </>
  );

}

export const formatDate = (dateString) => {
  const date = new Date(dateString);
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');
  return `${year}-${month}-${day}`;
}

export const Commingsoon = () => {

  return (
    <div className="modal fade" id="upcomingFeatureModal" tabindex="1" aria-labelledby="upcomingFeatureModalLabel" aria-hidden="true">
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-header mb-4">
            <h4 className="modal-subtitle">Available in Phase 2!</h4>
            <h1 className="modal-title mt-3">Upcoming Feature:</h1>
            <img src="https://storage.googleapis.com/ksabupatest/2024/09/24/te82r/xsyel27471.png" style={{ height: "260px" }} alt="" />
            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
        </div>
      </div>
    </div>
  );

};
export const DashboardAccess = () => {
  return (
    <div className="row tw-bg-white tw-py-5 tw-px-20 tw-rounded-xl tw-shadow-lg">
      <div className="col-md-12 text-center">
        <img
          src="https://storage.googleapis.com/ksabupatest/2024/09/24/yurhg/4140jj6978.png"
          alt=""
          style={{ height: "360px" }}
        />
        <h1
          className="login-subtitle my-3 tw-text-lg"
          style={{ fontWeight: "400" }}
        >
          It seems like you're on a secret mission without the right access.
          <br /> Better call your system administrator to unlock the doors!
        </h1>
      </div>
    </div>
  );
};

export const CommingsoonPage = () => {

  return (
    <div className="modal-dialog modal-dialog-centered">
      <div className="modal-content">
        <div className="modal-header mb-4">
          <h4 className="modal-subtitle">Available in Phase 2!</h4>
          <h1 className="modal-title mt-3">Upcoming Feature:</h1>
          <img src="https://storage.googleapis.com/ksabupatest/2024/09/24/te82r/xsyel27471.png" style={{ height: "260px" }} alt="" />
        </div>
      </div>
    </div>
  );
};


export const PathwayDots = ({ pathway, tab, onCallBackFromChild, ifCheck, isApproved, isPublish }) => {

  const [showDeletePathwayModal, setShowDeletePathwayModal] = useState(false);
  const [showEditPathwayModal, setShowEditPathwayModal] = useState(false);
  const [publishPathwayModal, setPublishPathwayModal] = useState(false);
  const [passPathwayId, setPassPathwayId] = useState('');
  const [showPopup, setShowPopup] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  //Call back for refresh page
  const sendRefreshToParent = (tab) => {
    onCallBackFromChild(tab);
  }

  const [formData, setFormData] = useState({
    pathway: pathway,
    pathwayName: '',
  })

  const [errors, setErrors] = useState({
    pathway: pathway,
    pathwayName: '',

  })

  const handleClose = () => {

    setFormData({
      pathway: pathway,
      pathwayName: '',
    });
    setErrors({
      pathway: pathway,
      pathwayName: '',
    })
    setShowDeletePathwayModal(false);
    setShowEditPathwayModal(false);
    setPublishPathwayModal(false);
    setShowPopup(false);
  }

  //onchange
  const onValueChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  }

  const handleDeleteNewPathway = () => {
    setShowDeletePathwayModal(true);
  }

  const handleEditNewPathway = () => {
    editFetchNewPathway(pathway)
      .then((response) => {
        console.log("des", response)
        setFormData(response.data.data);
      });
    setShowEditPathwayModal(true);
  }

  //submit edit pathway
  const submitEditNewPathway = async (e) => {
    e.preventDefault();
    setErrors('')
    const validationErrors = validateFormPathwayAdd(formData);
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
    } else {
      editNewPathway(formData)
        .then((response) => {
          if (response.data.code == 200) {
            setShowEditPathwayModal(false);
            if (tab === 1) {
              sendRefreshToParent(0);
            } else {
              sendRefreshToParent(1);
            }
          }
        });
    }
  }

  //submit delete program
  const submitDeleteNewPathway = async (e) => {
    e.preventDefault();
    deleteNewPathway(pathway)
      .then((response) => {
        if (response.data.code == 200) {
          setShowDeletePathwayModal(false);
          sendRefreshToParent(1);
        }
      });
  }

  //Handle public pathway
  const handlePublishNewPathway = (pathwayId) => {
    setPassPathwayId(pathwayId)
    displayMessagePathway(pathwayId)
      .then((response) => {
        if (response.data.code == 200) {
          setShowPopup(true);
          setErrorMessage(response.data.data.message);
        } else {
          setShowPopup(false);
          setErrorMessage("Error Message");
          setPublishPathwayModal(true);
        }
      }).catch((error) => {
        // Handle any error that occurs during the API call
        console.error("Error during publishing condition:", error);
      });

  }

  const submitPublishNewPathway = () => {
    publishNewPathway(passPathwayId)
      .then((response) => {
        if (response.data.code === 200) {
          setPublishPathwayModal(false);
          sendRefreshToParent(0);
          setErrorMessage("");
        } else {
          console.log("success", response);
          setErrorMessage(response.data.data.message)
        }
      }).catch((error) => {
        // Handle any error that occurs during the API call
        console.error("Error during publishing pathway:", error);
      });
  }
  return (
    <>
      <div className="DropDownAction">
        <div className="btn-group dropend" style={{ position: 'relative', zIndex: 0 }}>
          <button
            type="button"
            className="btn btn-light dropdown-toggle action-dropdown-btn"
            data-bs-toggle="dropdown"
            aria-expanded="false"
            aria-label="Open dropdown menu"
          >
            <i className="bi bi-three-dots-vertical"></i>
          </button>
          <ul className="dropdown-menu" style={{ zIndex: 2 }}>
            {ifCheck === 1 && isApproved === 'N' ? (
              <>
                <li className="list-class">
                  <Link
                    to=""
                    className="dropdown-item"
                    onClick={(e) => handlePublishNewPathway(pathway)}
                    aria-label="Publish Pathway"
                    title="Publish Pathway"
                  >
                    <i className="bi bi-display me-2"></i>
                    Publish Pathway
                  </Link>
                </li>
                <li className="list-class">
                  <Link
                    to=""
                    className="dropdown-item"
                    onClick={(e) => handleEditNewPathway(pathway)}
                    aria-label="Edit Pathway"
                    title="Rename Pathway"
                  >
                    <i className="bi bi-pencil-square me-2"></i>
                    Rename Pathway
                  </Link>
                </li>
                <li className="list-class">
                  <Link
                    to={`/add-service/${pathway}`}
                    className="dropdown-item"
                    aria-label="Edit Pathway Service"
                    title="Edit Pathway Service"
                  >
                    <i className="bi bi-pencil me-2"></i>
                    Edit Pathway Services
                  </Link>
                </li>
                <li className="list-class">
                  <Link
                    to=""
                    className="dropdown-item"
                    onClick={() => handleDeleteNewPathway()}
                    aria-label="Delete Pathway"
                    title="Delete Pathway"
                  >
                    <i className="bi bi-x-circle text-danger me-2"></i>
                    Delete Pathway
                  </Link>
                </li>
              </>
            ) : (
              <>
                <li className="list-class">
                  <Link
                    to=""
                    className="dropdown-item"
                    onClick={(e) => handleEditNewPathway(pathway)}
                    aria-label="Edit Pathway"
                    title="Rename Pathway"
                  >
                    <i className="bi bi-pencil-square me-2"></i>
                    Rename Pathway
                  </Link>
                </li>
                <li className="list-class">
                  <Link
                    to={`/add-service/${pathway}`}
                    className="dropdown-item"
                    aria-label="Edit Pathway Service"
                    title="Edit Pathway Service"
                  >
                    <i className="bi bi-pencil me-2"></i>
                    Edit Pathway Services
                  </Link>
                </li>
              </>
            )}
          </ul>
        </div>

        <style jsx>{`
                .DropDownAction {
                    position: relative;
                    z-index: 1;
                }

                /* Control z-index for dropdown elements */
                .dropdown-menu {
                    z-index: 1 !important;
                }

                .dropdown-menu.show {
                    z-index: 1 !important;
                    display: block;
                }

                .btn-group {
                    z-index: 1 !important;
                }

                /* Style improvements */
                .list-class .dropdown-item {
                    display: flex;
                    align-items: center;
                    padding: 0.5rem 1rem;
                }

                .list-class .dropdown-item i {
                    margin-right: 0.5rem;
                    width: 1rem;
                    text-align: center;
                }

                .dropdown-item:hover {
                    background-color: #f8f9fa;
                }

                /* Button styling */
                .action-dropdown-btn {
                    padding: 0.375rem 0.75rem;
                    background-color: transparent;
                    border: 1px solid #dee2e6;
                }

                .action-dropdown-btn:hover {
                    background-color: #f8f9fa;
                }

                /* Reset Bootstrap's default z-index */
                .dropend .dropdown-menu {
                    z-index: 1 !important;
                    margin-left: 0.125rem;
                }
            `}</style>
      </div>
      <Commingsoon />

      <div className={`modal fade ${showEditPathwayModal ? 'show' : ''}`} data-bs-backdrop="static" id="showEditPathwayModalLabel" tabIndex="-1" aria-labelledby="showEditPathwayModalLabel" aria-hidden={!showEditPathwayModal} style={{ display: showEditPathwayModal ? 'block' : 'none' }}>
        <div className="modal-dialog modal-dialog-centered modal-md">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="main-title">Rename Pathway</h4>
              <button
                type="button"
                className="btn-close"
                onClick={handleClose}
              ></button>
            </div>
            <div class="modal-body pt-1 mb-2">
              <form className='' onSubmit={submitEditNewPathway}>
                <div className="row">
                  <div className="col-md-12 mb-3">
                    <label htmlFor='pathwayName' class="form-label">Pathway Name</label>
                    <span style={{ color: 'red' }}> *</span>
                    <input type="text"
                      className="form-control input-field-ui"
                      placeholder="Enter pathway name"
                      name="pathwayName"
                      onChange={(e) => onValueChange(e)} value={formData['pathwayName']}
                    />
                    {errors['pathwayName'] && <div className="text-danger">{errors['pathwayName']}</div>}
                  </div>

                  <div className="col-md-12 my-2 text-center">
                    <button
                      type="submit"
                      className="btn btn-primary program-save-btn me-2"
                    >
                      Save
                    </button>
                  </div>
                </div>
              </form>
            </div>

          </div>
        </div>
        <div className="modal-backdrop fade show" style={{ zIndex: 1040 }}></div>
      </div>

      <div className={`modal fade ${showDeletePathwayModal ? 'show' : ''}`} data-bs-backdrop="static" id="showDeletePathwayModalLabel" tabIndex="-1" aria-labelledby="showDeletePathwayModalLabel" aria-hidden={!showDeletePathwayModal} style={{ display: showDeletePathwayModal ? 'block' : 'none' }}>
        <div className="modal-dialog modal-dialog-centered modal-md">
          <div className="modal-content">
            <div className="modal-header">
              <button
                type="button"
                className="btn-close"
                onClick={handleClose}
              ></button>
            </div>
            <div class="modal-body pt-1 mb-2">
              <form className='' onSubmit={submitDeleteNewPathway}>
                <div className="row">


                  <div className="col-md-12 my-2 text-center">
                    <div className="modal-body pt-1 mb-2 mx-auto w-80">
                      Are you sure you want to delete it?</div>
                    <button
                      type="submit"
                      className="btn btn-primary program-save-btn me-2"
                    >
                      Confirm
                    </button>
                  </div>
                </div>
              </form>
            </div>

          </div>
        </div>
        <div className="modal-backdrop fade show" style={{ zIndex: 1040 }}></div>
      </div>

      <div className={`modal fade ${publishPathwayModal ? 'show' : ''}`} data-bs-backdrop="static" id="publishPathwayModalLabel" tabIndex="-1" aria-labelledby="publishPathwayModalLabel" aria-hidden={!publishPathwayModal} style={{ display: publishPathwayModal ? 'block' : 'none' }}>
        <div className="modal-dialog modal-dialog-centered modal-md">
          <div className="modal-content">
            <div className="modal-header">
              {/* <p className="main-title">Are you sure want to publish pathway ?</p> */}
              <button
                type="button"
                className="btn-close"
                onClick={handleClose}
              ></button>
            </div>
            <div class="modal-body pt-1 mb-2">

              <div className="row">


                <div className="col-md-12 my-2 text-center">
                  <div className="modal-body pt-1 mb-2 mx-auto w-80" style={{ color: '#000000' }}>Are you sure you want to publish the pathway?</div>
                  <button
                    type="button"
                    className="btn btn-primary program-save-btn me-2"
                    onClick={submitPublishNewPathway}
                  >
                    Confirm
                  </button>
                </div>
              </div>

            </div>

          </div>

        </div>
        <div className="modal-backdrop fade show" style={{ zIndex: 1040 }}></div>
      </div>
      <DisplayMessage showPopup={showPopup}
        message={errorMessage}
        onClose={handleClose} />
    </>
  );
}

export const ProgramDots = ({ programId, tab, onCallBackFromChild, ifCheck, isApproved, isPublish }) => {

  // add program
  const [showEditProgramModal, setShowEditProgramModal] = useState(false);
  const [showDeleteProgramModal, setShowDeleteProgramModal] = useState(false);
  const [showAddConditionModal, setShowAddConditionModal] = useState(false);
  const [publishProgramModal, setPublishProgramModal] = useState(false);
  const [passProgramId, setPassProgramId] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);



  const handleClose = () => {
    setFormData({
      programId: programId,
      programName: '',
      programDescription: '',
      descriptionForMember: '',
      conditionName: '',
      conditionDescription: '',
    });
    setErrors({
      programId: programId,
      programName: '',
      programDescription: '',
      descriptionForMember: '',
    })
    setShowEditProgramModal(false);
    setShowDeleteProgramModal(false);
    setShowAddConditionModal(false);
    setPublishProgramModal(false);
    setErrorMessage('');


  }

  const [formData, setFormData] = useState({
    programId: programId,
    programName: '',
    programDescription: '',
    descriptionForMember: '',
    conditionName: '',
    conditionDescription: '',

  })

  const [errors, setErrors] = useState({
    programId: programId,
    programName: '',
    programDescription: '',
    descriptionForMember: '',

  })


  //Call back for refresh page
  const sendRefreshToParent = (tab) => {
    onCallBackFromChild(tab);
  }

  //open Edit program
  const handleEditNewProgram = (programId) => {
    editFetchNewProgram(programId)
      .then((response) => {
        console.log("des", response.data.data)
        setFormData(response.data.data);
      });
    setShowEditProgramModal(true);
  }
  //delete program
  const handleDeleteNewProgram = () => {
    setShowDeleteProgramModal(true);
  }
  //close delete popup
  const handleDeleteClose = () => {
    setShowDeleteProgramModal(false);
  }

  //onchange
  const onValueChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  }

  //submit edit program
  const submitEditNewProgram = async (e) => {
    e.preventDefault();
    setErrors('')
    const validationErrors = validateFormNewProgramAdd(formData);
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
    } else {
      editNewProgram(formData)
        .then((response) => {
          if (response.data.code == 200) {
            if (tab === 1) {
              sendRefreshToParent(0);
            } else {
              sendRefreshToParent(1);
            }
            setShowEditProgramModal(false);
          } else {
            setErrorMessage(response.data.data.message);
          }
        });
    }
  }

  //submit delete program
  const submitDeleteNewProgram = async (e) => {
    e.preventDefault();
    setErrors('');
    console.log("changes", programId);
    removeNewProgram(programId)
      .then((response) => {
        if (response.data.code == 200) {
          setShowDeleteProgramModal(false);
          sendRefreshToParent(1);
        }
      });
  }


  //Add new condition popup
  const handleAddCondition = () => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      programId: programId,  // replace `newProgramId` with the actual ID you want to set
    }));
    setShowAddConditionModal(true);
  }

  //submit new condition
  const submitAddNewCondition = async (e) => {
    e.preventDefault();
    setErrors('');
    setIsLoading(true);
    const validationErrors = validateFormNewConditionAdd(formData);
    if (Object.keys(validationErrors).length > 0) {
      setIsLoading(false);
      setErrors(validationErrors);
    } else {
      AddNewCondition(formData)
        .then((response) => {
          if (response.data.code == 200) {
            if (tab === 1) {
              sendRefreshToParent(1);
            } else {
              sendRefreshToParent(1);
            }
            setShowAddConditionModal(false);
            handleClose();

          } else {
            setErrorMessage(response.data.data.message);
          }
          setIsLoading(false);
        });
    }
  }

  //handle publish program
  const handlePublishNewProgram = (programId) => {
    setPassProgramId(programId)
    setPublishProgramModal(true);
  }

  const submitPublishNewProgram = () => {
    publishNewProgram(passProgramId)
      .then((response) => {
        if (response.data.code == 200) {
          sendRefreshToParent(0);
          setPublishProgramModal(false);
        } else {
          sendRefreshToParent(0);
          setPublishProgramModal(false);
        }
      });
  }


  return (
    <>
      <div className="DropDownAction">
        <div className="btn-group dropend" style={{ position: 'relative', zIndex: 1 }}>
          <button
            type="button"
            className="btn btn-light dropdown-toggle action-dropdown-btn"
            data-bs-toggle="dropdown"
            aria-expanded="false"
            aria-label="Open dropdown menu"
          >
            <i className="bi bi-three-dots-vertical"></i>
          </button>
          <ul className="dropdown-menu" style={{ zIndex: 1 }}>
            {ifCheck === 1 && isApproved === 'N' ? (
              <>
                <li className="list-class">
                  <Link
                    to=""
                    className="dropdown-item"
                    onClick={(e) => handlePublishNewProgram(programId)}
                    aria-label="Publish Program"
                    title="Publish Program"
                  >
                    <i className="bi bi-display me-2"></i>
                    Publish Program
                  </Link>
                </li>
                <li className="list-class">
                  <Link
                    to=""
                    className="dropdown-item"
                    onClick={() => handleAddCondition()}
                    aria-label="Add Condition"
                    title="Add Condition"
                  >
                    <i className="bi bi-plus-circle me-2"></i>
                    Add Condition
                  </Link>
                </li>
                <li className="list-class">
                  <Link
                    to=""
                    className="dropdown-item"
                    onClick={(e) => handleEditNewProgram(programId)}
                    aria-label="Edit Program"
                    title="Edit Program"
                  >
                    <i className="bi-pencil me-2"></i>
                    Edit Program
                  </Link>
                </li>
                <li className="list-class">
                  <Link
                    to=""
                    className="dropdown-item"
                    onClick={() => handleDeleteNewProgram()}
                    aria-label="Delete Program"
                    title="Delete Program"
                  >
                    <i className="bi bi-x-circle text-danger me-2"></i>
                    Delete Program
                  </Link>
                </li>
              </>
            ) : (
              <>
                <li className="list-class">
                  <Link
                    to=""
                    className="dropdown-item"
                    onClick={() => handleAddCondition()}
                    aria-label="Add Condition"
                    title="Add Condition"
                  >
                    <i className="bi bi-plus-circle me-2"></i>
                    Add Condition
                  </Link>
                </li>
                <li className="list-class">
                  <Link
                    to=""
                    className="dropdown-item"
                    onClick={(e) => handleEditNewProgram(programId)}
                    aria-label="Edit Program"
                    title="Edit Program"
                  >
                    <i className="bi-pencil me-2"></i>
                    Edit Program
                  </Link>
                </li>
              </>
            )}
          </ul>
        </div>

        <style jsx>{`
                .DropDownAction {
                    position: relative;
                    z-index: 1;
                }

                /* Control z-index for dropdown elements */
                .dropdown-menu {
                    z-index: 1 !important;
                }

                .dropdown-menu.show {
                    z-index: 1 !important;
                    display: block;
                }

                .btn-group {
                    z-index: 1 !important;
                }

                /* Style improvements */
                .list-class .dropdown-item {
                    display: flex;
                    align-items: center;
                    padding: 0.5rem 1rem;
                }

                .list-class .dropdown-item i {
                    margin-right: 0.5rem;
                    width: 1rem;
                    text-align: center;
                }

                .dropdown-item:hover {
                    background-color: #f8f9fa;
                }

                /* Button styling */
                .action-dropdown-btn {
                    padding: 0.375rem 0.75rem;
                    background-color: transparent;
                    border: 1px solid #dee2e6;
                }

                .action-dropdown-btn:hover {
                    background-color: #f8f9fa;
                }

                /* Reset Bootstrap's default z-index */
                .dropend .dropdown-menu {
                    z-index: 1 !important;
                    margin-left: 0.125rem;
                }
            `}</style>
      </div>
      <Commingsoon />

      <div className={`modal fade ${showEditProgramModal ? 'show' : ''}`} data-bs-backdrop="static" id="showEditProgramModalLabel" tabIndex="-1" aria-labelledby="showEditProgramModalLabel" aria-hidden={!showEditProgramModal} style={{ display: showEditProgramModal ? 'block' : 'none' }}>
        <div className="modal-dialog modal-dialog-centered modal-md">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="main-title">Edit Program</h4>
              <button
                type="button"
                className="btn-close"
                onClick={handleClose}
              ></button>
            </div>
            {errorMessage ? (<p style={{ color: 'red', textAlign: 'center' }}>{errorMessage}</p>) : null}
            <div class="modal-body pt-1 mb-2">
              <form className='' onSubmit={submitEditNewProgram}>
                <div className="row">
                  <div className="col-md-12 mb-3">
                    <label htmlFor='Condition' class="form-label">Program Name</label>
                    <span style={{ color: 'red' }}> *</span>
                    <input type="text"
                      className="form-control input-field-ui"
                      placeholder="Enter program name"
                      name="programName"
                      onChange={(e) => onValueChange(e)} value={formData['programName']}
                    />
                    {errors['programName'] && <div className="text-danger">{errors['programName']}</div>}
                  </div>
                  <div className="col-md-12 mb-3">
                    <label htmlFor='programDescription' class="form-label">Description</label>
                    <span style={{ color: 'red' }}> *</span>
                    <textarea
                      className="form-control input-field-ui"
                      placeholder="Enter program description"
                      rows="3"
                      name="programDescription"
                      onChange={(e) => onValueChange(e)} value={formData['programDescription']}
                    />
                    {errors['programDescription'] && <div className="text-danger">{errors['programDescription']}</div>}
                  </div>

                  <div className="col-md-12 my-2 text-center">
                    <button
                      type="submit"
                      className="btn btn-primary program-save-btn me-2"
                    >
                      Save
                    </button>
                  </div>
                </div>
              </form>
            </div>

          </div>
        </div>
        <div className="modal-backdrop fade show" style={{ zIndex: 1040 }}></div>
      </div>


      <div className={`modal fade ${showAddConditionModal ? 'show' : ''}`} data-bs-backdrop="static" id="showAddConditionModalLabel" tabIndex="-1" aria-labelledby="showAddConditionModalLabel" aria-hidden={!showAddConditionModal} style={{ display: showAddConditionModal ? 'block' : 'none' }}>
        <LoaderLoader isLoading={isLoading} />
        <div className="modal-dialog modal-dialog-centered modal-md">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="main-title">Add New Condition</h4>
              <button
                type="button"
                className="btn-close"
                onClick={handleClose}
              ></button>
            </div>
            {errorMessage ? (<p style={{ color: 'red', textAlign: 'center' }}>{errorMessage}</p>) : null}
            <div class="modal-body pt-1 mb-2">
              <form className='' onSubmit={submitAddNewCondition}>
                <div className="row">
                  <div className="col-md-12 mb-3">
                    <label htmlFor='Condition' class="form-label">Condition Name</label>
                    <span style={{ color: 'red' }}> *</span>
                    <input type="text"
                      className="form-control input-field-ui"
                      placeholder="Enter condition name"
                      name="conditionName"
                      onChange={(e) => onValueChange(e)} value={formData['conditionName']}
                    />
                    {errors['conditionName'] && <div className="text-danger">{errors['conditionName']}</div>}
                  </div>
                  <div className="col-md-12 mb-3">
                    <label htmlFor='conditionDescription' class="form-label">Description</label>
                    <span style={{ color: 'red' }}> *</span>
                    <textarea
                      className="form-control input-field-ui"
                      placeholder="Enter condition description"
                      rows="3"
                      name="conditionDescription"
                      onChange={(e) => onValueChange(e)} value={formData['conditionDescription']}
                    />
                    {errors['conditionDescription'] && <div className="text-danger">{errors['conditionDescription']}</div>}
                  </div>
                  <div className="col-md-12 my-2 text-center">
                    <button
                      type="submit"
                      className="btn btn-primary program-save-btn me-2"
                    >
                      Save
                    </button>
                  </div>
                </div>
              </form>
            </div>

          </div>
        </div>
        <div className="modal-backdrop fade show" style={{ zIndex: 999999 }}></div>
      </div>

      <div className={`modal fade ${showDeleteProgramModal ? 'show' : ''}`} data-bs-backdrop="static" id="showDeleteProgramModalLabel" tabIndex="-1" aria-labelledby="showDeleteProgramModalLabel" aria-hidden={!showDeleteProgramModal} style={{ display: showDeleteProgramModal ? 'block' : 'none' }}>
        <div className="modal-dialog modal-dialog-centered modal-md">
          <div className="modal-content">
            <div className="modal-header">
              {/* <p className="main-title">Are you sure want to delete this ?</p> */}
              <button
                type="button"
                className="btn-close"
                onClick={handleDeleteClose}
              ></button>
            </div>
            <div class="modal-body pt-1 mb-2">
              <form className='' onSubmit={submitDeleteNewProgram}>
                <div className="row">


                  <div className="col-md-12 my-2 text-center">
                    <div className="modal-body pt-1 mb-2 mx-auto w-80">
                      Are you sure you want to delete it?</div>
                    <button
                      type="submit"
                      className="btn btn-primary program-save-btn me-2"
                    >
                      Confirm
                    </button>
                  </div>
                </div>
              </form>
            </div>

          </div>
        </div>
        <div className="modal-backdrop fade show" style={{ zIndex: 1040 }}></div>
      </div>

      <div className={`modal fade ${publishProgramModal ? 'show' : ''}`} data-bs-backdrop="static" id="publishProgramModalLabel" tabIndex="-1" aria-labelledby="publishProgramModalLabel" aria-hidden={!publishProgramModal} style={{ display: publishProgramModal ? 'block' : 'none' }}>
        <div className="modal-dialog modal-dialog-centered modal-md">
          <div className="modal-content">
            <div className="modal-header">
              {/* <p className="main-title">Are you sure want to publish program ?</p> */}
              <button
                type="button"
                className="btn-close"
                onClick={handleClose}
              ></button>
            </div>
            <div class="modal-body pt-1 mb-2">
              <div className="row">


                <div className="col-md-12 my-2 text-center">
                  <div className="modal-body pt-1 mb-2 mx-auto w-80" style={{ color: '#000000' }}>
                    Are you sure you want to publish the program?</div>
                  <button
                    type="button"
                    className="btn btn-primary program-save-btn me-2"
                    onClick={submitPublishNewProgram}
                  >
                    Confirm
                  </button>
                </div>
              </div>

            </div>

          </div>
        </div>
        <div className="modal-backdrop fade show" style={{ zIndex: 1040 }}></div>
      </div>

    </>
  );
}



export const exportTableData = (csvName, modifiedData) => {
  try {
    const current = new Date();
    const date = `${current.getMonth() + 1}-${current.getDate()}-${current.getFullYear()},${current.getHours()}-${current.getMinutes()}-${current.getSeconds()}`;
    var wb = XLSX.utils.book_new();

    var ws = XLSX.utils.json_to_sheet(modifiedData);

    XLSX.utils.book_append_sheet(wb, ws, `${csvName}`);

    XLSX.writeFile(wb, `${csvName} ${date}.xlsx`);
  } catch (error) {
    console.error("Exception Error CSV Download = ", error);
    return false;
  }
};

export const exportExcelTableData = (excelName, modifiedData) => {
  try {
    const current = new Date();
    const date = `${current.getDate()}/${current.getMonth() + 1}/${current.getFullYear()}, ${current.getHours()}:${current.getMinutes()}:${current.getSeconds()}`;
    var wb = XLSX.utils.book_new();

    var ws = XLSX.utils.json_to_sheet(modifiedData);

    XLSX.utils.book_append_sheet(wb, ws, `${excelName}`);

    XLSX.writeFile(wb, `${excelName} ${date}.xls`);
  } catch (error) {
    console.error("Exception Error XLS Download = ", error);
    return false;
  }
};


export const exportPdfTableData = async (pdfName, tableData, columns) => {
  try {
    const date = new Date();
    const dateString = `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}, ${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}`;

    const pdf = new jsPDF();

    // Add table to PDF
    pdf.autoTable({
      head: [columns],
      body: tableData,
      theme: 'striped',
      startY: 10,
      styles: {
        fontSize: 8,
        cellPadding: 2,
      },

    });

    // Save the PDF with the name and timestamp
    pdf.save(`${pdfName} ${dateString}.pdf`);
    return true;
  } catch (error) {
    console.error("Exception Error PDF Download = ", error);
    return false;
  }
};

export const RenderStringWithBreaks = ({ inputString }) => {

  if (inputString == "" || inputString == null) {
    return;
  }
  const sanitizedString = DOMPurify.sanitize(inputString.replace(/\n/g, '<br>'));

  return (
    <div
      dangerouslySetInnerHTML={{
        __html: sanitizedString,
      }}
    ></div>
  );
};