import React, { useEffect, useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import CalendarDatePickerPage from "../CalendarDatePickerPage";
import moment from "moment";
import { fetchUntouchRemove, removeUntouchMembers, getFileLink } from "../../../services/rcm/Service";
import { MultiSelect } from "react-multi-select-component";
import { Link } from "react-router-dom";
import { CustomLoader } from "../../utils/CommonLibrary";
import DatePicker from "react-datepicker";

function RemoveUntouchMembers() {

  const today = moment();
  const sevenDaysAgo = moment().subtract(6, "days");

  const [startDate, setStartDate] = useState(sevenDaysAgo);
  const [endDate, setEndDate] = useState(today);
  const [showAndOr, setShowAndOr] = useState(true);
  const [errorMsg, setErrorMsg] = useState('');
  const [successMsg, setSuccessMsg] = useState('');
  const [fileLink, setFileLink] = useState('');
  const [memberCount, setMemberCount] = useState('');
  const [removeId, setRemoveId] = useState('');
  const [loading, setLoading] = useState(false);
  const [showRemove, setShowRemove] = useState(false);
  const [remarks, setRemarks] = useState('');
  const [remarkError, setRemarkError] = useState('');
  const [showRemoveBlock, setShowRemoveBlock] = useState('');
  const [programs, setPrograms] = useState([]);

  useEffect(() => {
    fetchConditionList();
  }, []);

  const fetchConditionList = () => {
    getFileLink()
      .then((res) => {
        setPrograms(res.data.data.conditionsData);
      }
      );
  }

  function formatDate(dateString) {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  }

  const handleDateChange = ({ start, end, index }) => {
    setStartDate(start);
    setEndDate(end);
    const newQueries = [...queries];
    newQueries[index]['value'] = formatDate(start) + "," + formatDate(end);
    newQueries[index]['startDate'] = start;
    newQueries[index]['endDate'] = end;
    setQueries(newQueries);
  };

  const handleDateChangeSingle = (start, index) => {
    setStartDate(moment(start));
    setEndDate(moment(start));

    const newQueries = [...queries];
    newQueries[index]['value'] = formatDate(moment(start));
    newQueries[index]['startDate'] = moment(start);
    newQueries[index]['endDate'] = moment(start);
    setQueries(newQueries);
  };

  const [queries, setQueries] = useState([
    { field: "m.createdTime", operator: "between", value: formatDate(sevenDaysAgo) + "," + formatDate(today), condition: null, startDate: sevenDaysAgo, endDate: today, type: "date" },
  ]);


  const fields = [
    { display: "Approved Date", value: "m.createdTime", type: "date" },
    { display: "Chronic Conditions", value: "mpcr.conditionId", type: "select" },
    { display: "Member Network", value: "me.memberNetwork", type: "text" },
    { display: "Policy End Date", value: "m.policyEndDate", type: "date" },
  ];


  const operators = [
    { display: "Equals", value: "=", type: "date" },
    { display: "Greater than", value: ">", type: "date" },
    { display: "Less than", value: "<", type: "date" },
    { display: "Between", value: "between", type: "date" },
    { display: "Contains", value: "like", type: "text" },
  ];

  const allOperators = {
    text: [
      { value: "=", display: "Equals" },
      { value: "like", display: "Contains" },
    ],
    select: [
      { value: "=", display: "Equals" },
      { value: "like", display: "Contains" },
    ],
    date: [
      { value: "between", display: "Between" },
      { value: "=", display: "Equals" },
      { value: ">", display: "Greater than" },
      { value: "<", display: "Less than" },
    ],
  };

  const getOperatorsByType = (type) => {
    const operatorMap = {
      "text": [
        { value: "=", display: "Equals" },
        { value: "contains", display: "Contains" },
      ],
      "select": [
        { value: "=", display: "Equals" },
        { value: "like", display: "Contains" },
      ],
      "date": [
        { value: "between", display: "Between" },
        { value: "=", display: "Equals" },
        { value: ">", display: "Greater than" },
        { value: "<", display: "Less than" },
      ],
    };

    return operatorMap[type] || [];
  };


  const handleAddQuery = (condition) => {
    setShowRemoveBlock(false);
    const newQueries = [...queries];

    newQueries[newQueries.length - 1].condition = condition;
    const selectedFields = newQueries.map(query => query.field);

    // const availableField = fields.find(field => !selectedFields.includes(field.value))?.value || fields[0].value;
    // const availableFieldObject = fields.find(field => field.value === availableField);

    const remainingFieldsCount = fields.filter(field => !selectedFields.includes(field.value)).length;
    if (remainingFieldsCount <= 1) {
      setShowAndOr(false);
    }

    let valueFinal = "";

    // if (availableFieldObject?.type === 'date') {
    //   valueFinal = `${formatDate(sevenDaysAgo)},${formatDate(today)}`;
    // }

    newQueries.push({
      field: "m.createdTime",
      operator: 'between', //operators[0].value
      value: `${formatDate(sevenDaysAgo)},${formatDate(today)}`,
      condition: null,
      startDate: sevenDaysAgo,
      endDate: today,
      type: "date",
    });

    setQueries(newQueries);
  };




  const fetchRemoveData = async () => {
    setLoading(true);
    setErrorMsg('');
    setShowRemoveBlock(false);
    try {
      fetchUntouchRemove(JSON.stringify({ queries }))
        .then((response) => {
          if (response.data.code == 200) {
            setFileLink(response.data.data.fileLink);
            setMemberCount(response.data.data.memberCount);
            setRemoveId(response.data.data.removeId);
            setShowRemove(true);
            setShowRemoveBlock(true);
          } else {
            setErrorMsg(response.data.data.msg);
          }
          setLoading(false);
        });
    } catch (error) {
      console.error('Error=', error);
    }
  }

  const RemoveMembers = async () => {
    if (remarks == '') {
      setRemarkError("Enter Remarks");
      return false;
    }
    setLoading(true);
    try {
      removeUntouchMembers(removeId, remarks)
        .then((response) => {
          if (response.data.code == 200) {
            setSuccessMsg(response.data.data.msg);
            setShowRemove(false);
            setTimeout(() => {
              setSuccessMsg(false);
            }, 3000);
          } else {
            setErrorMsg(response.data.data.msg);
          }
          setLoading(false);
        });
    } catch (error) {
      console.error('Error=', error);
    }
  }

  const handleRemoveQuery = (index) => {
    const newQueries = queries.filter((_, i) => i !== index);
    setQueries(newQueries);
    setShowAndOr(true);
  };

  const handleQueryChange = (index, key, value, fieldType) => {
    setErrorMsg('');
    const newQueries = [...queries];
    if (key === "field" && (fieldType === "select" || fieldType === "text")) {
      newQueries[index].value = "";
    }

    if (key === "field" && fieldType === "date") {
      newQueries[index].value = `${formatDate(sevenDaysAgo)},${formatDate(today)}`;
      newQueries[index].startDate = sevenDaysAgo;
      newQueries[index].endDate = today;
    }

    newQueries[index][key] = value;
    newQueries[index]['type'] = fieldType;

    if (key == 'field') {
      const validOperators = getOperatorsByType(fieldType);
      newQueries[index].operator = validOperators.length > 0 ? validOperators[0].value : "";
    }
    setQueries(newQueries);
  };

  const handleMultiSelectChange = (index, selectedOptions) => {
    const selectedValues = selectedOptions ? selectedOptions.map(option => option.value).join(",") : "";
    handleQueryChange(index, "value", selectedValues, "select");
  };

  const getAvailableFields = (index) => {
    const selectedFields = queries.slice(0, index).map(query => query.field);
    return fields.filter(field => !selectedFields.includes(field.value));
  };

  const setRemoveRemarks = (e) => {
    setRemarkError("");
    setRemarks(e.target.value);
  };


  const renderInput = (query, index) => {
    if (query.type === "date" && query.operator === "between") {
      return (
        <CalendarDatePickerPage
          startDate={query.startDate}
          endDate={query.endDate}
          onDateChange={handleDateChange}
          index={index}
        />
      );
    } else if (query.type === "date" && query.operator !== "between") {

      return (
        <div className="date-picker-wrapper" style={{ position: 'relative' }}>
          <DatePicker
            selectsRange={false}
            selected={query.endDate.toDate()}
            onChange={(date) => handleDateChangeSingle(date, index)}
            showTimeSelect={false}
            timeIntervals={10}
            dateFormat="dd MMM, yyyy"
            placeholderText="Select Date"
            className="form-control input-field-ui"
            isClearable={false}
            onKeyDown={(e) => {
              if (e.key === "Enter" || e.key === "Tab") {
                e.preventDefault();
              }
            }}
          />
          <i className='bx bx-calendar'
            style={{
              position: 'absolute',
              right: '10px',
              top: '50%',
              transform: 'translateY(-50%)',
              pointerEvents: 'none',
              fontSize: '20px',
              color: '#666'
            }}></i>
        </div>
      );
    } else if (query.type === "select") {

      // const selectedConditions = programs.filter(program => query.value.includes(program.value));

      const selectedConditions = programs.filter(program => {
        const queryValues = typeof query.value === 'string'
          ? query.value.split(',')
          : Array.isArray(query.value)
            ? query.value
            : [];

        return queryValues.some(queryVal => queryVal.trim() === program.value.toString());
      });

      return (
        <MultiSelect
          options={programs}
          value={selectedConditions.length > 0 ? selectedConditions : []}
          onChange={(selectedOptions) => handleMultiSelectChange(index, selectedOptions)}
          labelledBy="Select"
          hasSelectAll={true}
          className="form-select select-input-ui select-input-ui-select"
          disableSearch={false}
          overrideStrings={{
            selectAll: 'Select All',
            allItemsAreSelected: 'All Conditions are selected',
            selectSomeItems: 'Select Conditions'
          }}
        />
      );
    } else {
      return (
        <input
          type="text"
          value={query.value}
          className="form-control input-field-ui"
          onChange={(e) => handleQueryChange(index, "value", e.target.value, "text")}
        />
      );
    }
  };

  const handleBackClick = () => {
    window.history.back();
  };
  return (
    <>
      <section className="ReportsPrograms">
        <div className="container-fluid px-0 mb-3 ">
          <div className="row align-items-center pb-3 sticky-top-bar">
            <div className="col-md " style={{
              display: 'flex',
              alignItems: 'center'
            }}>
              <button className="btn btn-primary back-btn mb-0 me-3" onClick={handleBackClick}><i className="bi bi-chevron-left me-1"></i> Back</button>
              <h1 className="main-title mb-0">Remove Untouched Members</h1>
            </div>
          </div>
          <div className="reports-programs mb-3">
            <div className="row align-items-center">
              <div className="col-md-auto">
                <img
                  src="https://storage.googleapis.com/ksabupatest/2024/10/17/b4qag/6jm3kh8srs.png"
                  alt="Invited Members icon"
                  style={{ height: "140px" }}
                />
              </div>
              <div className="col-md">
                <p className="mb-0">
                  Identify and remove members who were mistakenly uploaded and have had no engagement since upload.
                </p>
              </div>
            </div>
          </div>

          <div className="reports-programs mb-3">
            <div className="row">
              <div className="col-md-12">
                <h1 className="dashboard-graph-title "><span>Custom Member Filter</span></h1>
              </div>

              <div className="col-md-12">

                {queries.map((query, index) => (
                  <div key={index} className="row mb-3">

                    <div className="col">
                      <select
                        value={query.field}
                        className="form-select input-field-ui"
                        onChange={(e) => {
                          const selectedField = fields.find(field => field.value === e.target.value);
                          handleQueryChange(index, "field", selectedField.value, selectedField.type);
                        }}
                      >
                        {fields.map((field) => (
                          <option key={field.value} value={field.value}>
                            {field.display}
                          </option>
                        ))}
                      </select>
                    </div>

                    <div className="col">
                      <select
                        value={query.operator}
                        className="form-select input-field-ui"
                        onChange={(e) => {
                          handleQueryChange(index, "operator", e.target.value, query.type);
                        }}
                      >
                        {allOperators[query.type]?.map((operator, i) => (
                          <option key={i} value={operator.value}>
                            {operator.display}
                          </option>
                        ))}
                      </select>
                    </div>

                    <div className="col">
                      {renderInput(query, index)}
                    </div>


                    {queries.length > 1 && (
                      <div className="col-auto mt-3">
                        <span className="tw-px-2" onClick={() => handleRemoveQuery(index)} style={{ cursor: 'pointer' }}><i className="bi bi-trash tw-text-red-600 hover:tw-text-[#215a87]"></i></span>
                      </div>
                    )}

                    {index < queries.length - 1 && query.condition && (
                      <div className="col-12 mt-3 text-center">
                        <strong>{query.condition}</strong>
                      </div>
                    )}

                    {index === queries.length - 1 && showAndOr && index < 3 && (
                      <div className="col-12 mt-3">
                        <button className="btn btn-primary back-btn me-2" onClick={() => handleAddQuery("AND")}>AND</button>
                        <button className="btn btn-primary back-btn" onClick={() => handleAddQuery("OR")}>OR</button>
                      </div>
                    )}
                  </div>
                ))}
                <div className="col-md-12">
                  {errorMsg && <span className="text-danger">{errorMsg}</span>}
                  {successMsg && <span className="text-success">{successMsg}</span>}
                </div>
                <div className="col-12 mt-3">
                  <button className="btn btn-success uplaod-file-btn" onClick={() => fetchRemoveData()}>Filter</button>
                </div>

                {showRemoveBlock &&
                  <>
                    <div className="col-12 mt-3">
                      {showRemove && memberCount &&
                        <h1 style={{ fontSize: '18px', fontWeight: '700' }}>Total {memberCount} Members Data will be removed.</h1>
                      }

                      {showRemove && fileLink &&
                        <Link className="link-primary link-offset-2 link-underline-opacity-25 link-underline-opacity-100-hover ms-2" to={fileLink}>Download File</Link>
                      }
                    </div>

                    {showRemove && fileLink &&
                      <div className="col-12 mt-3">
                        <textarea rows="3" className="form-control input-field-ui" placeholder="Remarks *" value={remarks} onChange={setRemoveRemarks}></textarea>
                        {/* <h5 className="card-text1">
                              Remarks <span className='text-danger'>*</span>
                          </h5> */}
                        {remarkError && <span className="text-danger">{remarkError}</span>}
                      </div>
                    }

                    {showRemove && removeId &&
                      <div className="col-12 mt-3">
                        <button className="btn btn-success uplaod-file-btn" onClick={() => RemoveMembers()}>Remove Members</button>
                      </div>
                    }
                  </>
                }

              </div>
            </div>
          </div>
        </div>
        {loading ? <CustomLoader /> : ''}
      </section>
    </>
  );
}

export default RemoveUntouchMembers;
