import React, { useState } from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import highcharts3d from 'highcharts/highcharts-3d';
import exporting from 'highcharts/modules/exporting';
import { capitalizeFirstLetter } from '../../../utils/Utils';


// Initialize exporting module
exporting(Highcharts);
highcharts3d(Highcharts);

const PieChart = ({data, title}) => {
  
  const headers = Object.keys(data);

  const defaultData = [
    { name: 'Cancelled', y: data.Cancelled, color: '#145EC5' },
    { name: 'Confirmed', y: data.Confirmed, color: '#3780B8' },
    { name: 'Pending', y: data.Pending, color: '#37A6E4' },
    { name: 'Rescheduled', y: data.Rescheduled, color: '#93C9FE' },
    { name: 'Completed', y: data.Completed, color: '#FFD93D' },
    { name: 'Done', y: data.Done, color: '#4ECDC4' },
  ].filter(item => item.y > 0);
   

  

  const pieOptions = {
    chart: {
      type: 'pie',
      backgroundColor: 'transparent',
      height: 260,
      options3d: {
          enabled: true,
          alpha: 70,
          beta: 0,
          depth: 50,
          viewDistance: 0
      },
    },
    title: "    ",
    subtitle: null,
    tooltip: {
       pointFormat: '{point.name}: <b>{point.y} ({point.percentage:.1f}%)</b>'
    },
    plotOptions: {
      pie: {
        allowPointSelect: true,
        cursor: 'pointer',
        depth: 30,
        size: 190,
        dataLabels: {
          enabled: true,
          format: '{point.percentage:.1f}%',
          distance: 5,
          alignTo: 'toPlotEdges'
        },
        showInLegend: true
      }
    },
    series: [{
      name: title,
      data: defaultData
    }],
    credits: {
      enabled: false
    },
    exporting: {
      enabled: false
    },
    legend: {
      enabled: true,
      labelFormatter: function() {
        return this.y > 0 ? this.name : null;
      }
    }
  };

  return (
    <div>
      {defaultData?.length > 0 && defaultData.some(item => item.y > 0) ? (
        <HighchartsReact
          highcharts={Highcharts}
          options={pieOptions}
        />
      ) : (
        <div className="emty-pie">
          <h5 className="emty-title-graph">There are no data to display</h5>
        </div>
      )}
    </div>
  );
};

export default PieChart;
