import React, {useState} from 'react'; 
import { Modal as BsModal } from 'react-bootstrap';
import { cancel_or_reschedule_appointment } from '../../../../services/met/Service';

const ConfirmationModal = ({showCancellationConfirmationModal, setShowCancellationConfirmationModal, bookingId, appointmentId, setIsLoading, setReflect}) => {

  async function handleCancelAppointment() {
      setIsLoading(true);
      try {
        let response = await cancel_or_reschedule_appointment("cancel", bookingId, appointmentId, null, null, null);
  
        if (response?.data?.code === 200) {
          //reload teh table

          setReflect((prev) => !prev); 
          setShowCancellationConfirmationModal(false);
        }
  
      } catch (e) {
  
      } finally {
        setIsLoading(false);
      }
    }


  return (
    <BsModal id="cancel-confirmationModl" show={showCancellationConfirmationModal} onHide={e => setShowCancellationConfirmationModal(false)} backdrop="static"
          keyboard="false" fullscreen={false} centered >

          <BsModal.Header closeButton>
            <BsModal.Title
              style={{
                width: "90%",
                color: "black",
                textAlign: "center",
              }}
            >
              Confirm
            </BsModal.Title>
          </BsModal.Header>
          <hr />
          <BsModal.Body >
            <div className="row">
              <div className="col-md-12 text-center">
                <h5 className="main-subtitle ">Do you want to cancel Telehealth consultation ?</h5>
              </div>
              <div className="col-md-12 my-3 text-center">
                <button className='btn program-save-btn me-2'
                  onClick={e => handleCancelAppointment()}
                >OK</button>
              </div>
            </div>
          </BsModal.Body>

        </BsModal>
  )
}

export default ConfirmationModal
