import React, { useState, useEffect } from 'react';
import BackendDrivenDataTable from '../../pages/cn/components/common/BackendDrivenDataTable';
import { Link } from 'react-router-dom';
import { Modal } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import { exportTableData, LoaderLoader, decryptData } from '../../pages/utils/CommonLibrary'
import { toast, ToastContainer } from 'react-toastify';
import moment from 'moment';
import CalendarDatePicker from "../../pages/componenents/CalendarDatePicker"
import { fetchDataUploadHistory } from '../../services/hih/Service';
import DataUploadActionDropDown from './DataUploadActionDropDown';

const DataUploadHistory = () => {
    const storedDates = localStorage.getItem('selectedDates') ? JSON.parse(decryptData(localStorage.getItem('selectedDates'))) : '';

    const [startDate, setStartDate] = useState(
        storedDates && storedDates.length === 4 ? moment(storedDates[0]) : moment()
    );
    const [endDate, setEndDate] = useState(
        storedDates && storedDates.length === 4 ? moment(storedDates[1]) : moment()
    );
    const [recordsList, setRecordsList] = useState([])
    const [isNewLoading, setIsNewLoading] = useState(false);

    const [searchQuery, setSearchQuery] = useState('');
    const [totalRows, setTotalRows] = useState(0);
    const [page, setPage] = useState(1);
    const [perPage, setPerPage] = useState(10);
    const [sortColumn, setSortColumn] = useState("recordId");
    const [sortDirection, setSortDirection] = useState('desc');
    const [download, setDownload] = useState('N');
    const [showFileUploadModal, setShowFileUploadModal] = useState(false);
    const [error, setError] = useState("");
    const [message, setMessage] = useState("");

    useEffect(() => {
        fetchListRecords();
    }, [startDate, endDate, page, perPage, sortColumn, sortDirection, searchQuery, download]);


    const fetchListRecords = async () => {
        setIsNewLoading(true);
        try {
            await fetchDataUploadHistory(formatDate(startDate), formatDate(endDate), page, perPage, sortColumn, sortDirection).then((response) => {
                setRecordsList(response.data.data.logs);
                setTotalRows(response.data.data.totalRows);
            });
            setIsNewLoading(false);
        } catch (error) {
            setIsNewLoading(false);
        }
    }

    function formatDate(dateString) {
        const date = new Date(dateString);
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    }

    const handleDateChange = ({ start, end }) => {
        setStartDate(start);
        setEndDate(end);
    };

    const columns = [
        {
            name: (
                <span>Date Uploaded</span>
            ),
            selector: row => row.date,
            sortable: true,
        },
        {
            name: (
                <span>Uploaded Records</span>
            ),
            selector: row => row.totalRecordsCount,
            sortable: true,
        },
        {
            name: (
                <span>Successful</span>
            ),
            selector: row => row.successRecordsCount,
            sortable: false,
        },
        {
            name: (
                <span>Failed</span>
            ),
            selector: row => row.failedRecordsCount,
            sortable: false,
        },
        {
            name: (
                <span>Uploaded By</span>
            ),
            selector: row => row.uploadedBy,
            sortable: false,
        },
        {
            name: 'Options',
            sortable: false,
            cell: row => (
                <>
                    <Link to={`/processed-data-insights/${row.id}`} className='btn btn-Edit mx-3'><i className="bi bi-eye"></i></Link>
                    |
                    <DataUploadActionDropDown originalFileLink={row.originalFileLink} successFileLink={row.successFileLink} failedFileLink={row.failedFileLink} />
                </>
            ),
        }
    ];

    return (
        <>

            <div className="container-fluid px-0 mb-3 ">
                <div className="row align-items-center pb-3 sticky-top-bar">
                    <div className="col-md ">
                        <h1 className="main-title">Data Upload History</h1>
                    </div>

                    <div className="col-md-3">
                        <CalendarDatePicker startDate={startDate} endDate={endDate} onDateChange={handleDateChange} />
                    </div>
                </div>
                <div className='reports-programs mb-3'>
                    <div className=" row align-items-center">
                        <div className="col-md-auto">
                            <img src="https://storage.googleapis.com/ksabupatest/2024/09/24/v3ysv/7sg4f87em4.png" alt="Invited Members icon" style={{ height: "140px" }} />
                        </div>
                        <div className="col-md">
                            <p className="mb-0">The Reports allows users to generate and download various reports of the data sets migrated into the system. This feature provides valuable insights and ensures you have comprehensive records of all migrated data.</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className='row'>
                <div className='col-10'></div>
                <div className='col-2'>
                    <button type='button' className='btn btn-primary add-new-program-btn' onClick={() => setShowFileUploadModal(true)}>Upload File</button><br /><br />
                </div>
            </div>
            {
                isNewLoading ? <LoaderLoader /> :
                    <BackendDrivenDataTable
                        data={recordsList}
                        loading={isNewLoading}
                        totalRows={totalRows}
                        page={page}
                        perPage={perPage}
                        sortColumn={sortColumn}
                        sortDirection={sortDirection}
                        filterText={searchQuery}
                        columns={columns}
                        fetchTableData={fetchListRecords}
                        setPage={setPage}
                        setSortColumn={setSortColumn}
                        setSortDirection={setSortDirection}
                        setPerPage={setPerPage}
                    />
            }
            {
                showFileUploadModal === true &&
                <Modal id="fileUploadModal" show={showFileUploadModal} onHide={() => setShowFileUploadModal(false)} size="md" backdrop="static" keyboard={false} fullscreen={false}>
                    <Modal.Header closeButton>
                        <h1 className='modal-title'>Upload Service Recommendations</h1>
                    </Modal.Header>
                    <Modal.Body>
                        <div className='row'>
                            <div className='col-md-12'>
                                <div className="row">
                                    <div className='col-md-12 mb-3'>
                                        <div className='row'>
                                            <div className='col-12'>
                                                <label className="form-label">File
                                                    <span className='text-danger'>*</span>
                                                </label>
                                                <input type="file" className='form-control control-input-ui-addmember' name="recommendationFile" />
                                                <div className={`${error != "" ? "errorDiv" : "hide"}`} style={{ fontWeight: "400 !important" }}>{error}</div>
                                                <div className={`${message != "" ? "messageDivNew" : "hide"}`} style={{ fontWeight: "400 !important", color: "black !important" }}>{message}</div>
                                            </div>
                                        </div>
                                        <br />
                                        <center><Button variant="primary" type="submit" className="btn btn-primary save-btn me-3" > Upload</Button></center>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>
            }
        </>
    )
}

export default DataUploadHistory